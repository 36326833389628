import { Reducer } from 'redux'
import { IProductState } from './interface/IProductState'
import { initialState } from './initialState'
import {
  GET_PRODUCTS_ERROR,
  GET_PRODUCTS_LOADING,
  GET_PRODUCTS_SUCCESS,
  RESET_PRODUCTS,
} from './Action'

const ProductReducer: Reducer<IProductState> = (state: IProductState = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTS_SUCCESS:
      return { ...state, loading: false, list: action.payload }
    case GET_PRODUCTS_ERROR:
      return { ...state, loading: false, error: action.payload }
    case GET_PRODUCTS_LOADING:
      return { ...state, loading: true }
    case RESET_PRODUCTS:
      return { ...state, loading: false, error: undefined, list: undefined }
    default:
      return state
  }
}

export default ProductReducer
