import {Reducer} from 'redux';
import {GET_ORGANISATION_INVOICEINFO, ORGANISATION_INVOICEINFO_LOADING} from './Actions';
import {IInvoiceInfo} from './IInvoiceInfo';

const initialState: IInvoiceInfo = {
  loading: false,
  error: '',
  invoiceInfo: [],
};

export const reducer: Reducer<IInvoiceInfo> = (
  state: IInvoiceInfo = initialState,
  action: any
): IInvoiceInfo => {
  switch (action.type) {
    case ORGANISATION_INVOICEINFO_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ORGANISATION_INVOICEINFO:
      return {
        ...state,
        loading: false,
        invoiceInfo: state.invoiceInfo.concat(action.invoiceInfo),
      };
    default:
      return state;
  }
};
