import {Reducer} from 'redux';
import {ISecurityState} from './ISecurityState';

// constants
export const userConstants = {
  LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USER_LOGIN_FAILURE',
  CHECK_CREDENTIALS: 'CHECK_CREDENTIALS',

  LOGOUT: 'USER_LOGOUT',
};

const initialState: ISecurityState = {
  isLoading: false,
  accessToken: '',
  refreshToken: '',
  expiredAt: '',
  error: '',
  roles: [],
};

export const reducer: Reducer<ISecurityState> = (
  state: ISecurityState = initialState,
  action: any
): ISecurityState => {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: '',
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        accessToken: action.access_token,
        refreshToken: action.refresh_token,
        expiredAt: action.expired_at,
        isLoading: false,
        error: '',
        roles: action.roles || []
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case userConstants.LOGOUT:
      return {
        ...state,
        isLoading: false,
        accessToken: '',
        refreshToken: '',
        expiredAt: '',
        error: '',
      };
    default:
      return state;
  }
};
