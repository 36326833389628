import axios, {AxiosResponse} from 'axios';
import {IOrganisationType} from './IOrganisationType';

export default class Client {
  static getStatistics(type: string, startDate?: string, endDate?: string): Promise<AxiosResponse> {
    let URL = `${process.env.CLIENT_URL}/api/anonymous/dashboard/stats/${type}`;
    if (startDate) {
      URL = `${process.env.CLIENT_URL}/api/anonymous/dashboard/stats/${type}/${startDate}`;
      if (endDate) {
        URL = `${process.env.CLIENT_URL}/api/anonymous/dashboard/stats/${type}/${startDate}/${endDate}`;
      }
    }
    const requestOptions: object = {
      method: 'GET',
      url: URL,
    };

    return axios(requestOptions);
  }

  static getLiveStatistics(organisation: IOrganisationType): Promise<AxiosResponse> {
    const URL = `${process.env.CLIENT_URL}/api/anonymous/dashboard/stats/live/count/${organisation}`;
    const requestOptions: object = {
      method: 'GET',
      url: URL,
    };

    return axios(requestOptions);
  }
}
