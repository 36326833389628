import {Dispatch} from 'react';
import AccountsApiClient from './AccountsApiClient';

export const GET_ACCOUNTS_LOADING = 'GET_ACCOUNTS_LOADING';
export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS';
export const GET_ACCOUNTS_ERROR = 'GET_ACCOUNTS_ERROR';
export const ADD_ORGANISATION = 'ADD_ORGANISATION';
export const ADD_ORGANISATION_ERROR = 'ADD_ORGANISATION_ERROR';
export const ORGANISATIONS_LOADING = 'ORGANISATIONS_LOADING';
export const CHANGE_ORANISATION_NAME = 'CHANGE_ORGANISATION_NAME';
export const CHANGE_ORGANISATION_NAME_LOADING = 'CHANGE ORGANISATION_NAME_LOADING';
export const SET_CURRENT_ORGANISATION = 'SET_CURRENT_ORGANISATION';

export function getAccounts() {
  return (dispatch: Dispatch<any>) => () => {
    dispatch(getAccountsLoading());

    AccountsApiClient.getAccounts()
      .then(response => {
        dispatch(getAccountsSuccess(response.data));
      })
      .catch(error => {
        dispatch(getAccountsError(error));
      });
  };
}

export function getAccountsForSearch(searchTerm: string) {
  return (dispatch: Dispatch<any>) => () => {
    dispatch(getAccountsLoading());
    AccountsApiClient.getAccountsForSearch(searchTerm)
      .then(response => {
        dispatch(getAccountsSuccess(response.data.data));
      })
      .catch(error => {
        dispatch(getAccountsError(error));
      });
  };
}

export function getOrganisation(organisationId: string) {
  return (dispatch: Dispatch<any>) => () => {
    dispatch(organisationLoading());
    AccountsApiClient.getOrganisation(organisationId)
      .then(response => {
        dispatch(addOrganisation(organisationId, response.data.data.name));
      })
      .catch(error => {
        dispatch(addOrganisationError(error));
      });
  };
}

export function setCurrentOrganisation(organisationId: string) {
  return {
    type: SET_CURRENT_ORGANISATION,
    organisationId,
  };
}

export function changeOrganisationName(organisationId: string, name: string) {
  return (dispatch: Dispatch<any>) => () => {
    dispatch(changeOrganisationNameLoading());
    AccountsApiClient.changeOrganisationName(organisationId, name).then(response => {
      dispatch(organisationNameChangedOK(response));
    });
  };
}

function getAccountsLoading() {
  return {
    type: GET_ACCOUNTS_LOADING,
  };
}

function organisationLoading() {
  return {
    type: ORGANISATIONS_LOADING,
  };
}

function getAccountsSuccess(accounts: any) {
  return {
    type: GET_ACCOUNTS_SUCCESS,
    accounts,
  };
}

function getAccountsError(error: string) {
  return {
    type: GET_ACCOUNTS_ERROR,
    error,
  };
}

function addOrganisation(id: string, name: string) {
  return {
    type: ADD_ORGANISATION,
    organisation: {
      id,
      name,
    },
  };
}

function addOrganisationError(error: any) {
  return {
    type: ADD_ORGANISATION_ERROR,
    error,
  };
}

function changeOrganisationNameLoading() {
  return {
    type: CHANGE_ORGANISATION_NAME_LOADING,
  };
}

function organisationNameChangedOK(ok: any) {
  return {
    type: CHANGE_ORANISATION_NAME,
    ok,
  };
}
