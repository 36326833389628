import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import LoadingDots from '../LoadingDots/LoadingDots';

import IDataProvider from './IDataProvider';

import './Table.scss';
import TableCell from './TableCell';
import TablePager from './TablePager';

interface IProps {
  dataProvider: IDataProvider;
  pageSize: number;
  refresh?: any;
  children: JSX.Element[];
}

interface IState {
  collapsedColumns: Array<any>;
  page: number;
  isLoading: boolean;
  data: Array<any>;
  total: number;
  isUpdating: boolean;
  sorting: object;
}

class Table extends React.Component<IProps, IState> {
  cellRef: React.RefObject<any>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      collapsedColumns: [],
      page: 1,
      isLoading: true,
      data: [],
      total: 0,
      isUpdating: false,
      sorting: {},
    };

    this.collapseColumn.bind(this);
    this.expandColumn.bind(this);
    this.cellRef = React.createRef();
  }

  componentDidMount () {
    this.load(1, 'DESC')
  }

  componentDidUpdate (prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    if (this.props.refresh !== prevProps.refresh) {
      this.load(1, 'DESC')
    }
  }

  load(page: number, sorting: any) {
    this.setState({isLoading: true});
    this.props.dataProvider.rows(page, this.props.pageSize, sorting).then((result: any) => {
      this.setState({
        data: result.data,
        total: result.count,
        isLoading: false,
        page,
      });
    });
  }

  expandColumn(columnIndex: number) {
    const index = this.state.collapsedColumns.indexOf(columnIndex);
    if (index === -1) return;

    const columns = this.state.collapsedColumns.slice(0);
    columns.splice(index, 1);

    this.setState({collapsedColumns: columns});
  }

  collapseColumn(columnIndex: number): void {
    this.setState((prevState: any) => ({
      collapsedColumns: [...prevState.collapsedColumns, columnIndex],
    }));
  }

  sortColumn(columnIndex: number, isAscending: boolean) {
    const {children} = this.props;

    const column: any = children[columnIndex];
    const sorting = {...this.state.sorting, [column.props.dataKey]: isAscending ? 'ASC' : 'DESC'};
    if (isAscending === null) delete sorting[column.props.dataKey];

    this.setState({sorting});
    this.load(this.state.page, sorting);
  }

  isCollapsed(columnIndex: number): boolean {
    return this.state.collapsedColumns.includes(columnIndex);
  }

  onUpdate(column: any, value: any, row: any) {
    if (typeof this.props.dataProvider.update !== 'function') {
      return;
    }
    const data = this.state.data.slice(0);
    const rowIdentifier = this.props.dataProvider.getRowIdentifier();
    const dataIndex = data.findIndex(
      (oneData: any) => oneData[rowIdentifier] === row[rowIdentifier]
    );
    if (column.props.reverseTransform) value = column.props.reverseTransform(value);
    if (value === data[dataIndex][column.props.dataKey]) return;

    data[dataIndex][column.props.dataKey] = value;
    this.setState({isUpdating: true, data});

    if (!this.isValid(data[dataIndex])) {
      this.setState({isUpdating: false});
      return;
    }

    this.props.dataProvider
      .update(row.key, column.props.dataKey, value)
      .then(() => this.setState({isUpdating: false}));
  }

  isValid(row: any): boolean {
    let columns: any = this.props.children;
    if (!Array.isArray(columns)) columns = [columns];

    for (let i = 0; i < columns.length; i++) {
      const column = columns[i];
      if (column.props.validate && !column.props.validate(row[column.props.dataKey])) return false;
    }

    return true;
  }

  render() {
    let {dataProvider, children, pageSize}: any = this.props;
    const {page, data, isLoading, isUpdating}: any = this.state;
    let hasEditableColumn = false;

    if (!Array.isArray(children)) children = [children];
    const columns: any = children.map((column: any, columnIndex: number) => {
      hasEditableColumn = hasEditableColumn || column.props.editable;
      return React.cloneElement(column, {
        onCollapse: (columnIndex: number) => this.collapseColumn(columnIndex),
        onExpand: (columnIndex: number) => this.expandColumn(columnIndex),
        onSort: (columnIndex: number, isAscending: boolean) =>
          this.sortColumn(columnIndex, isAscending),
        index: columnIndex,
        key: `header${columnIndex}`,
      });
    });

    const headerRow = <tr>{columns}</tr>;

    const rows = data.map((row: any, rowIndex: number) => {
      const cells = [];
      for (let i = 0; i < columns.length; i++) {
        if (rowIndex > 0 && this.isCollapsed(i)) continue;
        const column = columns[i];
        const classNames: Array<string> = [];
        if (this.isCollapsed(i)) classNames.push('collapsed');
        if (column.props.validate && !column.props.validate(row[column.props.dataKey]))
          classNames.push('error');

        cells.push(
          <TableCell
            value={
              this.isCollapsed(i) ? (
                <div>
                  <div>{column.props.children}</div>
                </div>
              ) : (
                row[column.props.dataKey]
              )
            }
            row={row}
            classNames={classNames}
            rowspan={this.isCollapsed(i) ? dataProvider.count() : 1}
            editable={column.props.editable}
            onBlur={(value: any) => this.onUpdate(column, value, row)}
            choices={column.props.choices}
            colors={column.props.colors}
            key={`tableCell_${rowIndex}_${i}`}
            transform={column.props.transform}
            cellStyle={column.props.cellStyle}
            iconMap={column.props.iconMap}
            url={column.props.url}
            collapsed={this.isCollapsed(i)}
          />
        );
      }

      if (cells.length === 0) return '';

      return (
        <tr className={rowIndex % 2 === 0 ? 'odd' : 'even'} key={`row${rowIndex}`}>
          {cells}
        </tr>
      );
    });

    return (
      <div>
        <div className="tableWrapper">
          {isLoading ? (
            <div className="loaderWrapper">
              <LoadingDots />
            </div>
          ) : (
            ''
          )}
          {hasEditableColumn ? (
            <div className="tableStatus">
              {isUpdating ? (
                <LoadingDots />
              ) : (
                <FontAwesomeIcon icon={faCheck} className="tableStatusSuccess" />
              )}
            </div>
          ) : (
            ''
          )}
          <table className="table">
            <thead>{headerRow}</thead>
            <tbody>{rows}</tbody>
          </table>
        </div>

        <TablePager
          pages={Math.ceil(dataProvider.count() / pageSize)}
          currentPage={page}
          onPageClick={(page: any) => this.load(page, 'DESC')}
        />
      </div>
    );
  }
}

export default Table;
