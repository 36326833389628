import React, {FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

import ModuleForm from "./ModuleForm";
import Hero from "../App/Components/Hero/Hero";
import {IModulePrices} from "../store/Module/IModule";
import AppLoadingDots from "../App/Components/AppLoadingDots/AppLoadingDots";
import {createModule} from "../store/Module/actions";
import {useHistory} from "react-router-dom";

const Create: FunctionComponent = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [isSubmitting, setIsSubmutting] = useState<boolean>(false)
    const [error, setError] = useState<string|undefined>(undefined)
    const history = useHistory()
    const onSubmit = async (name: string, prices: IModulePrices, permissions: string[]) => {
        setIsSubmutting(true)
        try {
            await dispatch(createModule(name, prices, permissions))
            setError(undefined)
        } catch (Error) {
            setError('An error occurred while creating the module.')
        }
        setIsSubmutting(false)
        history.push('/modules')
    }

    if (isSubmitting) {
        return <AppLoadingDots />
    }

    return (
        <Hero maxWidth="60em">
            <h1>{t('Create module')}</h1>
            {error ? <p className="status-message error">{t(error)}</p> : null}
            <ModuleForm onSubmit={onSubmit} />
        </Hero>
    )
}

export default Create