import React, {SyntheticEvent, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useHistory} from 'react-router-dom';
import ITranslation from 'Translations/Table/ITranslation';
import TextareaInput from '../../App/Components/TextareaInput/TextareaInput';
import TextInput from '../../App/Components/TextInput/TextInput';
import TranslationsApiClient from '../TranslationsApiClient';
import LoadingDots from '../../App/LoadingDots/LoadingDots';

interface IProps {
  translation: ITranslation;
  domain: string;
  children?: any;
}

const Form: React.FunctionComponent<IProps> = (props: IProps) => {
  const {t} = useTranslation();
  const history = useHistory();
  const [translation, setTranslation] = useState(props.translation);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const onFormInputChange = (key: string, value: string) => {
    translation[key] = value;
    setTranslation(translation);
  };

  const onSubmitTranslation = (event: SyntheticEvent) => {
    event.preventDefault();
    setIsSaving(true);
    TranslationsApiClient.addTranslation(translation, props.domain)
      .then(() => history.push(`/translations/${props.domain}`))
      .catch(() => setIsSaving(false));
  };
  if (isSaving) {
    return <LoadingDots />;
  }

  return (
    <form onSubmit={onSubmitTranslation}>
      <TextInput
        label={t<string>('Key')}
        value={translation.key}
        onChange={value => onFormInputChange('key', value)}
        full
      />
      <TextareaInput
        label={t('English')}
        value={translation.en}
        onChange={value => onFormInputChange('en', value)}
        full
      />
      <TextareaInput
        label={t('Flemmish')}
        value={translation.nl}
        onChange={value => onFormInputChange('nl', value)}
        full
      />
      <TextareaInput
        label={t('Dutch')}
        value={translation.nl_NL}
        onChange={value => onFormInputChange('nl_NL', value)}
        full
      />
      <TextareaInput
        label={t('French')}
        value={translation.fr}
        onChange={value => onFormInputChange('fr', value)}
        full
      />
    <TextareaInput
        label={t('Italian')}
        value={translation.it}
        onChange={value => onFormInputChange('it', value)}
        full
    />
      <TextareaInput
        label={t('German')}
        value={translation.de}
        onChange={value => onFormInputChange('de', value)}
        full
      />
      <TextareaInput
        label={t('Albanian')}
        value={translation.sq}
        onChange={value => onFormInputChange('sq', value)}
        full
      />
      <button className="button">{t('Add')}</button>
      <Link to="/translations" className="button dim">
        {t('Cancel')}
      </Link>
    </form>
  );
};

export default Form;
