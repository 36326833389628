import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { IAppState } from '../App/IAppState'
import { getBrandProducts, resetBrandProducts } from '../store/Products/Action'
import { ProductLoader } from './components/ProductLoader/ProductLoader'
import { brandProductsStyles } from './style'
import { ProductList } from './components/ProductList/ProductList'

export function BrandProducts() {
  const classes = brandProductsStyles()
  const history = useHistory()
  const { brandId } = useParams<{ brandId: string }>()

  const dispatch = useDispatch()
  const productList = useSelector((state: IAppState) => state.products.list)
  const productLoader = useSelector((state: IAppState) => state.products.loading)
  const productError = useSelector((state: IAppState) => state.products.error)

  useEffect(() => {
    if (typeof productList === 'undefined') {
      dispatch(getBrandProducts(brandId, dispatch))
    } else {
      dispatch(resetBrandProducts())
      dispatch(getBrandProducts(brandId, dispatch))
    }
  }, [])

  useEffect(() => {
    if (typeof productList !== 'undefined' && productList.length === 0) {
      console.log('no Products')
    } else console.log(productList)
  }, [productList])

  const backToBrandList = () => history.push('/products')
  return (
    <div className={classes.productsContainer}>
      <div className={classes.backArrow} onClick={() => backToBrandList()}>
        <ArrowBackIcon />
      </div>
      <ProductLoader
        isLoading={productLoader}
        productError={productError}
        productList={productList}
      />
      <ProductList productList={productList} />
    </div>
  )
}
