import {AxiosResponse} from 'axios';
import {SyntheticEvent, useEffect, useState} from 'react';
import {
  sendOrganisationAddress,
  sendOrganisationContactPerson,
  sendOrganisationName,
} from './ApiClient';
import {IContact} from '../ContactList/ContactStore/IContact';

const useForm = (): [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  any,
  any,
  string,
  any
] => {
  const [organisationName, setOrganisationName] = useState<string>('');
  const [organisationNameError, setOrganisationNameError] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  const [surname, setSurname] = useState<string>('');
  const [street, setStreet] = useState<string>('');
  const [streetNr, setStreetNr] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [language, setLanguage] = useState<string>('');
  const [homePhone, setHomePhone] = useState<string>('');
  const [mobilePhone, setMobilePhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');

  const [submitAttempted, setSubmitAttempted] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('default');

  useEffect(() => {
    if (submitAttempted) {
      validate();
    }
  }, [organisationName, name, email]);

  const setvalue = (key: string, value: string): void => {
    switch (key) {
      case 'organisationName':
        setOrganisationName(value);
        break;
      case 'name':
        setName(value);
        break;
      case 'surname':
        setSurname(value);
        break;
      case 'street':
        setStreet(value);
        break;
      case 'streetNr':
        setStreetNr(value);
        break;
      case 'postalCode':
        setPostalCode(value);
        break;
      case 'city':
        setCity(value);
        break;
      case 'country':
        setCountry(value);
        break;
      case 'language':
        setLanguage(value);
        break;
      case 'homePhone':
        setHomePhone(value);
        break;
      case 'mobilePhone':
        setMobilePhone(value);
        break;
      case 'email':
        setEmail(value);
        break;
    }
  };

  const validate = () => {
    let isvalid = true;
    if (!organisationName) {
      setOrganisationNameError('Organisation name is required');
      isvalid = false;
    } else {
      setOrganisationNameError('');
    }

    if (!name) {
      setNameError('Name is required');
      isvalid = false;
    } else {
      setNameError('');
    }

    if (!email) {
      setEmailError('Email is required');
      isvalid = false;
    } else {
      setEmailError('');
    }

    return isvalid;
  };

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    setSubmitAttempted(true);

    if (!validate()) {
      return;
    }

    setStatus('submitting');
    sendOrganisationName(organisationName).then(
      (organisationResponse: AxiosResponse) => {
        sendOrganisationAddress(
          organisationResponse.data.id,
          street,
          +streetNr,
          postalCode,
          city,
          country
        )
          .then((organisationAddressResponse: AxiosResponse) => {
            const contact: IContact = {
              name,
              lastName: surname,
              phone: mobilePhone,
              email,
              addressId: organisationAddressResponse.data.id,
            };

            sendOrganisationContactPerson(organisationResponse.data.id, contact);
          })
          .then(
            () => {
              setStatus('success');
            },
            () => {
              setStatus('failed');
            }
          );
      },
      () => {
        setStatus('failed');
      }
    );
  };

  const reset = () => {
    setOrganisationName('');
    setName('');
    setSurname('');
    setStreet('');
    setStreetNr('');
    setPostalCode('');
    setCity('');
    setCountry('');
    setLanguage('');
    setHomePhone('');
    setMobilePhone('');
    setEmail('');
    setStatus('default');
    setSubmitAttempted(false);
  };

  return [
    organisationName,
    organisationNameError,
    name,
    nameError,
    surname,
    street,
    streetNr,
    postalCode,
    city,
    country,
    language,
    homePhone,
    mobilePhone,
    email,
    emailError,
    setvalue,
    onSubmit,
    status,
    reset,
  ];
};

export default useForm;
