import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import {  useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createPartner } from "../../store/Partners/Action";
import PartnerForm from "../PartnerForm/PartnerForm";
import { IPartner } from "../../store/Partners/interface/IPartner";

const CreatePartner: FunctionComponent = () => {
  const {t} = useTranslation()

  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = async (partner: Partial<IPartner> & { password?: string }) => {
    await dispatch(createPartner(partner as Partial<IPartner> & { password: string }))
    history.push('/partners')
  }

  return (
        <div className="main-content" style={{textAlign: 'center'}}>
            <div style={{margin: '0 auto 2em auto', maxWidth: '480px', textAlign: 'left'}}>
                <h1>{t('Create partner')}</h1>
                <PartnerForm onSubmit={onSubmit} />
            </div>
        </div>
  )
}

export default CreatePartner
