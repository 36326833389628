import React from 'react';

interface IProps {
  text?: string;
  picture?: any;
}

const CardHeader: React.FunctionComponent<IProps> = (props: IProps) => (
  <div className="card-header">
    <div className="card-header-picture">{props.picture}</div>
    <div className="card-header-text">{props.text}</div>
  </div>
);

export default CardHeader;
