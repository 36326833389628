import React from 'react';
import './Table.scss';
import TableCellColorPicker from './TableCellColorPicker';
import TableCellDropDown from './TableCellDropdown';
import TableCellIconMap from './TableCellIconMap';

export default function TableCell(props: any) {
  let {
    value,
    row,
    classNames,
    rowspan,
    editable,
    onBlur,
    choices,
    colors,
    transform,
    cellStyle,
    iconMap,
    url,
    collapsed,
  } = props;

  let content = value;
  if (!collapsed) {
    if (typeof url === 'function') {
      url = url(value, row);
    }

    if (transform) {
      content = value = transform(value);
    }

    if (choices && editable) {
      content = (
        <TableCellDropDown
          choices={choices}
          onUpdate={(value: any) => onBlur(value)}
          value={value}
        />
      );
      classNames.push('dropDownCell');
    } else if (colors && editable) {
      content = (
        <TableCellColorPicker
          colors={colors}
          onUpdate={(value: any) => onBlur(value)}
          value={value}
          editable={editable}
        />
      );
      classNames.push('colorCell');
    } else if (iconMap) {
      content = <TableCellIconMap iconMap={iconMap} value={value} />;
    } else if (url) {
      content = <a href={url}>{content}</a>;
    }
  } else {
    editable = false;
  }

  return (
    <td
      rowSpan={rowspan || 1}
      className={classNames.join(' ')}
      contentEditable={!choices && !colors && editable}
      suppressContentEditableWarning={!choices && !colors && editable}
      onBlur={e => {
        if (!choices && editable) onBlur(e.currentTarget.textContent);
      }}
      style={cellStyle || {}}
    >
      {content}
    </td>
  );
}
