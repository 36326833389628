import axios, {AxiosResponse} from 'axios';
import {useEffect, useState} from 'react';

const useForm = (
  accessToken: string
): [string, string, string, string, string, string, string, string, any, any, string, any] => {
  const [subject, setSubject] = useState<string>('');
  const [subjectError, setSubjectError] = useState('');
  const [body, setBody] = useState('');
  const [bodyError, setBodyError] = useState('');
  const [action, setAction] = useState('');
  const [actionError, setActionError] = useState('');
  const [type, setType] = useState('announcement');
  const [language, setLanguage] = useState('nl');
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [status, setStatus] = useState('default');

  useEffect(() => {
    if (submitAttempted) {
      validate();
    }
  }, [subject, body, action]);

  const setValue = (key: string, value: string): void => {
    if (key === 'subject') setSubject(value);
    else if (key === 'body') setBody(value);
    else if (key === 'action') setAction(value);
    else if (key === 'language') setLanguage(value);
    else if (key === 'type') setType(value);
  };

  const validate = () => {
    let isValid = true;
    if (!subject) {
      setSubjectError('Subject is required');
      isValid = false;
    } else {
      setSubjectError('');
    }

    if (!body) {
      setBodyError('Body is required');
      isValid = false;
    } else {
      setBodyError('');
    }

    if (!action) {
      // setActionError('Action is required')
      // isValid = false
    } else {
      setActionError('');
    }

    return isValid;
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    setSubmitAttempted(true);

    if (!validate()) {
      return;
    }

    setStatus('submitting');
    getEstablishmentUuids(language).then(
      (response: AxiosResponse) => {
        sendBatchNotification(accessToken, response.data.data, subject, body, type, action).then(
          () => {
            setStatus('success');
          },
          () => {
            setStatus('failed');
          }
        );
      },
      () => {
        setStatus('failed');
      }
    );
  };

  const reset = () => {
    setStatus('default');
    setSubject('');
    setBody('');
    setAction('');
    setSubmitAttempted(false);
  };

  return [
    subject,
    subjectError,
    body,
    bodyError,
    action,
    actionError,
    type,
    language,
    setValue,
    onSubmit,
    status,
    reset,
  ];
};

const getEstablishmentUuids = (language: string) => {
  const requestOptions: object = {
    method: 'GET',
    url: `${process.env.CLIENT_URL}/api/anonymous/establishment-uuid/language/${language}`,
  };

  return axios(requestOptions);
};

const sendBatchNotification = (
  accessToken: string,
  establishmentUuids: string[],
  subject: string,
  body: string,
  type: string,
  action: string
) => {
  const requestOptions: object = {
    method: 'POST',
    url: `${process.env.API_URL}/notification-center/notification/send-batch`,
    headers: {
      'Content-Type': 'application/json',
      'X-AUTH-TOKEN': accessToken,
    },
    data: {
      establishment_ids: establishmentUuids,
      subject,
      body,
      action: action || null,
      type,
      icon: 'bell',
    },
  };

  return axios(requestOptions);
};

export default useForm;
