import {ThunkDispatch} from "redux-thunk";
import {IModule, IModulePrices} from "./IModule";
import {fetchModules, createModule as httpCreatedModule, sortModules as httpSortModules, updateModule as httpUpdateModule} from "./http";

const getModulesSuccess = (modules: IModule[]) => ({
    type: 'GET_MODULES_SUCCESS',
    payload: {
        list: modules
    }
})

const getModulesFailure = () => ({
    type: 'GET_MODULES_FAILURE'
})

export const getModules = () => async (dispatch: ThunkDispatch<any, any, any>) => {
    try {
        const modules = await fetchModules()
        dispatch(getModulesSuccess(modules))
    } catch (error) {
        dispatch(getModulesFailure())
        throw error
    }
}

const createModuleSuccess = (module: IModule) => ({
    type: 'CREATE_MODULE_SUCCESS',
    payload: {
        module
    }
})

const createModuleFailure = () => ({
    type: 'CREATE_MODULE_FAILURE'
})

export const createModule = (name: string, prices: IModulePrices, permissions: string[]) => async (dispatch: ThunkDispatch<any, any, any>) => {
    try {
        const module = await httpCreatedModule(name, prices, permissions)
        dispatch(createModuleSuccess(module))
    } catch (error) {
        dispatch(createModuleFailure())
        throw error
    }
}

const updateModuleSuccess = (module: IModule) => ({
    type: 'UPDATE_MODULE_SUCCESS',
    payload: {
        module
    }
})

const updateModuleFailure = () => ({
    type: 'UPDATE_MODULE_FAILURE'
})

export const updateModule = (module: IModule) => async (dispatch: ThunkDispatch<any, any, any>) => {
    try {
        await httpUpdateModule(module)
        dispatch(updateModuleSuccess(module))
    } catch (error) {
        dispatch(updateModuleFailure())
        throw error
    }
}

export const sortModules = (moduleIds: string[]) => {
    httpSortModules(moduleIds)

    return {
        type: 'SORT_MODULES',
        payload: {
            module_uuids: moduleIds
        }
    }
}
