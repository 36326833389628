import {library} from '@fortawesome/fontawesome-svg-core';
import {faCheck, faExclamation, faQuestion} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';

import './TableCellIconMap.scss';

export default function TableCellIconMap(props: any) {
  const {iconMap, value} = props;

  library.add(faCheck);
  library.add(faQuestion);
  library.add(faExclamation);

  const className: any = iconMap[value].class ? [iconMap[value].class] : null;

  return (
    <span className={`${className} tableCellIconMap`}>
      <FontAwesomeIcon icon={iconMap[value].icon} />
    </span>
  );
}
