import {Reducer} from 'redux';
import {MODAL_CLOSED, MODAL_OPEN, SET_MODAL_CONTENT} from './Actions';
import {IModalState} from './IModalState';

const initialState: IModalState = {
  isOpen: false,
  error: '',
  content: 'content',
};

export const reducer: Reducer<IModalState> = (
  state: IModalState = initialState,
  action: any
): IModalState => {
  switch (action.type) {
    case MODAL_OPEN:
      return {
        ...state,
        isOpen: true,
        error: '',
        content: action.content,
      };
    case MODAL_CLOSED:
      return {
        ...state,
        isOpen: false,
        error: '',
      };
    case SET_MODAL_CONTENT:
      return {
        ...state,
        content: action.content,
      };
    default:
      return state;
  }
};
