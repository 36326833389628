import React from 'react';
import {Line} from 'react-chartjs-2';
import Card from '../../App/Components/Card/Card';
import './CustomerGrowthCard.scss';
import CardRow from '../../App/Components/Card/CardRow/CardRow';

const CustomerGrowthCard: React.FunctionComponent = () => {
  const fakeDataSet = [
    {
      date: 'jun',
      count: 5,
    },
    {
      date: 'jul',
      count: 5,
    },
    {
      date: 'aug',
      count: 6,
    },
    {
      date: 'sep',
      count: 2,
    },
    {
      date: 'okt',
      count: 3,
    },
    {
      date: 'nov',
      count: 6,
    },
  ];
  const lastPercentage = fakeDataSet.slice(-1)[0].count;
  const growth = () => ({
    labels: fakeDataSet.map(element => element.date),
    datasets: [
      {
        label: 'Growth % per month',
        backgroundColor: fakeDataSet.map(element => {
          // colorvalues are copied from app/variables.scss
          if (element.count < 6) {
            return '#d24444';
          }
          return '#69d321';
        }),
        data: fakeDataSet.map(element => element.count),
        borderWidth: 1,
        fill: false,
      },
    ],
  });
  return (
    <div className="customer-growth-card">
      <Card>
        <CardRow>
          <div>Growth this month : {lastPercentage}%</div>
        </CardRow>
        <Line data={growth} />
      </Card>
    </div>
  );
};

export default CustomerGrowthCard;
