import { useTranslation } from 'react-i18next'
import React, { FunctionComponent } from 'react'
import LoadingDots from '../../../App/LoadingDots/LoadingDots'
import { IProps } from './IProps'

export const ProductLoader: FunctionComponent<IProps> = ({
  isLoading,
  productError,
  productList,
}) => {
  const { t } = useTranslation()

  if (productError) {
    console.log(productError)
    return <h1>{productError}</h1>
  }

  if (typeof productList !== 'undefined' && productList.length === 0) {
    return (
      <div>
        <h1>{t("No product's for this brand.")}</h1>
      </div>
    )
  }

  if (!isLoading && typeof productList !== 'undefined') {
    return (
      <div>
        <h1>{productList[0].brand.name}</h1>
      </div>
    )
  }

  return (
    <div>
      <h1>{t('Fetching product list')}</h1>
      <LoadingDots />
    </div>
  )
}
