import {IEstablishment, IApiEstablishment} from './IEstablishment';

export default class EstablishmentTransformer {
  static transform(apiEstablishment: IApiEstablishment, organisationId: string): IEstablishment {
    return {
      id: apiEstablishment.id,
      name: apiEstablishment.name,
      email: apiEstablishment.email,
      contactPersonsIds: apiEstablishment.contact_persons_ids,
      invoiceInformationId: apiEstablishment.invoice_information_id,
      label: apiEstablishment.label,
      language: apiEstablishment.language,
      organisationId,
    };
  }

  static reverseTransform(establishment: IEstablishment): IApiEstablishment {
    return {
      id: establishment.id,
      name: establishment.name,
      email: establishment.email,
      contact_persons_ids: establishment.contactPersonsIds,
      invoice_information_id: establishment.invoiceInformationId,
      label: establishment.label,
      language: establishment.language,
    };
  }
}
