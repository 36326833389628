export default [
  {
    name: 'name',
    label: 'Name',
    required: true,
  },
  {
    name: 'category',
    label: 'Product category',
    required: true,
  },
  {
    name: 'barcode',
    label: 'Product barcode',
    required: true,
  },
  {
    name: 'code',
    label: 'Code',
    required: false,
  },
  {
    name: 'purchase_price',
    label: 'Purchase price',
    required: false,
  },
  {
    name: 'retail_price',
    label: 'Retail price',
    required: false,
  },
];
