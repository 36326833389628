import React, { FunctionComponent } from 'react'
import { TableBody, TableCell, TableRow } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { IProps } from './IProps'
import { styles } from './style'

export const ProductList: FunctionComponent<IProps> = ({ productList }) => {
  const { t } = useTranslation()
  const classes = styles()
  if (!productList) {
    return null
  }
  return (
    <TableBody className={classes.tableBody}>
      <TableRow className={classes.root}>
        <TableCell>{t('name')}</TableCell>
        <TableCell>{t('category')}</TableCell>
        <TableCell>{t('barcode')}</TableCell>
        <TableCell>{t('image')}</TableCell>
        <TableCell>{t('purchase price')}</TableCell>
        <TableCell>{t('selling price')}</TableCell>
      </TableRow>
      {productList.map(product => (
        <TableRow id={product.id} key={product.id} className={classes.root}>
          <TableCell>{product.name}</TableCell>
          <TableCell>{product.category}</TableCell>
          <TableCell>{product.barcode}</TableCell>
          <TableCell>{product.imageId ? 'V' : 'X'}</TableCell>
          <TableCell>{product.purchasePrice}</TableCell>
          <TableCell>{product.sellingPrice}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  )
}
