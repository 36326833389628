import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import EditIcon from '@material-ui/icons/Edit'
import FindInPageIcon from '@material-ui/icons/FindInPage'
import React, { FunctionComponent } from 'react'
import { IProps } from './IProps'
import { CollapsibleTableUploadButton } from '../../../CollapsibleTableUploadButton/CollapsibleTableUploadButton'
import { styles } from './style'
import { formatPattern } from '../../../../utils/fortmatPattern'
import { useHistory } from 'react-router-dom'

export const BrandInfo: FunctionComponent<IProps> = ({
  brandInfo,
  setSelectedRowID,
  setIsOpen,
  isOpen,
  openModal,
}) => {
  const history = useHistory()
  const classes = styles()

  const inspectProducts = () => history.push(`products/${brandInfo.id}`)

  return (
    <TableRow id={brandInfo.id} key={brandInfo.id} className={classes.root}>
      <TableCell>
        <IconButton aria-label="expand row" size="small" onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>
      <TableCell key="rowName" component="th" scope="row">
        {brandInfo.name}
      </TableCell>
      <TableCell key="requestedAt">
        {brandInfo.lastRequestedAt === null
          ? 'N/A'
          : brandInfo.lastRequestedAt.format(formatPattern)}
      </TableCell>
      <TableCell key="updatedAt">
        {brandInfo.lastUpdatedAt === null ? 'N/A' : brandInfo.lastUpdatedAt.format(formatPattern)}
      </TableCell>
      <CollapsibleTableUploadButton setSelectedRowID={setSelectedRowID} brandInfo={brandInfo} />
      <TableCell key="actions">
        <IconButton aria-label="expand row" size="small" onClick={() => inspectProducts()}>
          <FindInPageIcon />
        </IconButton>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => openModal({ type: 'update', id: brandInfo.id })}
        >
          <EditIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}
