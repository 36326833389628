import { Dispatch } from 'redux'
import { createBrand, updateBrand } from '../../store/Brands/Actions'

export const brandCrud = async (
  dispatch: Dispatch,
  inputValue: string | undefined,
  branId?: string
): Promise<'error' | 'close'> => {
  if (!inputValue) {
    // setBrandInputError(true)
    return 'error'
  }
  if (!branId) {
    // @ts-ignore -> upgrade to toolkit
    await dispatch(createBrand(inputValue))
    return 'close'
  }
  // @ts-ignore -> upgrade to toolkit
  await dispatch(updateBrand(inputValue, branId))
  return 'close'

  // setBrandInput(undefined)
  // modalClose()
}
