import CardRow from '../../App/Components/Card/CardRow/CardRow';
import React, {useEffect} from 'react';
import Card from '../../App/Components/Card/Card';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './SupportCardEsthios.scss';
import {Line} from 'react-chartjs-2';
import {useDispatch, useSelector} from 'react-redux';
import {IAppState} from '../../App/IAppState';
import {getLiveStatistics} from '../../Dashboard/Store/Actions';

const SupportCardEsthios: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const esthiosLiveData = useSelector((state: IAppState) => {
    const findEsthiosData = state.dashboard.liveStatistics.find(
      stat => stat.organisation === 'esthios'
    );
    return findEsthiosData;
  });

  useEffect(() => {
    if (!esthiosLiveData) {
      dispatch(getLiveStatistics('esthios'));
    }
  });
  const fakeDataSet = [
    {
      date: '7/10',
      count: 55,
    },
    {
      date: '8/10',
      count: 51,
    },
    {
      date: '9/10',
      count: 49,
    },
    {
      date: '10/10',
      count: 51,
    },
    {
      date: '11/10',
      count: 47,
    },
    {
      date: '12/10',
      count: 48,
    },
  ];
  const avgReply = () => ({
    labels: fakeDataSet.map(element => element.date),
    datasets: [
      {
        label: 'AVG reply time in minutes',
        backgroundColor: fakeDataSet.map(element => {
          // colorvalues are copied from app/variables.scss
          if (element.count > 50) {
            return '#d24444';
          }
          return '#69d321';
        }),
        data: [66, 57, 32, 56, 47, 33],
        borderWidth: 1,
        fill: false,
      },
    ],
  });
  const getStars = (count: number) => {
    const parts = count.toString().split('.');
    const wholeNumber = parseInt(parts[0], 10);
    const decimal = parseInt(parts[1], 10);
    const stars: any[] = [];
    for (let i = 0; i < wholeNumber; i++) {
      stars.push(<FontAwesomeIcon key={i} icon={['fas', 'star']} />);
    }
    if (decimal >= 25 && decimal <= 75) {
      stars.push(<FontAwesomeIcon key={decimal} icon={['fas', 'star-half-alt']} />);
    } else if (decimal > 75) {
      stars.push(<FontAwesomeIcon key={decimal} icon={['fas', 'star']} />);
    }

    return stars.map(star => star);
  };
  return (
    <div className="support-card-esthios">
      <Card style="esthios">
        <CardRow>
          {' '}
          <FontAwesomeIcon className="icon" icon={['fas', 'comments']} />
          Open tickets: &nbsp;
          {esthiosLiveData ? esthiosLiveData.data.open : 0}
        </CardRow>
        <CardRow>
          {' '}
          <FontAwesomeIcon className="icon" icon={['fas', 'check-square']} /> Average resolution
          time: 2d{' '}
        </CardRow>
        <br />
        <CardRow>
          <div className="stars">{getStars(2.44)}</div>
        </CardRow>
        <Line data={avgReply} />
      </Card>
    </div>
  );
};

export default SupportCardEsthios;
