import axios from 'axios'
import { IAPIProduct } from '../interface/IProduct'
import { productTransformer } from '../productTransformer'

export const fetchProducts = async (brandId: string) => {
  const axiosResponse = await axios.get<IAPIProduct[]>(
    `${process.env.CLIENT_API}/products/sources/${brandId}`
  )
  return axiosResponse.data.map((apiProduct: IAPIProduct) => productTransformer(apiProduct))
}
