import React from 'react';

interface IProps {
  text?: string;
  actions: any;
}

const CardFooter: React.FunctionComponent<IProps> = (props: IProps) => (
  <div className="card-footer">
    {props.text}
    {props.actions}
  </div>
);

export default CardFooter;
