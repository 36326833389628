import React, { FunctionComponent, useState } from 'react'
import { IProps } from './IProps'
import { BrandInfo } from '../BrandInfo/BrandInfo'
import { SupplierSubTab } from '../SupplierSubTab/SupplierSubTab'

export const CollapsibleTableRow: FunctionComponent<IProps> = ({
  setSelectedRowID,
  brandInfo,
  openModal,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <BrandInfo
        brandInfo={brandInfo}
        setSelectedRowID={setSelectedRowID}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        openModal={openModal}
      />
      <SupplierSubTab isOpen={isOpen} brandInfo={brandInfo} />
    </>
  )
}
