import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {bindActionCreators} from 'redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IHtmlListButton} from 'App/Components/HtmlList/IHtmlListButton';
import LoadingDots from '../../App/LoadingDots/LoadingDots';
import {IAppState} from '../../App/IAppState';
import {IOrganisation} from '../../Accounts/Store/IOrganisation';
import HtmlList from '../../App/Components/HtmlList/HtmlList';
import {getOrganisationInvoiceInfo} from '../Dashboard/InvoiceInfoStore/Actions';
import {IHtmlListData} from '../../App/Components/HtmlList/IHtmlListData';

export interface IProps {
  invoiceInfo: any;
  organisation: IOrganisation;
  getOrganisationInvoiceInfo: any;
  invoiceInfoLoading: boolean;
  accountsLoading: boolean;
}

const InvoiceInfoList: React.FunctionComponent<IProps> = props => {
  useEffect(() => {
    if (!props.invoiceInfoLoading && props.invoiceInfo.length === 0) {
      props.getOrganisationInvoiceInfo(props.organisation.id);
    }
  }, [location.pathname]);
  const {t} = useTranslation();
  const invoiceInfoList: IHtmlListData[] = props.invoiceInfo.map((invoiceInfo: any) => {
    const content = [
      invoiceInfo.name,
      <div>
        <FontAwesomeIcon fixedWidth icon={['fas', 'piggy-bank']} />
        &nbsp;
        {invoiceInfo.iban}
      </div>,
      <div>
        <FontAwesomeIcon fixedWidth icon={['fas', 'percent']} />
        &nbsp;
        {invoiceInfo.vat_number}
      </div>,
      <div>
        <FontAwesomeIcon fixedWidth icon={['fas', 'university']} />
        &nbsp;
        {invoiceInfo.registration_number}
      </div>,
    ];

    const buttons: IHtmlListButton[] = [
      {
        icon: ['fas', 'building'],
        link: `/accounts/${props.organisation.id}/connect-establishment-invoiceinfo/${invoiceInfo.id}`,
      },
    ];
    return {
      content,
      buttons,
    };
  });

  return (
    <div className="invoice-info-list">
      <h1>
        {props.organisation ? props.organisation.name : null} <br /> {t('invoice information ')}
      </h1>
      <div>{props.invoiceInfoLoading ? <LoadingDots /> : <HtmlList list={invoiceInfoList} />}</div>
    </div>
  );
};
const mapStateToProps = (state: IAppState) => ({
  invoiceInfo: state.organisationInvoiceInfo.invoiceInfo,
  invoiceInfoLoading: state.organisationInvoiceInfo.loading,
  accountsLoading: state.accounts.loading,
});
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      getOrganisationInvoiceInfo: (organisationId: string) =>
        dispatch(getOrganisationInvoiceInfo(organisationId)),
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceInfoList);
