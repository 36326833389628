import React, {FunctionComponent} from "react";
import {Route, Switch} from "react-router";
import ModuleOverview from "./Overview";
import Create from "./Create";
import Update from "./Update";

const Bootstrap: FunctionComponent= () => {
    return (
        <Switch>
            <Route path="/modules/create" component={Create} />
            <Route path="/modules/:uuid" component={Update} />
            <Route path="/modules" component={ModuleOverview} />
        </Switch>
    )
}

export default Bootstrap