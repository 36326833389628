// Standart import
import React, {FC} from 'react';

// Material UI
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

// Own components
import CollapsibleTableHeadColumnDefinition from '../../../imports/Table/CollapsibleTableHeadColumnDefinition';

const CollapsibleTableHead: FC = () => (
  <TableHead>
    <TableRow key="tableHead">
      <TableCell />
      {CollapsibleTableHeadColumnDefinition.map((column, i) => (
        <TableCell key={i}>{column.name}</TableCell>
      ))}
    </TableRow>
  </TableHead>
);
export default CollapsibleTableHead;
