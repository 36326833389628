import axios, {AxiosResponse} from "axios";
import {IModule, IModulePrices} from "./IModule";

export const fetchModules = async (): Promise<IModule[]> => {
    const response = await axios.get<IModule[]>(
        `${process.env.CLIENT_API}/account/modules`,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );

    return response.data.sort((modA, modB) => modA.order - modB.order);
};

export const createModule = async (name: string, prices: IModulePrices, permissions: string[]): Promise<IModule> => {
    const response = await axios.post<{ uuid: string }>(
        `${process.env.CLIENT_API}/account/modules`,
        {
            name,
            prices,
            permissions
        },
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );

    return {
        uuid: response.data.uuid,
        name,
        prices,
        permissions,
        icon: 'icon-modules',
        color: '#555555',
        order: 9999
    };
};

export const updateModule = async (module: IModule): Promise<AxiosResponse> => {
    const response = await axios.post<{ uuid: string }>(
        `${process.env.CLIENT_API}/account/modules/${module.uuid}`,
        {
            name:module.name,
            prices: module.prices,
            permissions: module.permissions
        },
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );

    return response;
};

export const sortModules = async (moduleUuids: string[]): Promise<AxiosResponse> => {
    const response = await axios.post<{ uuid: string }>(
        `${process.env.CLIENT_API}/account/modules/sort`,
        {
            module_uuids: moduleUuids.map((moduleUuid) => ({ uuid: moduleUuid }))
        },
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );

    return response
};
