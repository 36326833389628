import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducer';

const __DEV__ = process.env.NODE_ENV === 'development';
const middlewares = [thunk];
const composeEnhancers: typeof compose =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  undefined,
  composeEnhancers(applyMiddleware(...middlewares))
);

if (__DEV__ && module.hot) {
  // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
  module.hot.accept('./reducer', () => store.replaceReducer(require('./reducer').default));
}

export default store;
