import React from 'react';

import './SecondaryNav.scss';

const SecondaryNav: React.FunctionComponent = (props: any) => (
  <div className="secondaryNav">
    <div className="full">{props.children}</div>
  </div>
);

export default SecondaryNav;
