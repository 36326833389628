import {useState, useEffect, SyntheticEvent} from 'react';
import {sendOrganisationContactPerson} from '../AddAccount/ApiClient';
import {IOrganisation} from '../Store/IOrganisation';
import {IContact} from '../ContactList/ContactStore/IContact';
import {IError} from '../IError';

const useContactForm = (
  organisation: IOrganisation
): [
  IContact,
  IError[],
  (contact: IContact) => void,
  (key: string, value: string) => void,
  (e: SyntheticEvent) => void,
  string,
  () => void,
  () => void
] => {
  const originalContact: IContact = {
    name: '',
    lastName: '',
    email: '',
    phone: '',
    addressId: '',
  };

  const [contact, setContact] = useState<IContact>(originalContact);
  const [submitAttempted, setSubmitAttempted] = useState<boolean>(false);
  const [status, setStatus] = useState('');

  const [errors, setErrors] = useState<IError[]>([]);

  useEffect(() => {
    if (submitAttempted) {
      validate();
    }
  }, [contact]);

  const validate = () => {
    const newErrors = [];

    if (!contact.name) {
      newErrors.push({message: 'name is required', property: 'name'});
    }

    if (!contact.email) {
      newErrors.push({message: 'email is required', property: 'email'});
    }

    setErrors(newErrors);

    return newErrors.length === 0;
  };

  const setValue = (key: string, value: string): void => {
    contact[key] = value;

    setContact(contact);
  };
  const onSubmit = (e: SyntheticEvent): void => {
    e.preventDefault();
    setSubmitAttempted(true);
    if (!validate()) {
      return;
    }

    setStatus('submitting');
    sendOrganisationContactPerson(organisation.id, contact).then(
      () => {
        setStatus('success');
      },
      () => {
        setStatus('failed');
      }
    );
  };

  const reset = (): void => {
    if (originalContact) setContact(originalContact);
    setStatus('default');
    setSubmitAttempted(false);
  };
  const tryAgain = (): void => {
    setStatus('default');
    setSubmitAttempted(false);
  };

  return [contact, errors, setContact, setValue, onSubmit, status, reset, tryAgain];
};

export default useContactForm;
