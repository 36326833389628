import axios from 'axios';
import React, {Suspense} from 'react';
import {I18nextProvider} from 'react-i18next';
import {Provider} from 'react-redux';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import i18n from '../i18n';
import App from './App';
import AppLoadingDots from './Components/AppLoadingDots/AppLoadingDots';

import store from './Store';

type State = {
  error: Error | null;
};

class Root extends React.Component<{}, State> {
  private RedBoxConstructor: typeof React.Component | null = null;

  constructor(props: any) {
    super(props);

    this.state = {
      error: null,
    };
  }

  componentWillReceiveProps() {
    this.setState({error: null});
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    require('./App.lazybundle')(({RedBox}: any) => {
      this.RedBoxConstructor = RedBox.default;
      this.setState({error});
    });
  }

  componentDidMount(): void {
    axios.interceptors.request.use(
      config => {
        if (config.headers.authorization !== false && !config.headers['X-CUSTOM-AUTH']) {
          config.headers['X-CUSTOM-AUTH'] = store.getState().security.accessToken;
        } else if (config.headers.authorization === false) {
          delete config.headers.authorization;
        }

        if (!config.headers['Content-Type']) {
          config.headers['Content-Type'] = 'application/json';
        }

        return config;
      },
      error =>
        // @todo: handle refresh: https://medium.com/swlh/handling-access-and-refresh-tokens-using-axios-interceptors-3970b601a5da
        Promise.reject(error)
    );
  }

  render() {
    const {error} = this.state;
    if (error && process.env.NODE_ENV !== 'test' && typeof this.RedBoxConstructor === 'function') {
      return <this.RedBoxConstructor error={error} />;
    }

    return (
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <Suspense fallback={<AppLoadingDots />}>
            <Router>
              <Route path="/" component={App} />
            </Router>
          </Suspense>
        </Provider>
      </I18nextProvider>
    );
  }
}

export default Root;
