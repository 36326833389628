import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {compose, Dispatch} from 'redux';
import debounce from 'lodash.debounce';

import Filter from '../../App/Components/Filter/Filters';
import {IFilter} from '../../App/Components/Filter/IFilter';
import TableComponent from '../../App/Table/Table';
import TableColumn from '../../App/Table/TableColumn';
import {openModal} from '../../App/Components/Modal/Actions';
import {getTranslationFilters} from './translationFilters';
import TranslationsDataProvider from './TranslationsDataProvider';
import TextInput from '../../App/Components/TextInput/TextInput';

interface IProps {
  domain?: string;
}

interface IConnectedProps extends IProps, WithTranslation {
  dispatch: Dispatch<any>;
  openModal: (content: any) => {};
}

interface IState {
  domain: string;
  label: string;
  search: string;
  refresh: string;
}

class Table extends React.Component<IConnectedProps, IState> {
  translationDataProvider: TranslationsDataProvider = new TranslationsDataProvider('messages');

  debouncedRefresh = debounce(this.updateRefresh, 1000);

  constructor(props: IConnectedProps) {
    super(props);
    this.state = {
      domain: props.domain ? props.domain : 'messages',
      label: props.domain
        ? props.domain.charAt(0).toUpperCase() + props.domain.slice(1)
        : 'Messages',
      search: '',
      refresh: props.domain ? props.domain : 'messages',
    };
    this.translationDataProvider.setDomain(props.domain ? props.domain : 'messages');
    this.openFilter = this.openFilter.bind(this);
    this.onHandleFilterChange = this.onHandleFilterChange.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.updateRefresh = this.updateRefresh.bind(this);
  }

  openFilter() {
    this.props.openModal(
      <div className="modal-filter">
        <Filter
          filters={getTranslationFilters(this.state.domain)}
          onChange={this.onHandleFilterChange}
        />
      </div>
    );
  }

  onHandleFilterChange(activeFilters: IFilter[]) {
    let activeDomain = this.state.domain;
    let activeDomainLabel = this.state.label;
    for (const activeFilter of activeFilters) {
      if (activeFilter.key !== 'domains') {
        continue;
      }

      for (const option of activeFilter.options) {
        activeDomain = option.value;
        activeDomainLabel = option.label;
      }
    }

    this.translationDataProvider.setDomain(activeDomain);
    this.setState({
      domain: activeDomain,
      label: activeDomainLabel,
      refresh: activeDomain + this.state.search,
    });
  }

  onSearchChange(value: string) {
    this.setState({search: value});
    this.translationDataProvider.setSearch(value);
    this.debouncedRefresh();
  }

  updateRefresh() {
    this.setState({refresh: this.state.domain + this.state.search});
  }

  render() {
    const {t} = this.props;
    return (
      <div className="main-content">
        <div className="page-layout large">
          <h1>{t('Translations')}</h1>
          <Link to={`/translations/add/${this.state.domain}`} className="button">
            <FontAwesomeIcon icon={['fas', 'plus']} />
          </Link>
          <button className="button" onClick={this.openFilter}>
            <FontAwesomeIcon icon={['fas', 'filter']} /> &nbsp;
            {this.state.label}
          </button>
          <TextInput
            label={false}
            value={this.state.search}
            onChange={this.onSearchChange}
            className="translations-search-input"
            placeholder={t<string>('Search')}
          />
          <TableComponent
            pageSize={50}
            dataProvider={this.translationDataProvider}
            refresh={this.state.refresh}
          >
            <TableColumn dataKey="key" collapsable={false}>
              {t('Key')}
            </TableColumn>
            <TableColumn dataKey="en" editable>
              {t('English')}
            </TableColumn>
            <TableColumn dataKey="nl" editable>
              {t('Flemmish')}
            </TableColumn>
            <TableColumn dataKey="nl_NL" editable collapsed>
              {t('Dutch')}
            </TableColumn>
            <TableColumn dataKey="fr" editable>
              {t('French')}
            </TableColumn>
            <TableColumn dataKey="it" editable collapsed>
              {t('Italian')}
            </TableColumn>
            <TableColumn dataKey="de" editable collapsed>
              {t('German')}
            </TableColumn>
            <TableColumn dataKey="sq" editable collapsed>
              {t('Albanian')}
            </TableColumn>
          </TableComponent>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openModal: (content: any) => dispatch(openModal(content)),
});

export default compose(
  connect(undefined, mapDispatchToProps),
  withTranslation()
)(Table) as React.ComponentType<IProps>;
