import {Dispatch} from 'redux';
import InvoiceInfoClient from './InvoiceInfoClient';
import {IInvoiceInfo} from './IInvoiceInfo';

export const GET_ORGANISATION_INVOICEINFO = 'GET_ORGANISATION_INVOICEINFO';
export const ORGANISATION_INVOICEINFO_LOADING = 'ORGANISATION_INVOICEINFO_LOADING';

export function getOrganisationInvoiceInfo(organisationId: string) {
  return (dispatch: Dispatch<any>) => () => {
    dispatch(invoiceInfoLoading());
    InvoiceInfoClient.getInvoiceInfo(organisationId).then(response => {
      const transformedInvoiceInfo = response.data.data.map((invoiceInfo: IInvoiceInfo) => {
        invoiceInfo.organisationId = organisationId;
        return invoiceInfo;
      });
      dispatch(organisationInvoiceInfo(transformedInvoiceInfo));
    });
  };
}

function invoiceInfoLoading() {
  return {
    type: ORGANISATION_INVOICEINFO_LOADING,
  };
}

function organisationInvoiceInfo(invoiceInfo: any) {
  return {
    type: GET_ORGANISATION_INVOICEINFO,
    invoiceInfo,
  };
}
