import {Dispatch} from 'redux';
import AdressClient from './AddressClient';
import {IAddress, IApiAddress} from './IAddress';
import AddressTransformer from './AddressTransformer';

export const GET_ORGANISATION_ADDRESSES = 'GET_ORGANISATION_ADDRESSES';
export const ORGANISATION_ADDRESSES_LOADING = 'ORGANISATION_ADDRESSES_LOADING';

export function getOrganisationAddresses(organisationId: string) {
  return (dispatch: Dispatch<any>) => () => {
    dispatch(organisationLoading());
    AdressClient.getOrganisationAddresses(organisationId).then(response => {
      const addresses: IAddress[] = response.data.data.map((apiAddress: IApiAddress): IAddress => {
        const transformedAddress = AddressTransformer.transform(apiAddress);
        return transformedAddress;
      });
      dispatch(organisationAddresses(addresses));
    });
  };
}

function organisationLoading() {
  return {
    type: ORGANISATION_ADDRESSES_LOADING,
  };
}

function organisationAddresses(addresses: any) {
  return {
    type: GET_ORGANISATION_ADDRESSES,
    addresses,
  };
}
