import React, { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updatePartner, getPartners } from "../../store/Partners/Action";
import PartnerForm from "../PartnerForm/PartnerForm";
import { IPartner } from "../../store/Partners/interface/IPartner";
import { IAppState } from "../../App/IAppState";
import LoadingDots from "../../App/LoadingDots/LoadingDots";

const UpdatePartner: FunctionComponent = () => {
  const {t} = useTranslation()

  const dispatch = useDispatch();
  const history = useHistory();
  const { partnerUuid } = useParams<{partnerUuid: string}>()
  const partners = useSelector((state: IAppState) => state.partners.list)

  const [partner, setPartner] = useState<IPartner | undefined>()

  const onSubmit = async (updatedPartner: Partial<IPartner>) => {
    await dispatch(updatePartner({ ...updatedPartner, uuid: partnerUuid } as IPartner))
    history.push('/partners')
  }
    
  useEffect(() => {
    if (!partners) {
      dispatch(getPartners());
      return;
    }

    const foundPartner = partners.find((onePartner) => onePartner.uuid === partnerUuid)
    if (! foundPartner) {
      history.push('/partners');
      return;
    }
    setPartner(foundPartner);
  }, [partners])

  if (!partner) {
    return <LoadingDots/>
  }

  return (
    <div className="main-content" style={{textAlign: 'center'}}>
      <div style={{margin: '0 auto 2em auto', maxWidth: '480px', textAlign: 'left'}}>
        <h1>{partner.firstName} {partner.lastName}</h1>
        <h2>{t('Update partner')}</h2>
        <PartnerForm onSubmit={onSubmit} partner={partner} />
      </div>
    </div>
  )
}

export default UpdatePartner
