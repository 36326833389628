import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IAppState} from 'App/IAppState';
import React from 'react';
import {connect} from 'react-redux';
import {Link, Route, Switch} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {debounce} from 'ts-debounce';
import {ThunkDispatch} from 'redux-thunk';
import Account from './Account/Account';
import AddAccount from './AddAccount/AddAccount';
import {getAccounts, getAccountsForSearch, getOrganisation} from './Store/Actions';
import './Accounts.scss';

interface IState {
  searchTerm: string;
  accountListVisible: boolean;
  hasSearched: boolean;
}

interface IStateProps {
  error: string | null;
  accounts: [];
  loading: boolean;
}

interface IDispatchProps {
  getAccounts: () => void;
  getAccountsForSearch: any;
  getOrganisation: any;
}

type IProps = IStateProps & IDispatchProps;

class Accounts extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      searchTerm: '',
      accountListVisible: false,
      hasSearched: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.searchAccounts = debounce(this.searchAccounts.bind(this), 1000);
    this.showAccountsList = this.showAccountsList.bind(this);
    this.hideAccountsList = debounce(this.hideAccountsList.bind(this), 200);
  }

  handleInputChange(e: any) {
    this.setState({
      searchTerm: e.target.value,
    });
    this.searchAccounts(e.target.value);
  }

  searchAccounts(searchTerm: string): any {
    if (searchTerm !== '') {
      this.props.getAccountsForSearch(searchTerm);
      this.setState({
        hasSearched: true,
      });
    }
  }

  showAccountsList() {
    this.setState({
      accountListVisible: true,
    });
  }

  hideAccountsList() {
    this.setState({
      accountListVisible: false,
    });
  }

  render() {
    const {accounts, loading} = this.props;

    return (
      <div className="accounts">
        <div className="search-nav-bar">
          <input
            type="text"
            className="input"
            autoFocus
            onFocus={this.showAccountsList}
            onBlur={this.hideAccountsList}
            onChange={this.handleInputChange}
            value={this.state.searchTerm}
            onKeyPress={(e: any) => {
              if (e.key === 'Enter') {
                this.searchAccounts(this.state.searchTerm);
              }
            }}
            placeholder="Search..."
          />
          <div className="loader-icon">
            {loading ? (
              <FontAwesomeIcon icon={['fas', 'spinner']} className="fa-spin accountIcon" />
            ) : (
              <FontAwesomeIcon icon={['fas', 'search']} className="accountIcon" />
            )}
          </div>
          <Link className="action" to="/accounts/add">
            <FontAwesomeIcon icon={['fas', 'plus']} className="addIcon" />
          </Link>

          {accounts.length === 0 &&
          this.state.searchTerm !== '' &&
          !loading &&
          this.state.accountListVisible ? (
            this.state.hasSearched === true ? (
              <div className="search-results">
                <div className="account-list-wrapper">No organisations found.</div>
              </div>
            ) : (
              ''
            )
          ) : this.state.accountListVisible ? (
            <div className="search-results">
              <div className="account-list-wrapper">
                {this.props.accounts.map((organisation: any) => (
                  <Link to={`/accounts/${organisation.id}`} key={organisation.id}>
                    <li>{organisation.name}</li>
                  </Link>
                ))}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>

        <div className="page-content">
          <Switch>
            <Route exact path="/accounts/add" component={AddAccount} />
            <Route path="/accounts/:organisationId" component={Account} />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState): IStateProps => ({
  error: state.accounts.error,
  accounts: state.accounts.search.result,
  loading: state.accounts.search.isLoading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): IDispatchProps =>
  bindActionCreators(
    {
      getAccounts: () => dispatch(getAccounts()),
      getAccountsForSearch: (searchTerm: string) => dispatch(getAccountsForSearch(searchTerm)),
      getOrganisation: (organisationId: string) => dispatch(getOrganisation(organisationId)),
    },
    dispatch
  );

export default connect<IStateProps, IDispatchProps, {}, IAppState>(
  mapStateToProps,
  mapDispatchToProps
)(Accounts);
