import React, {useState} from 'react';
import './Card.scss';

interface IProps {
  style?: string;
  selected?: boolean;
  onChanged?: (isSelected: boolean) => void;
}

const Card: React.FunctionComponent<IProps> = props => {
  const cardStyle = ['card'];
  const [selected, setSelected] = useState(props.selected);

  if (typeof props.selected !== 'undefined') {
    cardStyle.push('selectable');
  }

  const handleOnClick = () => {
    if (typeof props.selected === 'undefined') {
      return;
    }
    if (props.onChanged) {
      props.onChanged(!selected);
    }
    setSelected(!selected);
  };

  if (props.style) {
    if (props.style === 'optios') {
      cardStyle.push('optios');
    } else if (props.style === 'esthios') {
      cardStyle.push('esthios');
    }
  }

  if (selected) {
    cardStyle.push('selected');
  }

  return (
    <div className={cardStyle.join(' ')} onClick={handleOnClick}>
      {props.children}
    </div>
  );
};

export default Card;
