import React from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import SelectInput from '../../../App/Components/SelectInput/SelectInput';

import TextareaInput from '../../../App/Components/TextareaInput/TextareaInput';
import TextInput from '../../../App/Components/TextInput/TextInput';
import {IAppState} from '../../../App/IAppState';
import LoadingDots from '../../../App/LoadingDots/LoadingDots';
import useForm from './useForm';

interface IProps {
  accessToken: string;
}

const Broadcast: React.FunctionComponent<IProps> = props => {
  const {t} = useTranslation();
  const [
    subject,
    subjectError,
    body,
    bodyError,
    action,
    actionError,
    type,
    language,
    setValue,
    onSubmit,
    status,
    reset,
  ] = useForm(props.accessToken);

  let content;
  if (status === 'submitting') {
    content = <LoadingDots />;
  } else if (status === 'success') {
    content = (
      <>
        <div className="status-message success">{t('Broadcast notification has been sent.')}</div>
        <button className="button" onClick={reset}>
          {t('Send new notification')}
        </button>
      </>
    );
  } else if (status === 'failed') {
    content = (
      <>
        <div className="status-message error">{t('Unable to send broadcast notification.')}</div>
        <button className="button" onClick={reset}>
          {t('Try again')}
        </button>
      </>
    );
  } else {
    content = (
      <form onSubmit={onSubmit}>
        <TextInput
          label="Subject"
          value={String(subject)}
          onChange={value => {
            setValue('subject', value);
          }}
          errorMessage={String(subjectError)}
          full
        />
        <TextareaInput
          label={t<string>('Body')}
          value={String(body)}
          onChange={value => {
            setValue('body', value);
          }}
          errorMessage={String(bodyError)}
          full
        />
        <TextInput
          label={t<string>('Action')}
          value={String(action)}
          onChange={value => {
            setValue('action', value);
          }}
          errorMessage={String(actionError)}
          full
        />
        <SelectInput
          label={t<string>('Type')}
          options={[
            {label: 'Software update', value: 'update'},
            {label: 'Announcement', value: 'announcement'},
          ]}
          value={String(type)}
          onChange={value => {
            setValue('type', value);
          }}
          full
        />
        <SelectInput
          label={t<string>('Language')}
          options={[
            {label: 'Nederlands', value: 'nl'},
            {label: 'français', value: 'fr'},
            {label: 'English', value: 'en'},
          ]}
          value={String(language)}
          onChange={value => {
            setValue('language', value);
          }}
          full
        />
        <button type="submit" className="button">
          {t('Send')}
        </button>
      </form>
    );
  }

  return (
    <div className="main-content">
      <div className="page-layout">
        <h1>{t('Broadcast notification')}</h1>
        {content}
      </div>
    </div>
  );
};

const mapStateToProps = (state: IAppState) => ({accessToken: state.security.accessToken});

export default connect(mapStateToProps)(Broadcast);
