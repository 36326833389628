import React, {useState, KeyboardEvent, useEffect} from 'react';
import './EditableContent.scss';

interface IProps {
  onBlur: any;
  text?: string;
}
const EditableContent: React.FunctionComponent<IProps> = props => {
  const [text, setText] = useState(props.text);
  const [editMode, setEditMode] = useState(false);

  const handleKeyPress = (event: KeyboardEvent<any>) => {
    if (event.key === 'Enter') {
      handleNameEdit(event);
    }
  };

  const onBlur = (event: any) => {
    handleNameEdit(event);
  };

  const handleNameEdit = (event: any) => {
    if (event.currentTarget.value === '') {
      setText(props.text);
    } else if (event.currentTarget.value) {
      setText(event.currentTarget.value);
      props.onBlur(event.currentTarget.value);
    }
    setEditMode(false);
  };

  useEffect(() => {
    setText(props.text);
  }, [props.text]);

  return (
    <input
      placeholder={text}
      onFocus={() => {
        setEditMode(true);
      }}
      className={editMode ? 'edit on' : 'edit off'}
      value={text}
      onInput={e => setText(e.currentTarget.value)}
      onKeyPress={handleKeyPress}
      onBlur={onBlur}
    />
  );
};

export default EditableContent;
