import React, {useEffect} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {getOrganisationEstablishments} from '../EstablishmentStore/Actions';
import {IAppState} from '../../../App/IAppState';
import {IOrganisation} from '../../Store/IOrganisation';
import Card from '../../../App/Components/Card/Card';
import CardRow from '../../../App/Components/Card/CardRow/CardRow';
import CardDescription from '../../../App/Components/Card/Description/Description';
import CardHeader from '../../../App/Components/Card/CardHeader/CardHeader';
import {languages} from '../../../App/Resources/dropDownMenus/languages';
import esthiosTransparant from '../../../App/Resources/logos/esthios_transparant.png';
import optiosTransparant from '../../../App/Resources/logos/optios_transparant.png';
import LoadingDots from '../../../App/LoadingDots/LoadingDots';
import {IEstablishment} from '../EstablishmentStore/IEstablishment';

interface IDispatchProps {
  getOrganisationEstablishments: (organisationId: string) => void;
}
interface IStateProps {
  establishments: IEstablishment[];
  establishmentsLoading: boolean;
}
interface IComponentProps {
  organisation: IOrganisation;
  loading: boolean;
  selectable: boolean;
  selectedEstablishmentIds?: string[];
  onChanged?: (isSelected: boolean, establishmentId: string) => void;
}

type IProps = IStateProps & IDispatchProps & IComponentProps;

const EstablishmentCards: React.FunctionComponent<IProps> = props => {
  const {t} = useTranslation();
  useEffect(() => {
    if (!props.establishmentsLoading && props.establishments.length === 0) {
      props.getOrganisationEstablishments(props.organisation.id);
    }
  }, [props.organisation]);

  const onCardSelected = (isSelected: boolean, establishmentId: string) => {
    if (props.onChanged && props.selectable === true) {
      props.onChanged(isSelected, establishmentId);
    }
  };

  const isEstablishmentSelected = (establishment: IEstablishment) => {
    if (!props.selectable) {
      return undefined;
    }

    if (!props.selectedEstablishmentIds) {
      return false;
    }

    const establishmentIndex = props.selectedEstablishmentIds.findIndex(
      (establishmentId: string) => establishmentId === establishment.id
    );

    return establishmentIndex >= 1;
  };

  const establishmentCards = props.establishments.map((establishment: IEstablishment) => {
    const language = languages.find(
      currentLanguage => currentLanguage.value === establishment.language
    );

    return (
      <Card
        style={establishment.label}
        selected={isEstablishmentSelected(establishment)}
        onChanged={(isSelected: boolean) => {
          props.selectable ? onCardSelected(isSelected, establishment.id) : '';
        }}
      >
        <CardHeader
          text={establishment.name}
          picture={
            establishment.label === 'esthios' ? (
              <img src={esthiosTransparant} height="40px" width="40px" />
            ) : (
              <img src={optiosTransparant} height="40px" width="40px" />
            )
          }
        />
        <CardRow>
          <CardDescription text={establishment.email ? establishment.email : ''} />
        </CardRow>
        <CardRow>
          <CardDescription text={language ? language.label : t('No language found')} />
        </CardRow>
      </Card>
    );
  });
  const loadingCard = () => (
    <Card>
      <LoadingDots />
    </Card>
  );

  return (
    <div className="establishments">
      {props.establishmentsLoading ? loadingCard() : establishmentCards}
    </div>
  );
};

const mapStateToProps = (state: IAppState, componentProps: IComponentProps): IStateProps => ({
  establishments: state.organisationEstablishments.establishments.filter(
    (element: IEstablishment) => element.organisationId === componentProps.organisation.id
  ),
  establishmentsLoading: state.organisationEstablishments.loading,
});

const mapDispatchToProps = (dispatch: any): IDispatchProps =>
  bindActionCreators(
    {
      getOrganisationEstablishments: (organisationId: string) =>
        dispatch(getOrganisationEstablishments(organisationId)),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EstablishmentCards);
