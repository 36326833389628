import React, {useEffect} from 'react';
import {useDispatch, useSelector, } from "react-redux";
import {Link} from 'react-router-dom';
import {SortableContainer, SortableElement, SortEnd, SortEvent} from "react-sortable-hoc";
import {arrayMoveImmutable} from 'array-move';

import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconName} from "@fortawesome/free-brands-svg-icons";
import {IAppState} from "../App/IAppState";
import {IModule} from "../store/Module/IModule";
import AppLoadingDots from "../App/Components/AppLoadingDots/AppLoadingDots";
import {getModules, sortModules} from "../store/Module/actions";
import './Overview.scss'

const iconMapper = (icon: string): IconName => {
    switch (icon) {
        case 'icon-customers':
            return 'users'
        case 'icon-calendar':
            return 'calendar'
        case 'icon-modules':
        default:
            return 'th'
    }
}

const SortableItem = SortableElement<{module: IModule}>(({module}: { module: IModule }) => (
    <div key={`module${module.uuid}`} className="module-card">
        <div className="module-card-icon" style={{color: module.color}}><FontAwesomeIcon
            icon={['fas', iconMapper(module.icon)]}/></div>
        <div className="module-card-content">
            <div><FontAwesomeIcon icon={['fas', 'tag']}/> {module.name}</div>
            <div><FontAwesomeIcon icon={['fas', 'money-bill-wave-alt']}/> {module.prices.BE.toFixed(2)} EUR</div>
            <div><FontAwesomeIcon icon={['fas', 'key']}/> {module.permissions.join(', ')}</div>
        </div>
        <div className="module-card-actions">
            <Link to={`/modules/${module.uuid}/update`}><FontAwesomeIcon icon={'edit'} /></Link>
        </div>
    </div>
));

const SortableList = SortableContainer<{modules: IModule[]}>(({modules}: { modules: IModule[] }) => (
    <div>
        {modules.map((module: IModule, index: number) => (
            <SortableItem key={`module-${module.uuid}`} index={index} module={module} />
        ))}
    </div>
));

const Orders: React.FunctionComponent = () => {
    const modules: IModule[] | undefined = useSelector((state: IAppState) => state.modules.list)
    const dispatch = useDispatch()
    const {t} = useTranslation()

    useEffect(() => {
            if (typeof modules === 'undefined') {
                dispatch(getModules())
            }
        },
        [modules]
    )

    const onSortEnded = (sortEnd: SortEnd, sortEvent: SortEvent) => {
        console.log(sortEnd, sortEvent)
        if (!modules) {
            return
        }

        const moduleIds: string[] = arrayMoveImmutable(modules, sortEnd.oldIndex, sortEnd.newIndex)
            .map((module) => module.uuid)

        dispatch(sortModules(moduleIds))
    }

    if (typeof modules === 'undefined') {
        return <AppLoadingDots />
    }

    return <div className="modules-container">
        <h1>{t('Modules')}</h1>
        <Link to="/modules/create" className="button" style={{marginBottom: '1em'}}>{t('Add new module')}</Link>
        <SortableList modules={modules} onSortEnd={onSortEnded} pressDelay={200} />
    </div>
};

export default Orders;
