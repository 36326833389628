import React, { FormEvent, FunctionComponent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from "react-router-dom";
import TextInput from "../../App/Components/TextInput/TextInput";
import SelectInput, { ISelectInputOption } from "../../App/Components/SelectInput/SelectInput";
import { IPartner } from "../../store/Partners/interface/IPartner";

interface IProps {
  onSubmit: (partner: Partial<IPartner & {password?: string}>) => Promise<void>,
  partner?: IPartner
}

const PartnerForm: FunctionComponent<IProps> = ({ onSubmit, partner }) => {
  const {t} = useTranslation()

    type TLanguage = 'nl' | 'fr' | 'en' | 'de';

    const [firstName, setFirstName] = useState<string>(partner ? partner.firstName : '');
    const [lastName, setLastName] = useState<string>(partner ? partner.lastName : '');
    const [email, setEmail] = useState<string>(partner ? partner.email : '');
    const [password, setPassword] = useState<string>('');
    const [repeatPassword, setRepeatPassword] = useState<string>('');
    const [language, setLanguage] = useState<TLanguage>(partner ? partner.language : 'nl');
    const [pipedriveId, setPipedriveId] = useState<string | null>(partner ? partner.pipedriveId : null);

    const [showErrors, setShowErrors] = useState<boolean>(false);
    const [firstNameError, setFirstNameError] = useState<string>();
    const [lastNameError, setLastNameError] = useState<string>();
    const [emailError, setEmailError] = useState<string>();
    const [passwordError, setPasswordError] = useState<string>();
    const [repeatPasswordError, setRepeatPasswordError] = useState<string>();
    const [pipedriveIdError, setPipedriveIdError] = useState<string>();

    const [isSaving, setIsSaving] = useState<boolean>();

    const languageOptions: ISelectInputOption[] = [
      {value: 'nl', label: 'Nederlands'},
      {value: 'fr', label: 'français'},
      {value: 'en', label: 'English'},
      {value: 'de', label: 'Deutsch'},
    ]

    const validate = (): boolean => {
      let isValid = true;
      if (firstName === '') {
        setFirstNameError(t('Firstname is required'));
        isValid = false;
      } else {
        setFirstNameError(undefined);
      }

      if (lastName === '') {
        setLastNameError(t('Lastname is required'));
        isValid = false;
      } else {
        setLastNameError(undefined);
      }

      if (email === '') {
        setEmailError(t('Email is required'));
        isValid = false;
      } else {
        setEmailError(undefined);
      }

      if (! partner) {
        if (password === '') {
          setPasswordError(t('Password is required'));
          isValid = false;
        } else {
          setPasswordError(undefined);
        }

        if (repeatPassword !== password) {
          setRepeatPasswordError(t('Password confirmation is not equal to the password'));
          isValid = false;
        } else {
          setRepeatPasswordError(undefined);
        }
      }

      if (pipedriveId === null || pipedriveId === '') {
        setPipedriveId(null);
        setPipedriveIdError(undefined)
      } else {
        const pipedriveIdNumber = parseInt(pipedriveId, 10);
        if (Number.isNaN(pipedriveIdNumber)) {
          setPipedriveIdError('Pipedrive id is invalid')
          isValid = false;
        } else {
          setPipedriveIdError(undefined)
        }        
      }
    
      return isValid
    }

    const onFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setShowErrors(true);
      if (validate()) {
        setIsSaving(true)
        await onSubmit({
          firstName,
          lastName,
          email,
          language,
          pipedriveId,
          password
        })

        setIsSaving(false)
      }

      return false;
    }

    return (
      <form onSubmit={onFormSubmit}>
          <TextInput
              label={t('Firstname')}
              value={firstName}
              onChange={(newValue) => setFirstName(newValue)}
              errorMessage={showErrors ? firstNameError : undefined}
              full
          />
          <TextInput
              label={t('Lastname')}
              value={lastName}
              onChange={(newValue) => setLastName(newValue)}
              errorMessage={showErrors ? lastNameError : undefined}
              full
          />
          <TextInput
              label={t('Email')}
              value={email}
              type="email"
              onChange={(newValue) => setEmail(newValue)}
              errorMessage={showErrors ? emailError : undefined}
              full
          />

          { partner ? null : (
              <><TextInput
                  label={t('Password')}
                  type="password"
                  value={password}
                  onChange={(newValue) => setPassword(newValue)}
                  errorMessage={showErrors ? passwordError : undefined}
                  full/><TextInput
                  label={t('Repeat password')}
                  type="password"
                  value={repeatPassword}
                  onChange={(newValue) => setRepeatPassword(newValue)}
                  errorMessage={showErrors ? repeatPasswordError : undefined}
                  full/></>
          )}

          <SelectInput
              label={t('Language')}
              options={languageOptions}
              value={language}
              onChange={(newValue) => setLanguage(newValue as TLanguage)}
              full
          />
          <TextInput
              label={t('Pipedrive ID')}
              value={pipedriveId ?? ''}
              onChange={(newValue) => setPipedriveId(newValue)}
              errorMessage={showErrors ? pipedriveIdError : undefined}
              full
          />
          <button type="submit" className={isSaving ? 'button dim' : 'button'} disabled={isSaving}>
              {t('Save')}
          </button>
          <Link className="button dim" to="/partners">{t('Cancel')}</Link>
      </form>
    )
}

export default PartnerForm
