import React from 'react';
import {IFilter} from './IFilter';
import {IFilterOption} from './IFilterOption';

interface IProps {
  filters: Array<IFilter>;
  onChange: (activeFilterOptions: Array<any>) => void;
}

interface IState {
  filters: Array<IFilter>;
}

class Filters extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.onHandleClick = this.onHandleClick.bind(this);

    this.state = {
      filters: props.filters,
    };
  }

  onHandleClick(selectedOption: IFilterOption, selectedFilter: IFilter) {
    const filters = JSON.parse(JSON.stringify(this.state.filters));

    const activeFilters = [];
    for (let i = 0; i < filters.length; i++) {
      for (let j = 0; j < filters[i].options.length; j++) {
        if (filters[i].key === selectedFilter.key) {
          if (filters[i].options[j].value === selectedOption.value) {
            filters[i].options[j].isActive = !filters[i].options[j].isActive;
          } else if (selectedFilter.multiSelect === false) {
            filters[i].options[j].isActive = false;
          }
        }
      }

      const activeOptions: Array<IFilterOption> = filters[i].options.filter(
        (option: IFilterOption) => option.isActive
      );

      if (activeOptions.length > 0) {
        const activeFilter = {...filters[i]};
        activeFilter.options = activeOptions;
        activeFilters.push(activeFilter);
      } else if (filters[i].required) {
        // Is required and no options are selected, stop toggline last active option
        return;
      }
    }

    this.setState({
      filters,
    });

    this.props.onChange(activeFilters);
  }

  render() {
    const filters = [];
    for (let i = 0; i < this.state.filters.length; i++) {
      const filter = this.state.filters[i];

      const filterOptions = [];
      for (let j = 0; j < filter.options.length; j++) {
        const option = filter.options[j];
        filterOptions.push(
          <li
            key={`filter_option_${i}_${j}`}
            className={option.isActive ? 'active' : ''}
            onClick={() => this.onHandleClick(option, filter)}
          >
            <div className="filter">{option.label}</div>
          </li>
        );
      }

      filters.push(
        <div key={`filter_${i}`}>
          <div className="filter-label">{filter.label}</div>
          {filterOptions}
        </div>
      );
    }

    return <div>{filters}</div>;
  }
}

export default Filters;
