import {Reducer} from 'redux';
import Moment from 'moment';
import {LOADING, GET_STATS_SUCCESS, GET_LIVE_STATS_SUCCESS} from './Actions';
import {IDashboardState} from './IDashboardState';
import {IStatistic} from './IStatistic';

const initialState: IDashboardState = {
  statistics: [],
  liveStatistics: [],
};
export const reducer: Reducer<IDashboardState> = (
  state: IDashboardState = initialState,
  action: any
): IDashboardState => {
  switch (action.type) {
    case LOADING:
      const dateArray = [];
      const currentDate = new Date(action.startDate);
      const endDate = new Date(action.endDate);
      const placeholderStats = state.statistics;

      while (currentDate <= endDate) {
        dateArray.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }

      dateArray.forEach((date: Date) => {
        const momentDate = Moment(date);
        const foundStat = placeholderStats.find(
          statistic =>
            statistic.date === momentDate.format('YYYY-MM-DD') &&
            statistic.statisticType === action.statsType
        );
        if (!foundStat) {
          placeholderStats.push({
            statisticType: action.statsType,
            count: 0,
            date: momentDate.format('YYYY-MM-DD'),
            loading: true,
          });
        }
      });
      return {
        ...state,
        statistics: placeholderStats,
      };

    case GET_STATS_SUCCESS:
      const newStatistics = state.statistics;

      newStatistics.forEach(x => (x.loading = false));

      action.data.forEach((element: IStatistic) => {
        const foundStat = newStatistics.find(statistic => statistic.date === element.date);
        if (foundStat) {
          foundStat.date = element.date;
          foundStat.count = element.count;
          foundStat.statisticType = action.statsType;
          foundStat.loading = false;
        }
      });
      return {
        ...state,
        statistics: newStatistics,
      };

    case GET_LIVE_STATS_SUCCESS:
      const newLiveStatistics = state.liveStatistics.concat({
        organisation: action.organisation,
        data: action.data,
      });
      return {
        ...state,
        liveStatistics: newLiveStatistics,
      };

    default:
      return state;
  }
};
