import {TFunction} from 'i18next';
import React from 'react';
import {useTranslation} from 'react-i18next';
import LoadingDots from '../../App/LoadingDots/LoadingDots';
import {countries} from '../../App/Resources/dropDownMenus/countries';
import {languages} from '../../App/Resources/dropDownMenus/languages';
import Dropdown from '../../App/Components/Dropdown/Dropdown';
import TextInput from '../../App/Components/TextInput/TextInput';
import useForm from './useForm';

const InviteTrialAccount: React.FunctionComponent = () => {
  const {t} = useTranslation();

  return (
    <div className="add-account-wrapper">
      <h1>{t('Create trial account')}</h1>
      {getContent(t)}
    </div>
  );
};

const getContent = (t: TFunction) => {
  const [
    company,
    companyError,
    fullName,
    fullNameError,
    email,
    emailError,
    countryCode,
    countryCodeError,
    language,
    languageError,
    setvalue,
    onSubmit,
    status,
    reset,
  ] = useForm();

  if (status === 'submitting') {
    return <LoadingDots />;
  }

  if (status === 'success') {
    return (
      <>
        <div className="status-message success">{t('A trial invitation has been sent.')}</div>
        <button className="button" onClick={reset}>
          {t('Create another')}
        </button>
      </>
    );
  }

  if (status === 'failed') {
    return (
      <>
        <div className="status-message error">{t('Unable to create invitation.')}</div>
        <button className="button" onClick={reset}>
          {t('Try again')}
        </button>
      </>
    );
  }

  return (
    <form onSubmit={onSubmit}>
      <TextInput
        label={t('Organisation name')}
        value={String(company)}
        onChange={value => {
          setvalue('organisation', value);
        }}
        errorMessage={String(companyError)}
      />
      <TextInput
        label={t('Full name')}
        value={String(fullName)}
        onChange={value => {
          setvalue('fullName', value);
        }}
        errorMessage={String(fullNameError)}
      />
      <TextInput
        label={t('E-mail')}
        value={String(email)}
        onChange={value => {
          setvalue('email', value);
        }}
        errorMessage={String(emailError)}
      />
      <Dropdown
        label={t('Country')}
        value={String(countryCode)}
        onChange={(selectedItem: string) => {
          setvalue('countryCode', selectedItem);
        }}
        errorMessage={String(countryCodeError)}
        options={countries}
      />
      <Dropdown
        label={t('Language')}
        value={String(language)}
        onChange={(selectedItem: string) => {
          setvalue('language', selectedItem);
        }}
        errorMessage={String(languageError)}
        options={languages}
      />

      <button type="submit" className="button">
        {t('Create')}
      </button>
    </form>
  );
};

export default InviteTrialAccount;
