import { makeStyles } from '@material-ui/styles'

export const productStyles = makeStyles(theme => ({
  modalBox: {
    display: 'flex',
    backgroundColor: 'white',
    padding: '20px',
    flexDirection: 'column',
    width: '50%',
    minWidth: '300px',
    margin: 'auto',
  },
  inputStyle: {
    margin: '0 0 20px 0',
    minWidth: '250px',
    width: '33%',
  },
}))
