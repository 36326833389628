export const languages = [
  {
    value: 'nl',
    label: 'Nederlands',
  },
  {
    value: 'fr',
    label: 'Français',
  },
  {
    value: 'de',
    label: 'Deutsch',
  },
  {
    value: 'en',
    label: 'English',
  },
];
