import axios from 'axios';

export const AddEstablishment = (
  organisationId: string,
  name: string,
  label: string,
  language: string,
  email: string,
  with_sample_data: boolean,
  with_trial_modules: boolean
) => {
  const requestOptions: object = {
    method: 'POST',
    url: `${process.env.API_URL}/organisation/${organisationId}/add-establishment`,
    data: {
      name,
      label,
      language,
      email,
      with_sample_data,
      with_trial_modules,
    },
  };

  return axios(requestOptions);
};
