import axios, {AxiosResponse} from 'axios';

export default class AdressClient {
  static getOrganisationAddresses(organisationId: string): Promise<AxiosResponse> {
    const requestOptions: object = {
      method: 'GET',
      url: `${process.env.API_URL}/organisation/${organisationId}/addresses`,
    };

    return axios(requestOptions);
  }
}
