import { AnyAction, Reducer } from 'redux'
import moment from 'moment'
import IBrandState from './interface/IBrandState'
import { initialState } from './InitialState'
import { IBrand } from './interface/IBrand'
import {
  CREATE_BRAND_LOADING,
  CREATE_BRAND_SUCCESS,
  FETCH_STATUS_SUCCESS,
  GET_BRANDS_SUCCESS,
  REQUEST_UPDATE_SUCCESS,
  SEND_SELECTED_SUCCESS,
  SEND_UPLOAD_SUCCESS,
  UPDATE_BRAND_LOADING,
  UPDATE_BRAND_SUCCESS,
} from './Actions'

const BrandReducer: Reducer<IBrandState> = (
  state: IBrandState = initialState,
  action: AnyAction
) => {
  let newBrand: IBrand | null = null
  let prevBrands: IBrand[] | null
  let brandIndex: number | undefined
  switch (action.type) {
    case GET_BRANDS_SUCCESS:
      return { ...state, list: action.payload }
    case REQUEST_UPDATE_SUCCESS:
      brandIndex = state.list?.findIndex((brand: IBrand) => brand.id === action.payload.id)

      if (typeof brandIndex === 'undefined' || brandIndex === -1 || !state.list) {
        return state
      }

      newBrand = {
        ...state.list[brandIndex],
        lastRequestedAt: moment(),
      }
      return {
        ...state,
        list: [...state.list.slice(0, brandIndex), newBrand, ...state.list.slice(brandIndex + 1)],
      }
    case CREATE_BRAND_LOADING:
      return {
        ...state,
        loading: true,
      }
    case CREATE_BRAND_SUCCESS:
      newBrand = {
        id: action.payload.brandId,
        name: action.payload.brandName,
        lastRequestedAt: null,
        lastUpdatedAt: null,
        suppliers: [],
      }
      prevBrands = typeof state.list === 'undefined' ? [] : state.list

      return {
        ...state,
        loading: false,
        list: [...prevBrands, newBrand],
      }
    case UPDATE_BRAND_LOADING:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_BRAND_SUCCESS:
      newBrand = {
        id: action.payload.brandId,
        name: action.payload.brandName,
        lastRequestedAt: null,
        lastUpdatedAt: null,
        suppliers: [],
      }
      prevBrands =
        typeof state.list === 'undefined'
          ? []
          : state.list.filter(brand => brand.id !== action.payload.brandId)
      console.log(action.payload.brandId, action.payload)

      return {
        ...state,
        loading: false,
        list: [...prevBrands, newBrand],
      }
    case SEND_UPLOAD_SUCCESS:
      return {
        ...state,
        columnMapping: action.payload,
      }

    case SEND_SELECTED_SUCCESS:
      return {
        ...state,
        fileSelected: action.payload,
      }

    case FETCH_STATUS_SUCCESS:
      return {
        ...state,
        statusProgress: action.payload,
      }
    default:
      return state
  }
}

export default BrandReducer
