import React, {useState} from 'react';

interface IProps {
  label: string;
  checked?: boolean;
  onChange: (value: boolean) => void;
}

const Checkbox: React.FunctionComponent<IProps> = (props: IProps) => {
  const {label, onChange} = props;
  const [checked, setChecked] = useState<boolean>(props.checked || false);
  const handleChange = () => {
    onChange(!checked);
    setChecked(!checked);
  };
  return (
    <label>
      <input type="checkbox" className="checkbox" checked={checked} onChange={handleChange} />
      <div className="text" style={{flex: 1}}>{label}</div>
    </label>
  );
};

export default Checkbox;
