import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Downshift from 'downshift';
import * as React from 'react';
import './Dropdown.scss';

type OptionType = {
  value?: string;
  label?: string;
};

interface IDropdownProps {
  options: OptionType[];
  onChange?: ((selectedItem: string) => void) | any;
  label: string;
  value?: string;
  errorMessage?: string;
}

const Dropdown: React.FunctionComponent<IDropdownProps> = ({
  options,
  onChange,
  label,
  value,
  errorMessage,
}) => {
  const selectedOption: OptionType | undefined = options.find(
    (option: OptionType) => option.value === value
  );

  return (
    <Downshift
      onChange={newSelectedItem => onChange(newSelectedItem ? newSelectedItem.value : '')}
      itemToString={item => (item ? item.label : '')}
      selectedItem={selectedOption}
    >
      {({
        getInputProps,
        getMenuProps,
        getRootProps,
        getItemProps,
        getToggleButtonProps,
        isOpen,
        highlightedIndex,
      }) => (
        <label className="dropdown" {...getRootProps(undefined, {suppressRefError: true})}>
          <div className="text">{label}</div>
          <div className="input-container">
            <input className="input" {...getInputProps()} />
            <button className="dropdown-button" {...getToggleButtonProps()}>
              {isOpen ? (
                <FontAwesomeIcon icon={['fas', 'chevron-up']} />
              ) : (
                <FontAwesomeIcon icon={['fas', 'chevron-down']} />
              )}
            </button>
            <ul className="menu" {...getMenuProps()}>
              {isOpen &&
                options.map((item, index) => (
                  <li
                    {...getItemProps({
                      style: {
                        backgroundColor: index === highlightedIndex ? 'lightgray' : '',
                      },
                      item,
                      index,
                      key: item.value,
                      className: 'item',
                    })}
                  >
                    {item.label ? item.label : item.value}
                  </li>
                ))}
            </ul>
          </div>
          {errorMessage ? <div className="error">{errorMessage}</div> : ''}
        </label>
      )}
    </Downshift>
  );
};

export default Dropdown;
