import React, { ChangeEvent, FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Button, TableCell } from '@material-ui/core/'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { sendUpload } from '../../../../store/Brands/Actions'
import { IAppState } from '../../../../App/IAppState'
import { IProps } from './IProps'
import { styles } from './style'

export const CollapsibleTableUploadButton: FunctionComponent<IProps> = ({
  brandInfo,
  setSelectedRowID,
}) => {
  const classes = styles()

  const [fileSelected, setFileSelected] = React.useState<File>() // also tried <string | Blob>

  const dispatch = useDispatch<ThunkDispatch<IAppState, undefined, AnyAction>>()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFileSelected(e.target.files[0])
    }
  }

  const uploadOnClick = () => {
    setSelectedRowID(brandInfo.id)
    dispatch(sendUpload(fileSelected))
  }

  return (
    <TableCell>
      <input
        className={classes.input}
        onChange={e => handleChange(e)}
        accept=".xls,.xlsx,.csv"
        type="file"
      />
      {fileSelected ? (
        <label className={classes.label} htmlFor="contained-button-file">
          <Button
            className={classes.button}
            onClick={() => uploadOnClick()}
            variant="contained"
            color="primary"
          >
            <FontAwesomeIcon icon={['fas', 'upload']} className={classes.icon} />
          </Button>
        </label>
      ) : null}
    </TableCell>
  )
}
