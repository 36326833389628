import React, {useEffect, useState} from 'react';
import {Bar, Doughnut, Line} from 'react-chartjs-2';
import PayingCustomerCard from '../PayingCustomerCard/PayingCustomerCard';
import './Dashboard.scss';
import Card from '../../App/Components/Card/Card';
import NewCustomersCard from '../NewCustomersCard/NewCustomersCard';
import SupportCardEsthios from '../SupportCardEsthios/SupportCardEsthios';
import SupportCardOptios from '../SupportCardOptios/SupportCardOptios';
import CustomerGrowthCard from '../CustomerGrowthCard/CustomerGrowthCard';
import Masonry from 'react-masonry-css';
import CustomerPercentageCard from '../CustomerPercentageCard/CustomerPercentageCard';

const Dashboard: React.FunctionComponent = props => {
  const [numberOfCols, setNumberOfCols] = useState<number>();

  useEffect(() => {
    calCols();
  }, [numberOfCols]);

  const barData = () => ({
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: '# of Votes',
        backgroundColor: ['#B21F00', '#00A6B4', '#C9DE00', '#2FDE00', '#6800B4', '#FFB82B'],
        data: [12, 19, 3, 5, 2, 3],
        borderWidth: 1,
      },
    ],
  });
  const calCols = () => {
    if (window.innerWidth <= 786) {
      return setNumberOfCols(1);
    }
    if (window.innerWidth > 786 && window.innerWidth <= 1150) {
      return setNumberOfCols(2);
    }
    if (window.innerWidth > 1150 && window.innerWidth <= 1485) {
      return setNumberOfCols(3);
    }
    if (window.innerWidth > 1485 && window.innerWidth <= 1900) {
      return setNumberOfCols(4);
    }
    return setNumberOfCols(5);
  };
  window.onresize = calCols;

  return (
    <div className="background-div">
      <div className="dashboard-container">
        <br />
        <Masonry
          breakpointCols={numberOfCols}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          <PayingCustomerCard />
          <CustomerPercentageCard />
          <NewCustomersCard />
          <CustomerGrowthCard />
          <SupportCardEsthios />
          <SupportCardOptios />
          <Card>
            <Doughnut data={barData} />
          </Card>
          <Card>
            <Bar data={barData} />
          </Card>
          <Card>
            <Line data={barData} />
          </Card>
        </Masonry>
      </div>
    </div>
  );
};

export default Dashboard;
