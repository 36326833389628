import {AnyAction, Reducer} from "redux";
import {IModule, IModuleState} from "./IModule";

const ModuleReducer: Reducer<IModuleState> = (
    state: IModuleState = { list: undefined },
    action: AnyAction
): IModuleState => {
    switch (action.type) {
        case 'GET_MODULES_SUCCESS':
            return { ...state, list: action.payload.list }
        case 'GET_MODULES_FAILURE':
            return { ...state, list: [] }
        case 'CREATE_MODULE_SUCCESS':
            return { ...state, list: [ ...(state.list || []), action.payload.module ] }
        case 'UPDATE_MODULE_SUCCESS':
            const moduleIndex = state.list?.findIndex((module: IModule) => module.uuid === action.payload.module.uuid);

            if (typeof moduleIndex === 'undefined' || moduleIndex === -1 || !state.list) {
                return state;
            }

            return {
                ...state,
                list: [...state.list.slice(0, moduleIndex), action.payload.module, ...state.list.slice(moduleIndex + 1)],
            };
        case 'SORT_MODULES':
            return {
                ...state,
                list: state.list?.map(
                    (module) => ({
                        ...module,
                        order: action.payload.module_uuids.findIndex((uuid: string) => uuid === module.uuid)
                    })
                ).sort((modA, modB) => modA.order - modB.order)
            }
        default:
            return state
    }
}

export default ModuleReducer
