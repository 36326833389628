import {useState, useEffect} from 'react';
import {AxiosResponse} from 'axios';
import {sendOrganisationInvoiceInfo} from '../AddAccount/ApiClient';
import {validateIban, validateVat} from './InvoiceClient';
import {IOrganisation} from '../Store/IOrganisation';

const useInvoiceInfoForm = (
  accessToken: string,
  organisation: IOrganisation
): [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  any,
  any,
  any,
  string,
  any,
  any
] => {
  const [ibanNumber, setIbanNumber] = useState('');
  const [ibanNumberError, setIbanNumberError] = useState('');
  const [vatNumber, setVatNumber] = useState('');
  const [vatNumberError, setVatNumberError] = useState('');
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [registrationNumberError, setRegistrationNumberError] = useState('');
  const [addressId, setAddressId] = useState<string>('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');

  const [submitAttempted, setSubmitAttempted] = useState<boolean>(false);
  const [status, setStatus] = useState('');

  useEffect(() => {
    if (submitAttempted) {
      validate();
    }
  }, [ibanNumber, vatNumber, registrationNumber]);

  const validate = () => {
    let isValid = true;

    if (!ibanNumber) {
      setIbanNumberError('iban is not found');
      isValid = false;
    } else {
      setIbanNumberError('');
    }
    if (!vatNumber) {
      setVatNumberError('cannot validate vat number');
      isValid = false;
    } else {
      setVatNumberError('');
    }
    if (!registrationNumber) {
      setRegistrationNumberError('registration number is required');
      isValid = false;
    } else {
      setRegistrationNumberError('');
    }
    if (!email) {
      setEmailError('email is required');
      isValid = false;
    } else {
      setEmailError('');
    }
    if (!name) {
      setNameError('name is required');
      isValid = false;
    } else {
      setNameError('');
    }
    return isValid;
  };

  const setvalue = (key: string, value: string): void => {
    switch (key) {
      case 'ibanNumber':
        setIbanNumber(value);
        break;
      case 'vatNumber':
        setVatNumber(value);
        break;
      case 'registrationNumber':
        setRegistrationNumber(value);
        break;
      case 'addressId':
        setAddressId(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'name':
        setName(value);
        break;
    }
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    setSubmitAttempted(true);
    if (!validate()) {
      return;
    }

    setStatus('submitting');
    validateVat(vatNumber).then((vatResponse: any) => {
      if (!vatResponse) {
        setIbanNumberError('VAT number is not valid');
        setStatus('failed');
      }
    });
    validateIban(ibanNumber).then((response: AxiosResponse) => {
      if (response) {
        sendOrganisationInvoiceInfo(
          accessToken,
          organisation.id,
          addressId,
          ibanNumber,
          vatNumber,
          registrationNumber,
          email,
          name
        ).then(
          () => {
            setStatus('success');
          },
          () => {
            setStatus('failed');
          }
        );
      } else {
        setIbanNumberError('Iban number is not valid');
        setStatus('failed');
      }
    });
  };

  const reset = () => {
    setAddressId('');
    setIbanNumber('');
    setRegistrationNumber('');
    setName('');
    setVatNumber('');
    setStatus('');
    setStatus('default');
    setSubmitAttempted(false);
  };
  const tryAgain = () => {
    setStatus('default');
    setSubmitAttempted(false);
  };

  return [
    ibanNumber,
    ibanNumberError,
    vatNumber,
    vatNumberError,
    registrationNumber,
    registrationNumberError,
    email,
    emailError,
    name,
    nameError,
    addressId,
    setvalue,
    onSubmit,
    status,
    reset,
    tryAgain,
  ];
};

export default useInvoiceInfoForm;
