import React, {useEffect} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import {IAppState} from '../../../App/IAppState';
import {IOrganisation} from '../../Store/IOrganisation';
import Card from '../../../App/Components/Card/Card';
import CardHeader from '../../../App/Components/Card/CardHeader/CardHeader';
import {getOrganisationInvoiceInfo} from '../InvoiceInfoStore/Actions';
import LoadingDots from '../../../App/LoadingDots/LoadingDots';
import CardRow from '../../../App/Components/Card/CardRow/CardRow';
import CardDescription from '../../../App/Components/Card/Description/Description';
import CardFooter from '../../../App/Components/Card/CardFooter/CardFooter';
import {IInvoiceInfo} from '../InvoiceInfoStore/IInvoiceInfo';

interface IDispatchProps {
  getOrganisationInvoiceInfo: (organisationId: string) => void;
}

interface IStateProps {
  invoiceInfo: any;
  invoiceInfoLoading: boolean;
}

interface IComponentProps {
  organisation: IOrganisation;
  loading: boolean;
}

type IProps = IStateProps & IDispatchProps & IComponentProps;

const InvoiceInfoCard: React.FunctionComponent<IProps> = (props: IProps) => {
  const {t} = useTranslation();
  useEffect(() => {
    if (!props.invoiceInfoLoading && props.invoiceInfo.length === 0) {
      props.getOrganisationInvoiceInfo(props.organisation.id);
    }
  }, [props.organisation]);

  const invoiceActions = (
    <Link to={`${props.organisation.id}/invoiceinformation`}>
      <div className="contact-card-link">
        {props.invoiceInfo.length - 1 > 0 ? `+ ${props.invoiceInfo.length - 1} more ` : ''}
        <FontAwesomeIcon className="card-arrow" icon={['fas', 'arrow-right']} />
      </div>
    </Link>
  );

  const invoiceCard = (
    <Card>
      <CardHeader
        text={t('Invoice information')}
        picture={<FontAwesomeIcon className="general-icon" icon={['fas', 'money-bill']} />}
      />
      {props.invoiceInfo.slice(0, 1).map((invoiceInfo: any) => (
        <div key={invoiceInfo.id}>
          <CardRow>
            <CardDescription text={invoiceInfo.name ? invoiceInfo.name : t('no name found')} />
          </CardRow>
          <CardRow>
            <CardDescription
              text={
                invoiceInfo.iban ? (
                  <div>
                    <FontAwesomeIcon fixedWidth icon={['fas', 'piggy-bank']} />
                    &nbsp;
                    {invoiceInfo.iban}
                  </div>
                ) : (
                  t('no iban found')
                )
              }
            />
          </CardRow>
          <CardRow>
            <CardDescription
              text={
                invoiceInfo.vat_number ? (
                  <div>
                    <FontAwesomeIcon fixedWidth icon={['fas', 'percent']} />
                    &nbsp;
                    {invoiceInfo.vat_number}
                  </div>
                ) : (
                  t('no vat found')
                )
              }
            />
          </CardRow>
          <CardRow>
            <CardDescription
              text={
                invoiceInfo.registration_number ? (
                  <div>
                    <FontAwesomeIcon fixedWidth icon={['fas', 'university']} />
                    &nbsp;
                    {invoiceInfo.registration_number}
                  </div>
                ) : (
                  t('no registration number found')
                )
              }
            />
          </CardRow>
        </div>
      ))}
      <CardFooter actions={invoiceActions} />
    </Card>
  );

  const loadingCard = (nothingFoundText?: string) => (
    <Card>
      <CardHeader
        text={t('Invoice information')}
        picture={<FontAwesomeIcon className="general-icon" icon={['fas', 'money-bill']} />}
      />
      {nothingFoundText ? <CardDescription text={nothingFoundText} /> : <LoadingDots />}
    </Card>
  );
  return (
    <div>
      {props.invoiceInfoLoading
        ? loadingCard()
        : props.invoiceInfo.length === 0
        ? loadingCard(t('no invoice information found'))
        : invoiceCard}
    </div>
  );
};

const mapStateToProps = (state: IAppState, componentProps: IComponentProps): IStateProps => ({
  invoiceInfo: state.organisationInvoiceInfo.invoiceInfo.filter(
    (element: IInvoiceInfo) => element.organisationId === componentProps.organisation.id
  ),
  invoiceInfoLoading: state.organisationInvoiceInfo.loading,
});

const mapDispatchToProps = (dispatch: any): IDispatchProps =>
  bindActionCreators(
    {
      getOrganisationInvoiceInfo: (organisationId: string) =>
        dispatch(getOrganisationInvoiceInfo(organisationId)),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceInfoCard);
