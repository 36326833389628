import {IContact, IApiContact} from './IContact';

export default class ContactTransformer {
  static transform(apiContact: IApiContact): IContact {
    return {
      id: apiContact.id,
      organisationId: apiContact.organisation_id,
      name: apiContact.first_name,
      lastName: apiContact.last_name,
      email: apiContact.email_address,
      phone: apiContact.phone,
      addressId: apiContact.address_id,
    };
  }

  static reverseTransform(contact: IContact): IApiContact {
    return {
      id: contact.id ? contact.id : undefined,
      organisation_id: contact.organisationId ? contact.organisationId : undefined,
      first_name: contact.name,
      last_name: contact.lastName,
      email_address: contact.email,
      phone: contact.phone,
      address_id: contact.addressId,
    };
  }
}
