import { Dispatch } from 'react'
import { fetchProducts } from './client/Client'
import { IProduct } from './interface/IProduct'

export const GET_PRODUCTS_LOADING = 'GET_PRODUCTS_LOADING'
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR'
export const RESET_PRODUCTS = 'RESET_PRODUCTS'

const brandProductsLoading = () => ({
  type: GET_PRODUCTS_LOADING,
})

const brandProductsSuccess = (productList: IProduct[]) => ({
  type: GET_PRODUCTS_SUCCESS,
  payload: productList,
})

const brandProductsError = (error: string) => ({
  type: GET_PRODUCTS_ERROR,
  payload: error,
})

export const resetBrandProducts = () => ({
  type: RESET_PRODUCTS,
})

export const getBrandProducts = (brandId: string, dispatch: Dispatch<any>) => async () => {
  dispatch(brandProductsLoading())
  const response = await fetchProducts(brandId).catch(error => error.response.data)
  if (response.status === 500) {
    console.log(response)
    return dispatch(brandProductsError(response.detail))
  }
  console.log(response)
  return dispatch(brandProductsSuccess(response))
}
