import React, {useState} from 'react';
import './Tab.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import {ITabItem} from './ITabItem';

interface IProps {
  list: ITabItem[];
  onChange?: (value: string) => void;
}

const Tab: React.FunctionComponent<IProps> = (props: IProps) => {
  const [tabOpen, setTabOpen] = useState<boolean>(false);

  const handleOnMouseClick = () => {
    setTabOpen(!tabOpen);
  };

  return (
    <div className={tabOpen ? 'tab open' : 'tab closed'} onClick={handleOnMouseClick}>
      <div>
        <FontAwesomeIcon icon={tabOpen ? ['fas', 'arrow-right'] : ['fas', 'arrow-left']} />
        <div className="container">
          {props.list.map((tabItem: ITabItem, i: number) => (
            <div className="tab-item" key={i}>
              <Link to={tabItem.link}>
                <div className="tab-label">{tabItem.label}</div>
                <span className="tab-item-icon">
                  <FontAwesomeIcon icon={['fas', tabItem.icon]} />
                </span>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Tab;
