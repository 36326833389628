import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Tab from '../../App/Components/Tab/Tab';
import {IOrganisation} from '../Store/IOrganisation';
import {ITabItem} from '../../App/Components/Tab/ITabItem';
import EstablishmentCards from './EstablishmentCards/EstablishmentCards';
import ContactsCard from './ContactsCard/ContactsCard';
import AddressesCard from './AddressesCard/AddressesCard';
import InvoiceInfoCard from './InvoiceInfoCard/InvoiceInfoCard';
import EditableContent from '../../App/Components/EditableContent/EditableContent';
import {changeOrganisationName} from '../Store/Actions';
import './Dashboard.scss';

interface IProps {
  organisation: IOrganisation;
  loading: boolean;
  changeOrganisationName: (organisationId: string, name: string) => void;
}

const Dashboard: React.FunctionComponent<IProps> = props => {
  const {t} = useTranslation();
  const [organisationName, setOrganisationName] = useState(
    props.organisation ? props.organisation.name : ''
  );

  useEffect(() => {
    setOrganisationName(props.organisation ? props.organisation.name : '');
  }, [props.organisation]);

  const dashBoardTabList: ITabItem[] = [
    {
      icon: 'home',
      label: 'Add establishment',
      link: `/accounts/${props.organisation.id}/add-establishment`,
    },
    {
      icon: 'id-badge',
      label: 'Add contact',
      link: `/accounts/${props.organisation.id}/add-contact`,
    },
    {
      icon: 'map-marked-alt',
      label: 'Add address',
      link: `/accounts/${props.organisation.id}/add-address`,
    },
    {
      icon: 'money-bill',
      label: 'Add invoice information',
      link: `/accounts/${props.organisation.id}/add-invoice-info`,
    },
  ];

  const handleOnBlur = (event: string) => {
    if (event.toString() !== props.organisation.name) {
      props.changeOrganisationName(props.organisation.id, event.toString());
    }
  };

  return (
    <>
      <div className="dashboard-title">
        <h1>
          {t('Dashboard')} <br />
          <EditableContent onBlur={handleOnBlur} text={organisationName} />
        </h1>
      </div>
      <div className="cards">
        <div className="general">
          <EstablishmentCards
            organisation={props.organisation}
            loading={props.loading}
            selectable={false}
          />
          <ContactsCard organisation={props.organisation} loading={props.loading} />
          <AddressesCard organisation={props.organisation} loading={props.loading} />
          <InvoiceInfoCard organisation={props.organisation} loading={props.loading} />
        </div>
      </div>
      {props.organisation ? <Tab list={dashBoardTabList} /> : ''}
    </>
  );
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      changeOrganisationName: (organisationId: string, name: string) =>
        dispatch(changeOrganisationName(organisationId, name)),
    },
    dispatch
  );

export default connect(undefined, mapDispatchToProps)(Dashboard);
