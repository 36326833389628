import axios from 'axios'
import { IApiPartner, IPartner } from '../interface/IPartner'
import { partnerTransformer } from '../partnerTransformer'

export const fetchPartners = async () => {
  const axiosResponse = await axios.get<IApiPartner[]>(
    `${process.env.CLIENT_API}/users/partners`
  )
  return axiosResponse.data.map((apiProduct: IApiPartner) => partnerTransformer(apiProduct))
}

export const createPartner = async (newPartner: Partial<IPartner> & {password: string}): Promise<IPartner> => {
  const axiosResponse = await axios.post<{ uuid: string }>(
    `${process.env.CLIENT_API}/users/partners`,
    {
      first_name: newPartner.firstName,
      last_name: newPartner.lastName,
      email: newPartner.email,
      language: newPartner.language,
      pipedrive_id: newPartner.pipedriveId,
      password: newPartner.password
    }
  )

  return { ...newPartner, uuid: axiosResponse.data.uuid } as IPartner
}

export const updatePartner = async (newPartner: IPartner): Promise<IPartner> => {
  await axios.post(
    `${process.env.CLIENT_API}/users/partners/${newPartner.uuid}`,
    {
      first_name: newPartner.firstName,
      last_name: newPartner.lastName,
      email: newPartner.email,
      language: newPartner.language,
      pipedrive_id: newPartner.pipedriveId,
    }
  )

  return newPartner
}

export const deletePartner = async (partnerUuid: string): Promise<void> => {
  await axios.delete(
    `${process.env.CLIENT_API}/users/partners/${partnerUuid}`
  )
}

export const updatePassword = async (partnerUuid: string, password: string): Promise<void> => {
  await axios.post(
    `${process.env.CLIENT_API}/users/partners/${partnerUuid}/password`,
    {
      password
    }
  )
}