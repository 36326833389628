import {IAddress, IApiAddress} from './IAddress';

export default class AddressTransformer {
  static transform(apiAddress: IApiAddress): IAddress {
    return {
      id: apiAddress.id,
      organisationId: apiAddress.organisation_id,
      street: apiAddress.street,
      houseNumber: apiAddress.house_number,
      houseNumberAddition: apiAddress.house_number_addition,
      postalCode: apiAddress.postal_code,
      city: apiAddress.city,
      countryCode: apiAddress.country_code,
    };
  }

  static reverseTransform(address: IAddress): IApiAddress {
    return {
      id: address.id ? address.id : undefined,
      organisation_id: address.organisationId,
      street: address.street,
      house_number: address.houseNumber,
      house_number_addition: address.houseNumberAddition,
      postal_code: address.postalCode,
      city: address.city,
      country_code: address.countryCode,
    };
  }
}
