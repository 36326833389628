import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {useTranslation} from 'react-i18next';
import {IOrganisation} from '../Store/IOrganisation';
import {IEstablishment} from '../Dashboard/EstablishmentStore/IEstablishment';
import {IAppState} from '../../App/IAppState';
import {
  getOrganisationEstablishments,
  connectInvoiceInfoToEstablishment,
  disconnectInvoiceInfoToEstablishment,
} from '../Dashboard/EstablishmentStore/Actions';
import {getOrganisationInvoiceInfo} from '../Dashboard/InvoiceInfoStore/Actions';
import EstablishmentCards from '../Dashboard/EstablishmentCards/EstablishmentCards';

interface IDispatchProps {
  getOrganisationInvoiceInfo: (organisationId: string) => void;
  disConnectInvoiceInfoToEstablishment: (
    organisationId: string,
    invoiceInfoId: string,
    establishmentId: string
  ) => void;
  connectInvoiceInfoToEstablishment: (
    organisationId: string,
    invoiceInfoId: string,
    establishmentId: string
  ) => void;
}
interface IStateProps {
  establishments: IEstablishment[];
  establishmentsLoading: boolean;
  invoiceInfo: any;
  invoiceInfoLoading: boolean;
}
interface IComponentProps {
  organisation: IOrganisation;
  loading: boolean;
}

type IProps = IStateProps & IDispatchProps & IComponentProps;

const LinkInvoiceInfo: React.FunctionComponent<IProps> = props => {
  const invoiceInfoId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  const foundInvoiceInfo = props.invoiceInfo.find(
    (oneInvoice: any) => oneInvoice.id === invoiceInfoId
  );
  const selectedEstablishments: string[] = [];

  props.establishments.map((establishment: IEstablishment) => {
    if (establishment.invoiceInformationId !== '') {
      selectedEstablishments.push(establishment.id);
    }
  });

  const [t] = useTranslation();

  useEffect(() => {
    if (!props.invoiceInfoLoading && props.invoiceInfo.length === 0) {
      props.getOrganisationInvoiceInfo(props.organisation.id);
    }
  }, [location.pathname]);
  const handleOnChange = (isSelected: boolean, establishmentId: string) => {
    if (
      selectedEstablishments.find((element: string) => element === establishmentId) &&
      !isSelected
    ) {
      props.disConnectInvoiceInfoToEstablishment(
        props.organisation.id,
        invoiceInfoId,
        establishmentId
      );
      const index = selectedEstablishments.indexOf(establishmentId);
      selectedEstablishments.splice(index, 1);
    } else {
      props.connectInvoiceInfoToEstablishment(
        props.organisation.id,
        invoiceInfoId,
        establishmentId
      );
      selectedEstablishments.push(establishmentId);
      console.log(selectedEstablishments);
    }
  };

  return (
    <div className="cards">
      <h1 className="link-contact-title">
        {t('Connect ')}
        {foundInvoiceInfo ? foundInvoiceInfo.name : ''}
        {t(' to following establishment')}
      </h1>
      <EstablishmentCards
        selectedEstablishmentIds={['temp']}
        organisation={props.organisation}
        loading={props.loading}
        selectable
        onChanged={(isSelected: boolean, establishmentId: any) => {
          handleOnChange(isSelected, establishmentId);
        }}
      />
    </div>
  );
};

const mapStateToProps = (state: IAppState, componentProps: IComponentProps): IStateProps => ({
  invoiceInfo: state.organisationInvoiceInfo.invoiceInfo,
  invoiceInfoLoading: state.organisationInvoiceInfo.loading,
  establishments: state.organisationEstablishments.establishments.filter(
    (element: IEstablishment) => element.organisationId === componentProps.organisation.id
  ),
  establishmentsLoading: state.organisationEstablishments.loading,
});

const mapDispatchToProps = (dispatch: any): IDispatchProps =>
  bindActionCreators(
    {
      getOrganisationInvoiceInfo: (organisationId: string) =>
        dispatch(getOrganisationInvoiceInfo(organisationId)),
      getOrganisationEstablishments: (organisationId: string) =>
        dispatch(getOrganisationEstablishments(organisationId)),

      connectInvoiceInfoToEstablishment: (
        organisationId: string,
        invoiceInfoId: string,
        establishmentId: string
      ) =>
        dispatch(connectInvoiceInfoToEstablishment(organisationId, invoiceInfoId, establishmentId)),

      disConnectInvoiceInfoToEstablishment: (
        organisationId: string,
        invoiceInfoId: string,
        establishmentId: string
      ) =>
        dispatch(
          disconnectInvoiceInfoToEstablishment(organisationId, invoiceInfoId, establishmentId)
        ),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LinkInvoiceInfo);
