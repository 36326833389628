import axios from 'axios';

export const inviteTrialAccount = (fullName: string, company: string, email: string, countryCode: string, language: string) => {
  const requestOptions: object = {
    method: 'POST',
    url: `${process.env.CLIENT_API}/anonymous/account/signup`,
    data: {
      full_name: fullName,
      company_name: company,
      email,
      newsletters: false,
      country_code: countryCode,
      language,
      provision_trial_environment: true
    },
  };

  return axios(requestOptions);
};
