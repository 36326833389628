import {
  fetchBrands,
  fetchRequestUpdate,
  fetchFileUpload,
  fetchImportReference,
  fetchStatusProgress,
  postCreateBrandName,
  postUpdateBrandName,
} from './client/Client'
import { IBrand } from './interface/IBrand'
import { Dispatch } from 'react'

export const CREATE_BRAND_LOADING = 'CREATE_BRAND_LOADING'
export const CREATE_BRAND_SUCCESS = 'CREATE_BRAND_SUCCESS'

export const UPDATE_BRAND_LOADING = 'UPDATE_BRAND_LOADING'
export const UPDATE_BRAND_SUCCESS = 'UPDATE_BRAND_SUCCESS'

export const GET_BRANDS_SUCCESS = 'GET_BRANDS_SUCCESS'
export const REQUEST_UPDATE_SUCCESS = 'REQUEST_UPDATE_SUCCESS'
export const SEND_UPLOAD_SUCCESS = 'SEND_UPLOAD_SUCCESS'
export const SEND_SELECTED_SUCCESS = 'SEND_SELECTED_SUCCESS'
export const FETCH_STATUS_SUCCESS = 'FETCH_STATUS_SUCCESS'

const createBrandLoading = () => ({
  type: CREATE_BRAND_LOADING,
})

const createBrandSuccess = (brandName: string, brandId: string) => ({
  type: CREATE_BRAND_SUCCESS,
  payload: { brandName: brandName, brandId: brandId },
})

export const createBrand = (newBrandName: string) => async (dispatch: Dispatch<any>) => {
  dispatch(createBrandLoading())
  const response = await postCreateBrandName(newBrandName)
  dispatch(createBrandSuccess(newBrandName, response.data.id))
}

const updateBrandLoading = () => ({
  type: UPDATE_BRAND_LOADING,
})

const updateBrandSuccess = (brandName: string, brandId: string) => ({
  type: UPDATE_BRAND_SUCCESS,
  payload: { brandName: brandName, brandId: brandId },
})

export const updateBrand =
  (newBrandName: string, brandId: string) => async (dispatch: Dispatch<any>) => {
    dispatch(updateBrandLoading())
    const response = await postUpdateBrandName(newBrandName, brandId)
    console.log(response.data)
    dispatch(updateBrandSuccess(newBrandName, brandId))
  }

export const getBrandsSuccess = (brands: IBrand[]) => ({
  type: GET_BRANDS_SUCCESS,
  payload: brands,
})

export const getBrands = () => async (dispatch: Dispatch<any>) => {
  const brands = await fetchBrands()
  dispatch(getBrandsSuccess(brands))

  return brands
}

export const requestUpdateSuccess = (brand: IBrand) => ({
  type: REQUEST_UPDATE_SUCCESS,
  payload: brand,
})

export const requestUpdate = (brand: IBrand) => async (dispatch: any) => {
  // No result for the moment
  await fetchRequestUpdate(brand)
  dispatch(requestUpdateSuccess(brand))

  return null
}

export const sendUploadSuccess = (columnMapping: any) => ({
  type: SEND_UPLOAD_SUCCESS,
  payload: columnMapping,
})

export const sendUpload = (file: any) => async (dispatch: any) => {
  const response = await fetchFileUpload(file)
  dispatch(sendUploadSuccess(response.data))
}

export const sendSelectedSuccess = (fileSelected: any) => ({
  type: SEND_SELECTED_SUCCESS,
  payload: fileSelected,
})

export const sendSelected =
  (values: any, retrievedFileUuId: any, rowId: any) => async (dispatch: any) => {
    const response = await fetchImportReference(values, retrievedFileUuId, rowId)
    dispatch(sendSelectedSuccess(response.data))
  }

export const fetchStatusSuccess = (statusProgress: any) => ({
  type: FETCH_STATUS_SUCCESS,
  payload: statusProgress,
})

export const fetchStatus = (fileSelected: any) => async (dispatch: any) => {
  try {
    const response = await fetchStatusProgress(fileSelected)

    dispatch(fetchStatusSuccess(response.data))
  } catch (error) {
    console.error(error)
    dispatch(fetchStatusSuccess({ status: 'processing', processed: 0, total: 0 }))
  }
}
