import {Dispatch} from 'redux';
import EstablishmentClient from './EstablishmentClient';
import {IEstablishment, IApiEstablishment} from './IEstablishment';
import EstablishmentTransformer from './Transformer';

export const GET_ORGANISATION_ESTABLISHMENTS = 'GET_ORGANISATION_ESTABLISHMENTS';
export const ORGANISATION_ESTABLISHMENTS_LOADING = 'ORGANISATION_ESTABLISHMENTS_LOADING';
export const CONNECT_CONTACT_ESTABLISHMENT_SUCCESS = 'CONNECT_CONTACT_ESTABLISHMENT_SUCCESS';
export const DISCONNECT_CONTACT_ESTABLISHMENT = 'DISCONNECT_CONTACT_ESTABLISHMENT';
export const CONNECT_INVOICEINFO_ESTABLISHMENT_SUCCESS =
  'CONNECT_INVOICEINFO_ESTABLISHMENT_SUCCESS';
export const DISCONNECT_INVOICEINFO_ESTABLISHMENT = 'DISCONNECT_INVOICEINFO_ESTABLISHMENT';

export function getOrganisationEstablishments(organisationId: string) {
  return (dispatch: Dispatch<any>) => () => {
    dispatch(establishmentsLoading());
    EstablishmentClient.getEstablishments(organisationId).then(response => {
      const transformedEstablishments: IEstablishment[] = response.data.data.map(
        (apiEstablishment: IApiEstablishment): IEstablishment => {
          const transformedData = EstablishmentTransformer.transform(
            apiEstablishment,
            organisationId
          );
          transformedData.contactPersonsIds = ['1d116830-f0ff-3c07-b54c-2826877d834d']; // TODO //
          return transformedData;
        }
      );
      dispatch(organisationEstablishments(transformedEstablishments));
    });
  };
}

export function connectContactToEstablishment(
  organisationId: string,
  contactId: string,
  establishmentId: string
) {
  return (dispatch: Dispatch<any>) => () => {
    dispatch(establishmentsLoading());
    EstablishmentClient.connectContactToEstablishment(
      organisationId,
      contactId,
      establishmentId
    ).then(() => {
      dispatch(connectContactToEstablishmentSuccess(contactId, establishmentId));
    });
  };
}
export function disconnectContactToEstablishment(
  organisationId: string,
  contactId: string,
  establishmentId: string
) {
  return (dispatch: Dispatch<any>) => () => {
    dispatch(establishmentsLoading());
    EstablishmentClient.disconnectContactToEstablishment(
      organisationId,
      contactId,
      establishmentId
    ).then(() => {
      dispatch(disconnectContactToEstablishmentSuccess(contactId, establishmentId));
    });
  };
}
export function connectInvoiceInfoToEstablishment(
  organisationId: string,
  invoiceInfoId: string,
  establishmentId: string
) {
  return (dispatch: Dispatch<any>) => () => {
    dispatch(establishmentsLoading());
    EstablishmentClient.connectContactToEstablishment(
      organisationId,
      invoiceInfoId,
      establishmentId
    ).then(() => {
      dispatch(connectInvoiceInfoEstablishmentSuccess(invoiceInfoId, establishmentId));
    });
  };
}
export function disconnectInvoiceInfoToEstablishment(
  organisationId: string,
  invoiceInfoId: string,
  establishmentId: string
) {
  return (dispatch: Dispatch<any>) => () => {
    dispatch(establishmentsLoading());
    EstablishmentClient.disconnectContactToEstablishment(
      organisationId,
      invoiceInfoId,
      establishmentId
    ).then(() => {
      dispatch(disconnectInvoiceInfoToEstablishmentSuccess(invoiceInfoId, establishmentId));
    });
  };
}

function disconnectContactToEstablishmentSuccess(contactId: string, establishmentId: string) {
  return {
    type: DISCONNECT_CONTACT_ESTABLISHMENT,
    contactPersonId: contactId,
    establishmentId,
  };
}

function connectContactToEstablishmentSuccess(contactId: string, establishmentId: string) {
  return {
    type: CONNECT_CONTACT_ESTABLISHMENT_SUCCESS,
    contactPersonId: contactId,
    establishmentId,
  };
}
function disconnectInvoiceInfoToEstablishmentSuccess(
  invoiceInfoId: string,
  establishmentId: string
) {
  return {
    type: DISCONNECT_INVOICEINFO_ESTABLISHMENT,
    invoiceInfoId,
    establishmentId,
  };
}

function connectInvoiceInfoEstablishmentSuccess(invoiceInfoId: string, establishmentId: string) {
  return {
    type: CONNECT_INVOICEINFO_ESTABLISHMENT_SUCCESS,
    invoiceInfoId,
    establishmentId,
  };
}

function establishmentsLoading() {
  return {
    type: ORGANISATION_ESTABLISHMENTS_LOADING,
  };
}

function organisationEstablishments(establishments: IEstablishment[]) {
  return {
    type: GET_ORGANISATION_ESTABLISHMENTS,
    establishments,
  };
}
