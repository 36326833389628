import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Collapse from '@material-ui/core/Collapse'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import Button from '@material-ui/core/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FunctionComponent, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { IBrand } from '../../../../../../store/Brands/interface/IBrand'
import { requestUpdate } from '../../../../../../store/Brands/Actions'
import { IAppState } from '../../../../../../App/IAppState'
import CollapsibleTableSubHeadColumnDefinition from '../../../../../../imports/Table/CollapsibleTableSubHeadColumnDefinition'
import { IProps } from './IProps'

export const SupplierSubTab: FunctionComponent<IProps> = ({ isOpen, brandInfo }) => {
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch<ThunkDispatch<IAppState, undefined, AnyAction>>()
  const updateOnclick = (brand: IBrand) => {
    setLoading(true)
    dispatch(requestUpdate(brand)).then(() => setLoading(false))
  }
  return (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <Box margin={1}>
            <Typography variant="h6" gutterBottom component="div">
              Suppliers
            </Typography>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  {CollapsibleTableSubHeadColumnDefinition.map(column => (
                    <TableCell>{column.name}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {brandInfo.suppliers.map(supplier => (
                  <TableRow key={supplier.id}>
                    <TableCell component="th" scope="row">
                      {supplier.name}
                    </TableCell>
                    <TableCell>{supplier.phone}</TableCell>
                    <TableCell>{supplier.email}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => updateOnclick(brandInfo)}
                        variant="contained"
                        color="primary"
                        disabled={loading}
                      >
                        {loading ? (
                          <FontAwesomeIcon icon={['fas', 'spinner']} pulse />
                        ) : (
                          <FontAwesomeIcon icon={['fas', 'redo-alt']} className="icon" />
                        )}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}
