import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import Dropdown from '../../App/Components/Dropdown/Dropdown';
import TextInput from '../../App/Components/TextInput/TextInput';
import LoadingDots from '../../App/LoadingDots/LoadingDots';
import {countries} from '../../App/Resources/dropDownMenus/countries';
import {IOrganisation} from '../Store/IOrganisation';

import useAddressForm from './useAddressForm';

interface IProps {
  organisation: IOrganisation;
}

const AddAddress: React.FunctionComponent<IProps> = (props: IProps) => {
  const {t} = useTranslation();
  const [street, streetNr, postalCode, city, country, status, reset, setValue, onSubmit] =
    useAddressForm(props.organisation);

  let AddAddressForm: any = <div>{t('something went wrong')}</div>;

  if (status === 'submitting') {
    AddAddressForm = <LoadingDots />;
  } else if (status === 'succes') {
    AddAddressForm = (
      <>
        <div className="status-message success">{t('Address has been added.')}</div>
        <button className="button" onClick={reset}>
          {t('Create another')}
        </button>
      </>
    );
  } else if (status === 'failed') {
    AddAddressForm = (
      <>
        <div className="status-message error">{t('Unable to add address.')}</div>
        <button className="button" onClick={reset}>
          {t('Try again')}
        </button>
      </>
    );
  } else {
    AddAddressForm = (
      <form onSubmit={onSubmit}>
        <TextInput
          label={t('Street')}
          value={String(street)}
          onChange={value => {
            setValue('street', value);
          }}
        />
        <TextInput
          label={t('Street number')}
          value={String(streetNr)}
          onChange={value => {
            setValue('streetNr', value);
          }}
        />
        <TextInput
          label={t('Postal code')}
          value={String(postalCode)}
          onChange={value => {
            setValue('postalCode', value);
          }}
        />
        <TextInput
          label={t('City')}
          value={String(city)}
          onChange={value => {
            setValue('city', value);
          }}
        />
        <Dropdown
          label={t('Country')}
          value={String(country)}
          onChange={(selectedItem: string) => {
            setValue('country', selectedItem);
          }}
          options={countries}
        />
        <button type="submit" className="button">
          {t('Add address')}
        </button>
        <Link className="nav-item button dim" to={`/accounts/${props.organisation.id}`}>
          {t('Cancel')}
        </Link>
      </form>
    );
  }

  return (
    <div className="form-layout">
      {props.organisation ? (
        <div>
          <h2>{t('Add an address for')}: </h2>
          <h1>{props.organisation.name}</h1>
          {AddAddressForm}
        </div>
      ) : (
        <LoadingDots />
      )}
    </div>
  );
};

export default AddAddress;
