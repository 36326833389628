import { makeStyles } from '@material-ui/styles'

export const partnerStyles = makeStyles(theme => ({
  modalBox: {
    display: 'flex',
    backgroundColor: 'white',
    padding: '20px',
    flexDirection: 'column',
    maxWidth: '480px',
    width: '50%',
    minWidth: '300px',
    margin: 'auto',
  }
}))
