import {faChevronDown} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';

interface IProps {
  label: string;
  options: ISelectInputOption[];
  value?: string;
  onChange?: (value: string) => void;
  full?: boolean;
  errorMessage?: string;
}

export interface ISelectInputOption {
  value: string | number;
  label: string;
}

const SelectInput: React.FunctionComponent<IProps> = (props: IProps) => {
  const [value, setValue] = useState(props.value);

  const classes = [];
  if (props.full) classes.push('full');
  if (props.errorMessage) classes.push('error');

  const options = props.options.map((option: ISelectInputOption) => (
    <option key={option.label} value={option.value}>
      {option.label}
    </option>
  ));

  return (
    <label className={classes.join(' ')}>
      <div className="text">{props.label}</div>
      <div className="select">
        <select
          className="input"
          value={value}
          onChange={e => {
            e.preventDefault();
            setValue(e.target.value);
            if (props.onChange) props.onChange(e.target.value);
          }}
        >
          {options}
        </select>
        <FontAwesomeIcon icon={faChevronDown} />
      </div>
    </label>
  );
};

export default SelectInput;
