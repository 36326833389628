import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {IAppState} from 'App/IAppState';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Link} from 'react-router-dom';
import LoadingDots from '../../App/LoadingDots/LoadingDots';
import {IOrganisation} from '../../Accounts/Store/IOrganisation';
import {getOrganisationContacts} from './ContactStore/Actions';
import {getOrganisationAddresses} from '../../Accounts/AddAddress/AddressStore/Actions';
import HtmlList from '../../App/Components/HtmlList/HtmlList';
import {IHtmlListData} from '../../App/Components/HtmlList/IHtmlListData';
import {IHtmlListButton} from '../../App/Components/HtmlList/IHtmlListButton';
import {IContact} from './ContactStore/IContact';

export interface IProps {
  contactLoading: boolean;
  organisation: IOrganisation;
  addresses: any;
  contacts: any;
  getOrganisationContacts: any;
  getOrganisationAddresses: any;
}

const ContactList: React.FunctionComponent<IProps> = props => {
  useEffect(() => {
    if (!props.contactLoading && props.contacts.length === 0) {
      props.getOrganisationAddresses(props.organisation.id);
      props.getOrganisationContacts(props.organisation.id);
    }
  }, [location.pathname]);
  const {t} = useTranslation();

  const contacts: IHtmlListData[] = props.contacts.map((contact: IContact) => {
    const contactAddress = props.addresses.find((address: any) => address.id === contact.addressId);
    const contactListData = [
      `${contact.name} ${contact.lastName ? contact.lastName : ''}`,
      contact.email,
    ];
    if (contact.phone) {
      const parsedPhone =
        contact.phone.slice(0, 2) === '00' ? `+${contact.phone.slice(2)}` : contact.phone;
      contactListData.push(parsedPhone);
    }
    if (contactAddress) {
      contactListData.push(`${contactAddress.street} ${contactAddress.house_number}`);
      contactListData.push(`${contactAddress.postal_code} ${contactAddress.city}`);
    }

    const buttons: IHtmlListButton[] = [
      {
        icon: ['fas', 'pencil-alt'],
        link: `/accounts/${props.organisation.id}/edit-contact/${contact.id}`,
      },
      {
        icon: ['fas', 'building'],
        link: `/accounts/${props.organisation.id}/connect-establishment-contact/${contact.id}`,
      },
    ];

    return {
      content: contactListData,
      buttons,
    };
  });

  return (
    <div className="contacts-list">
      <h1>
        {props.organisation ? props.organisation.name : null} {t('contact list')}
      </h1>
      <div>
        {props.contactLoading ? (
          <LoadingDots />
        ) : contacts ? (
          <HtmlList list={contacts} />
        ) : (
          <LoadingDots />
        )}
      </div>
      <Link to={`/accounts/${props.organisation.id}`} className="button dim">
        {t('Cancel')}
      </Link>
    </div>
  );
};
const mapStateToProps = (state: IAppState) => ({
  addresses: state.organisationAddresses.addresses,
  contacts: state.organisationContacts.contacts,
  contactLoading: state.organisationContacts.loading,
});
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      getOrganisationContacts: (organisationId: string) =>
        dispatch(getOrganisationContacts(organisationId)),
      getOrganisationAddresses: (organisationId: string) =>
        dispatch(getOrganisationAddresses(organisationId)),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ContactList);
