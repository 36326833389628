import IDataProvider from '../IDataProvider';

class ArrayDataProvider implements IDataProvider {
  result: Array<any>;

  rowIdentifier = 'id';

  constructor(data: Array<any>) {
    this.result = data;
  }

  getRowIdentifier(): string {
    return this.rowIdentifier;
  }

  rows(page: number, limit: number, sorting: any): Promise<any> {
    return new Promise(resolve => {
      resolve({
        data: this.result.slice((page - 1) * limit, page * limit),
        count: this.result.length,
      });
    });
  }

  count() {
    return this.result.length;
  }

  update(id: any, dataKey: string, newValue: any): Promise<any> {
    const rowIndex = this.result.findIndex(row => row[this.rowIdentifier] === id);
    this.result[rowIndex][dataKey] = newValue;

    return new Promise(resolve => resolve(this.result[rowIndex]));
  }
}

export default ArrayDataProvider;
