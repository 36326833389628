export default [
  {
    name: 'Name',
    dataRef: '',
  },
  {
    name: 'Phone',
    dataRef: '',
  },
  {
    name: 'Email',
    dataRef: '',
  },
  {
    name: 'Update',
    dataRef: '',
  },
];
