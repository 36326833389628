import IDataProvider from '../../App/Table/IDataProvider';
import TranslationsApiClient from '../TranslationsApiClient';
import ITranslation from './ITranslation';

class TranslationsDataProvider implements IDataProvider {
  rowIdentifier = 'key';

  data: ITranslation[] = [];

  domain = 'messages';

  search = '';

  constructor(domain: string) {
    this.domain = domain;
  }

  setDomain(domain: string) {
    this.domain = domain;
    this.data = [];
  }

  setSearch(search: string) {
    this.search = search;
  }

  rows(page: number, limit: number, sorting: any): Promise<any> {
    if (this.data.length > 0) {
      return Promise.resolve(this.sortAndFilter(page, limit, sorting));
    }

    return TranslationsApiClient.getTranslations(this.domain).then(response => {
      this.data = response.data.map((translation: ITranslation) => ({
        key: translation.key,
        en: translation.en ? translation.en : '',
        nl: translation.nl ? translation.nl : '',
        fr: translation.fr ? translation.fr : '',
        it: translation.it ? translation.it : '',
        nl_NL: translation.nl_NL ? translation.nl_NL : '',
        de: translation.de ? translation.de : '',
        sq: translation.sq ? translation.sq : '',
      }));

      return this.sortAndFilter(page, limit, sorting);
    });
  }

  sortAndFilter(page: number, limit: number, sorting: any) {
    const sortedMappedData: ITranslation[] = this.data
      .filter(
        (translation: ITranslation) => translation.key.search(new RegExp(this.search, 'i')) !== -1
      )
      .sort((n1, n2) => {
        if (typeof sorting !== 'object') {
          return 0;
        }

        for (const sortingKey of Object.keys(sorting)) {
          if (!sorting.hasOwnProperty(sortingKey)) {
            continue;
          }

          if (n1[sortingKey] > n2[sortingKey]) {
            return sorting[sortingKey] === 'ASC' ? 1 : -1;
          }

          if (n1[sortingKey] < n2[sortingKey]) {
            return sorting[sortingKey] === 'ASC' ? -1 : 1;
          }
        }

        return 0;
      });

    return {
      data: sortedMappedData.slice((page - 1) * limit, page * limit),
      count: sortedMappedData.length,
    };
  }

  count() {
    return this.data.filter(
      (translation: ITranslation) => translation.key.search(new RegExp(this.search, 'i')) !== -1
    ).length;
  }

  update(key: any, dataKey: string, newValue: any): Promise<any> {
    const rowIndex = this.data.findIndex(row => row.key === key);
    this.data[rowIndex][dataKey] = newValue;

    return TranslationsApiClient.addTranslationLocale(this.data[rowIndex], dataKey, this.domain);
  }

  getRowIdentifier(): string {
    return this.rowIdentifier;
  }
}

export default TranslationsDataProvider;
