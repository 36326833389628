import React, {FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import ModuleForm from "./ModuleForm";
import Hero from "../App/Components/Hero/Hero";
import {IModule, IModulePrices} from "../store/Module/IModule";
import AppLoadingDots from "../App/Components/AppLoadingDots/AppLoadingDots";
import {updateModule} from "../store/Module/actions";
import {useHistory, useParams} from "react-router-dom";
import {IAppState} from "../App/IAppState";


const Update: FunctionComponent = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [isSubmitting, setIsSubmutting] = useState<boolean>(false)
    const [error, setError] = useState<string | undefined>(undefined)
    const history = useHistory()

    const {uuid} = useParams<{uuid: string}>();
    const module: IModule|undefined = useSelector((state: IAppState) => state.modules.list?.find((oneModule) => oneModule.uuid === uuid))

    const onSubmit = async (name: string, prices: IModulePrices, permissions: string[]) => {
        if (! module) {
            return
        }

        setIsSubmutting(true)
        try {
            await dispatch(updateModule({...module, name, prices, permissions}))
            setError(undefined)
        } catch (Error) {
            setError('An error occurred while updating the module.')
        }
        setIsSubmutting(false)
        history.push('/modules')
    }

    if (isSubmitting) {
        return <AppLoadingDots/>
    }

    if (! module) {
        history.push('/modules')
    }

    return (
        <Hero maxWidth="60em">
            <h1>{t('Update module')}</h1>
            {error ? <p className="status-message error">{t(error)}</p> : null}
            <ModuleForm onSubmit={onSubmit} module={module}/>
        </Hero>
    )
}

export default Update