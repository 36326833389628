import React, {useEffect} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IAppState} from '../../../App/IAppState';
import {IOrganisation} from '../../Store/IOrganisation';
import Card from '../../../App/Components/Card/Card';
import CardRow from '../../../App/Components/Card/CardRow/CardRow';
import CardDescription from '../../../App/Components/Card/Description/Description';
import CardHeader from '../../../App/Components/Card/CardHeader/CardHeader';
import CardFooter from '../../../App/Components/Card/CardFooter/CardFooter';
import LoadingDots from '../../../App/LoadingDots/LoadingDots';
import {getOrganisationContacts} from '../../ContactList/ContactStore/Actions';
import {IContact} from '../../../Accounts/ContactList/ContactStore/IContact';

interface IDispatchProps {
  getOrganisationContacts: (organisationId: string) => void;
}

interface IStateProps {
  contacts: IContact[];
  contactLoading: boolean;
}

interface IComponentProps {
  organisation: IOrganisation;
  loading: boolean;
}

type IProps = IStateProps & IDispatchProps & IComponentProps;

const ContactsCard: React.FunctionComponent<IProps> = (props: IProps) => {
  const {t} = useTranslation();
  useEffect(() => {
    if (!props.contactLoading && props.contacts.length === 0) {
      props.getOrganisationContacts(props.organisation.id);
    }
  }, [props.organisation]);

  const contactActions = (
    <Link to={`${props.organisation.id}/contacts`}>
      <div className="contact-card-link">
        {props.contacts.length - 2 > 0 ? `+ ${props.contacts.length - 2} more ` : ''}
        <FontAwesomeIcon className="card-arrow" icon={['fas', 'arrow-right']} />
      </div>
    </Link>
  );

  const contactsCard = (
    <Card>
      <CardHeader
        text={t('Contacts')}
        picture={<FontAwesomeIcon className="general-icon" icon={['fas', 'id-badge']} />}
      />
      {props.contacts.slice(0, 2).map((contact: IContact) => (
        <div key={contact.id}>
          <CardRow>
            <div className="contact-name">
              <CardDescription
                text={`${contact.name} ${contact.lastName ? contact.lastName : ''}`}
              />
            </div>
          </CardRow>
          <CardRow>
            <div className="contact-card-info">
              <span className="contact-email">
                <FontAwesomeIcon icon={['fas', 'envelope']} />
                <CardDescription text={<a href={`mailto:${contact.email}`}>{contact.email}</a>} />
              </span>
              <span className="contact-number">
                <FontAwesomeIcon icon={['fas', 'phone']} />
                <CardDescription
                  text={
                    <a href={`tel:${contact.phone.replace(/^00/, '+')}`}>
                      {contact.phone.replace(/^00/, '+')}
                    </a>
                  }
                />
              </span>
            </div>
          </CardRow>
        </div>
      ))}
      <CardFooter actions={contactActions} />
    </Card>
  );

  const loadingCard = (nothingFoundText?: string) => (
    <Card>
      <CardHeader
        text={t('Contacts')}
        picture={<FontAwesomeIcon className="general-icon" icon={['fas', 'id-badge']} />}
      />
      {nothingFoundText ? <CardDescription text={nothingFoundText} /> : <LoadingDots />}
    </Card>
  );

  return (
    <div>
      {props.contactLoading
        ? loadingCard()
        : props.contacts.length === 0
        ? loadingCard(t('no contacts found'))
        : contactsCard}
    </div>
  );
};
const mapStateToProps = (state: IAppState, organisationProps: IComponentProps): IStateProps => ({
  contacts: state.organisationContacts.contacts.filter(
    (element: IContact) => element.organisationId === organisationProps.organisation.id
  ),
  contactLoading: state.organisationContacts.loading,
});

const mapDispatchToProps = (dispatch: any): IDispatchProps =>
  bindActionCreators(
    {
      getOrganisationContacts: (organisationId: string) =>
        dispatch(getOrganisationContacts(organisationId)),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ContactsCard);
