import {Reducer} from 'redux';
import {GET_ORGANISATION_CONTACTS, ORGANISATION_CONTACTS_LOADING} from './Actions';
import {IContactsState} from './IContactsState';

const initialState: IContactsState = {
  loading: false,
  error: '',
  contacts: [],
};

export const reducer: Reducer<IContactsState> = (
  state: IContactsState = initialState,
  action: any
): IContactsState => {
  switch (action.type) {
    case ORGANISATION_CONTACTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ORGANISATION_CONTACTS:
      return {
        ...state,
        loading: false,
        contacts: state.contacts.concat(action.contacts),
      };
    default:
      return state;
  }
};
