import React, {FormEvent, FunctionComponent, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link} from 'react-router-dom'

import TextInput from "../App/Components/TextInput/TextInput";
import {IModule, IModulePrices} from "../store/Module/IModule";
import Checkbox from "../App/Components/Checkbox/Checkbox";

interface IProps {
    module?: IModule,
    onSubmit: (name: string, prices: IModulePrices, permissions: string[]) => void
}

interface IErrors {
    [field: string]: string|undefined
}

const ModuleForm: FunctionComponent<IProps> = ({module = undefined, ...props}) => {
    const {t} = useTranslation()
    const [name, setName] = useState<string>(module?.name || '')
    const [errors, setErrors] = useState<IErrors>({})
    const [permissions, setPermissions] = useState<string[]>(module?.permissions || [])
    const [prices, setPrices] = useState<{ [country: string]: number, BE: number }>(module?.prices || { BE: 0})

    const onNameChange = (value: string) => {
        setName(value)
    };

    const onPermissionChange = (permission: string, isChecked: boolean) => {
        if (isChecked) {
            setPermissions([...permissions, permission])
        } else {
            const index = permissions.indexOf(permission);
            if (index > -1) {
                const newPermissions = [...permissions]
                newPermissions.splice(index, 1);
                setPermissions(newPermissions)
            }
        }
    }

    const onPriceChange = (countryCode: string, price: string) => {
        const newPrices = {...prices}
        newPrices[countryCode] = Math.round(Number(price))
        setPrices(newPrices)
    }

    const existingPermissions = [
        'customers',
        'calendar',
        'register',
        'inventory',
        'marketing',
        'punchclock',
        'reports',
        'subscriptions',
        'loyalty_cards',
        'online_payments',
        'online_appointments',
        'website',
        'absences',
        'absence_requests',
        'hr_time',
        'shopify',
        'advanced_settings',
        'surveys',
        'pro_website',
        'workshops'
    ]

    const countryCodes = ['BE', 'NL', 'FR', 'DE', 'IT']

    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (validate()) {
            props.onSubmit(name, prices, permissions)
        }

        return false
    }

    const validate = (): boolean => {
        const errors: IErrors = {}
        let isValid = true
        if (! name) {
            errors.name = 'This field is required'
            isValid = false
        }

        if (! prices.BE && 0 !== prices.BE) {
            errors.prices_BE = 'This field is required'
            isValid = false
        }
        setErrors(errors)

        return isValid
    }

    return (
        <form onSubmit={onSubmit}>
            <div style={{display: 'flex', paddingRight: '1em'}}>
                <div style={{flexGrow: '1'}}>
                    <TextInput
                        label={t('Name')}
                        value={name}
                        onChange={onNameChange}
                        errorMessage={errors.name ? t(errors.name) : undefined}
                        full
                    />
                    {countryCodes.map((countryCode) => (
                        <TextInput
                            label={<><img src={`https://cdn.jsdelivr.net/npm/flag-icon-css@4.1.7/flags/4x3/${countryCode.toLowerCase()}.svg`} width="20"/> {`${t('Price')} ${countryCode}`}</>}
                            value={String(prices[countryCode] || 0)}
                            onChange={(value) => onPriceChange(countryCode, value)}
                            full
                        />
                    ))}
                </div>
                <div style={{flexGrow: '1', paddingLeft: '1em'}}>
                    <label><div className="text">{t('Permissions')}</div></label>
                    {existingPermissions.map((permission) => {
                        return  (
                            <Checkbox
                                label={t(`module_permission.${permission}`)}
                                onChange={(isChecked) => onPermissionChange(permission, isChecked)}
                                checked={permissions.includes(permission)}
                            />
                        )
                    })}
                </div>
            </div>
            <button type="submit" className={'button'}>{t('Save')}</button>
            <Link to={'/modules'} className={'button dim'}>{t('Cancel')}</Link>
        </form>
    )
}

export default ModuleForm
