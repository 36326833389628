import {IFilter} from '../../App/Components/Filter/IFilter';

export function getTranslationFilters(domain: string): Array<IFilter> {
  return [
    {
      key: 'domains',
      required: true,
      multiSelect: false,
      options: [
        {label: 'Messages', value: 'messages', isActive: domain === 'messages'},
        {label: 'Email', value: 'email', isActive: domain === 'email'},
        {label: 'Activities', value: 'activities', isActive: domain === 'activities'},
        {label: 'Mail', value: 'mail', isActive: domain === 'mail'},
        {label: 'Order', value: 'order', isActive: domain === 'order'},
        {label: 'Website', value: 'website', isActive: domain === 'website'},
      ],
      label: 'Domains',
    },
  ];
}
