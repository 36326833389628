import axios from 'axios';

export const validateIban = (ibanNumber: string) => {
  const requestOptions: object = {
    method: 'GET',
    url: `https://openiban.com/validate/${ibanNumber}`,
    headers: {
      authorization: false,
    },
  };

  return axios(requestOptions).then(response => response.data.valid);
};
export const validateVat = (vatNumber: string) => {
  const requestOptions: object = {
    method: 'GET',
    url: `https://cors-anywhere.herokuapp.com/https://controleerbtwnummer.eu/api/validate/${vatNumber}.json`,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return axios(requestOptions).then(response => response.data.valid);
};
