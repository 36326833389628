import axios from 'axios';
import {IContact} from '../ContactList/ContactStore/IContact';
import ContactTransformer from '../ContactList/ContactStore/ContactTransformer';

export const sendOrganisationName = (salonName: string) => {
  const requestOptions: object = {
    method: 'POST',
    url: `${process.env.API_URL}/organisation`,
    data: {
      name: salonName,
    },
  };
  return axios(requestOptions);
};

export const sendOrganisationAddress = (
  organisationId: string,
  street: string,
  streetNr: number,
  postalCode: string,
  city: string,
  country: string
) => {
  const requestOptions: object = {
    method: 'POST',
    url: `${process.env.API_URL}/organisation/${organisationId}/address`,
    data: {
      street,
      house_number: streetNr,
      postal_code: postalCode,
      city,
      country_code: country,
    },
  };

  return axios(requestOptions);
};

export const sendOrganisationContactPerson = (organisationId: string, contact: IContact) => {
  const requestOptions: object = {
    method: 'POST',
    url: `${process.env.API_URL}/organisation/${organisationId}/contact-person`,
    data: ContactTransformer.reverseTransform(contact),
  };

  return axios(requestOptions);
};

export const sendOrganisationInvoiceInfo = (
  accessToken: string,
  organisationId: string,
  addressId: string,
  ibanNumber: string,
  vatNumber: string,
  registrationNumber: string,
  email: string,
  name: string
) => {
  const requestOptions: object = {
    method: 'POST',
    url: `${process.env.API_URL}/organisation/${organisationId}/invoice-information`,
    data: {
      iban: ibanNumber,
      vat_number: vatNumber,
      registration_number: registrationNumber,
      address_id: addressId,
      email,
      name,
    },
  };
  return axios(requestOptions);
};
