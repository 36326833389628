import React, { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { Button, Modal, Backdrop, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import { getBrands } from '../store/Brands/Actions'
import { CollapsibleTable } from '../components/CollapsibleTable/CollapsibleTable'
import LoadingDots from '../App/LoadingDots/LoadingDots'
import { IAppState } from '../App/IAppState'
import { productStyles } from './style'
import { brandCrud } from './utils/brandCrud'
import { IModal } from './interface/IModal'

const Products: FunctionComponent = () => {
  const [openModalType, setOpenModalType] = useState<IModal>({ type: 'idle', id: null })
  const [brandInput, setBrandInput] = useState<string>()
  const [brandInputError, setBrandInputError] = useState<boolean>()

  const classes = productStyles()
  const dispatch = useDispatch<ThunkDispatch<IAppState, any, any>>()
  const { t } = useTranslation()

  const brandList = useSelector((state: IAppState) => state.brands.list)
  const columnMapping = useSelector((state: IAppState) => state.brands.columnMapping)
  const fileSelected = useSelector((state: IAppState) => state.brands.fileSelected)
  const statusProgress = useSelector((state: IAppState) => state.brands.statusProgress)

  const openModal = (action: IModal) => setOpenModalType({ type: action.type, id: action.id })
  const modalClose = () => setOpenModalType({ type: 'idle', id: null })

  const triggerBrandCrud = async () => {
    const crudResponse = await brandCrud(
      dispatch,
      brandInput,
      openModalType.id ? openModalType.id : undefined
    )
    if (crudResponse === 'error') {
      setBrandInputError(true)
      return
    }
    setBrandInput(undefined)
    modalClose()
  }

  useEffect(() => {
    dispatch(getBrands())
  }, [])

  if (!brandList) {
    return <LoadingDots />
  }

  return (
    <>
      <div style={{ padding: '16px' }} className="main-content">
        {/* {error && <p className="error">{error}</p>} */}
        <CollapsibleTable
          brandList={brandList}
          columnMapping={columnMapping}
          fileSelected={fileSelected}
          statusProgress={statusProgress}
          openModal={openModal}
        />
        <Button variant="text" onClick={() => openModal({ type: 'create', id: null })}>
          {t('Add brand')}
        </Button>
      </div>
      <Modal
        open={openModalType.type !== 'idle'}
        BackdropComponent={Backdrop}
        onClose={() => modalClose()}
      >
        <Box className={classes.modalBox}>
          <TextField
            error={brandInputError}
            helperText={brandInputError ? t('This field is required') : null}
            className={classes.inputStyle}
            value={brandInput}
            onChange={e => setBrandInput(e.target.value)}
            placeholder={t('Brand name')}
          />
          <Button type="submit" variant="outlined" onClick={() => triggerBrandCrud()}>
            {openModalType.type === 'create' ? t('Add') : t('Update')}
          </Button>
        </Box>
      </Modal>
    </>
  )
}

export default Products
