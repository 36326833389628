import {useState, useEffect, SyntheticEvent} from 'react';
import {IOrganisation} from '../Store/IOrganisation';
import {AddEstablishment} from './EstablishmentApiClient';

const useEstablishmentForm = (
  organisation: IOrganisation
): [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  any,
  any,
  any,
  any,
  any
] => {
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [label, setLabel] = useState('');
  const [labelError, setLabelError] = useState('');
  const [language, setLanguage] = useState('');
  const [languageError, setLanguageError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [with_sample_data, setWith_sample_data] = useState<any>(false);
  const [with_trial_modules, setWith_trial_modules] = useState(false);

  const [submitAttempted, setSubmitAttempted] = useState<boolean>(false);
  const [status, setStatus] = useState('');

  useEffect(() => {
    if (submitAttempted) {
      validate();
    }
  }, [name, email]);
  const validate = () => {
    let isvalid = true;

    if (!name) {
      setNameError('Name is required');
      isvalid = false;
    } else {
      setNameError('');
    }

    if (!email) {
      setEmailError('Email is required');
      isvalid = false;
    } else {
      setEmailError('');
    }
    if (!label) {
      setLabelError('Label is required');
      isvalid = false;
    } else {
      setLabelError('');
    }
    if (!language) {
      setLanguageError('Language is required');
      isvalid = false;
    } else {
      setLanguageError('');
    }
    return isvalid;
  };
  const setValue = (key: string, value: string): void => {
    switch (key) {
      case 'name':
        setName(value);
        break;
      case 'label':
        setLabel(value);
        break;
      case 'language':
        setLanguage(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'with_sample_data':
        setWith_sample_data(value);
        break;
      case 'with_trial_modules':
        setWith_trial_modules(true);
        break;
    }
  };
  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    setSubmitAttempted(true);
    if (!validate()) {
      return;
    }
    setStatus('submitting');
    AddEstablishment(
      organisation.id,
      name,
      label,
      language,
      email,
      with_sample_data,
      with_trial_modules
    ).then(
      () => {
        setStatus('success');
      },
      () => {
        setStatus('failed');
      }
    );
  };
  const reset = () => {
    setName('');
    setNameError('');
    setEmail('');
    setEmailError('');
    setLabel('');
    setLabelError('');
    setLanguage('');
    setLanguageError('');
    setWith_sample_data(false);
    setWith_trial_modules(false);
    setStatus('default');
    setSubmitAttempted(false);
  };
  const tryAgain = () => {
    setStatus('default');
    setSubmitAttempted(false);
  };
  return [
    name,
    nameError,
    email,
    emailError,
    label,
    labelError,
    language,
    languageError,
    setValue,
    onSubmit,
    status,
    reset,
    tryAgain,
  ];
};
export default useEstablishmentForm;
