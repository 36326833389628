import {Reducer} from 'redux';
import {
  GET_ORGANISATION_ESTABLISHMENTS,
  ORGANISATION_ESTABLISHMENTS_LOADING,
  CONNECT_CONTACT_ESTABLISHMENT_SUCCESS,
  DISCONNECT_CONTACT_ESTABLISHMENT,
  CONNECT_INVOICEINFO_ESTABLISHMENT_SUCCESS,
  DISCONNECT_INVOICEINFO_ESTABLISHMENT,
} from './Actions';
import {IEstablishmentStore} from './IEstablishmentStore';
import {IEstablishment} from './IEstablishment';

const initialState: IEstablishmentStore = {
  loading: false,
  error: '',
  establishments: [],
};

export const reducer: Reducer<IEstablishmentStore> = (
  state: IEstablishmentStore = initialState,
  action: any
): IEstablishmentStore => {
  switch (action.type) {
    case ORGANISATION_ESTABLISHMENTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ORGANISATION_ESTABLISHMENTS:
      return {
        ...state,
        loading: false,
        establishments: state.establishments.concat(action.establishments),
      };
    case CONNECT_CONTACT_ESTABLISHMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        establishments: state.establishments.map(
          (establishment: IEstablishment): IEstablishment => {
            if (establishment.id === action.establishmentId) {
              establishment.contactPersonsIds.concat(action.contactPersonId);
              establishment.contactPersonsIds.filter(
                (value, index, self) => self.indexOf(value) === index
              );
            }

            return establishment;
          }
        ),
      };
    case DISCONNECT_CONTACT_ESTABLISHMENT:
      return {
        ...state,
        loading: false,
        establishments: state.establishments.map(
          (establishment: IEstablishment): IEstablishment => {
            if (establishment.id === action.establishmentId) {
              const index = establishment.contactPersonsIds.indexOf(action.contactPersonId);
              establishment.contactPersonsIds.splice(index, 1);
            }

            return establishment;
          }
        ),
      };
    case CONNECT_INVOICEINFO_ESTABLISHMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        establishments: state.establishments.map(
          (establishment: IEstablishment): IEstablishment => {
            if (establishment.id === action.establishmentId) {
              establishment.invoiceInformationId = action.invoiceInfoId;
            }
            return establishment;
          }
        ),
      };
    case DISCONNECT_INVOICEINFO_ESTABLISHMENT:
      return {
        ...state,
        loading: false,
        establishments: state.establishments.map(
          (establishment: IEstablishment): IEstablishment => {
            if (establishment.id === action.establishmentId) {
              establishment.invoiceInformationId = '';
            }
            return establishment;
          }
        ),
      };
    default:
      return state;
  }
};
