import React from 'react';
import {isArray} from 'util';
import {IHtmlListData} from './IHtmlListData';
import './HtmlList.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';

interface IProps {
  list: IHtmlListData[];
}

const HtmlList: React.FunctionComponent<IProps> = props => (
  <div className="list-component">{props.list.map(getItem)}</div>
);

const getItem = (item: IHtmlListData, index: number) => {
  let content: JSX.Element[] = [];
  if (isArray(item.content)) {
    content = item.content.map((line, lineIndex) => <div key={`itemLine${lineIndex}`}>{line}</div>);
  }

  let buttons: any = [];
  if (item.buttons) {
    buttons = item.buttons.map((button, buttonIndex) => (
      <span key={`button${buttonIndex}`}>
        <Link className="htmlButton" to={button.link}>
          <FontAwesomeIcon icon={button.icon} />
        </Link>
      </span>
    ));
  }

  const colors = ['#428be3', '#dd019a', '#d24444', '#69d321', '#fbb03b'];

  return (
    <div className="list-item" key={index}>
      <div className="color-div" style={{backgroundColor: colors[index % 5]}} />
      <div className="list-item-element" key={`item${index}`}>
        {content}
        <div className="list-item-buttons">{buttons}</div>
      </div>
    </div>
  );
};

export default HtmlList;
