import React, {useEffect} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IAppState} from '../../../App/IAppState';
import {IOrganisation} from '../../Store/IOrganisation';
import Card from '../../../App/Components/Card/Card';
import CardRow from '../../../App/Components/Card/CardRow/CardRow';
import CardDescription from '../../../App/Components/Card/Description/Description';
import CardHeader from '../../../App/Components/Card/CardHeader/CardHeader';
import CardFooter from '../../../App/Components/Card/CardFooter/CardFooter';
import LoadingDots from '../../../App/LoadingDots/LoadingDots';
import {getOrganisationAddresses} from '../../AddAddress/AddressStore/Actions';
import {IAddress} from '../../AddAddress/AddressStore/IAddress';

interface IDispatchProps {
  getOrganisationAddresses: (organisationId: string) => void;
}

interface IStateProps {
  addresses: IAddress[];
  addressesLoading: boolean;
}

interface IComponentProps {
  organisation: IOrganisation;
  loading: boolean;
}

type IProps = IStateProps & IDispatchProps & IComponentProps;

const AddressesCard: React.FunctionComponent<IProps> = (props: IProps) => {
  const {t} = useTranslation();
  useEffect(() => {
    if (!props.addressesLoading && props.addresses.length === 0) {
      props.getOrganisationAddresses(props.organisation.id);
    }
  }, [props.organisation]);

  const addressActions = (
    <Link to={`${props.organisation.id}/addresses`}>
      <div className="address-card-link">
        {props.addresses.length - 3 > 0 ? `+ ${props.addresses.length - 3} more ` : ''}
        <FontAwesomeIcon className="card-arrow" icon={['fas', 'arrow-right']} />
      </div>
    </Link>
  );

  const addressesCard = (
    <Card>
      <CardHeader
        text={t('Addresses')}
        picture={<FontAwesomeIcon className="general-icon" icon={['fas', 'map-marked-alt']} />}
      />
      {props.addresses.slice(0, 3).map((address: IAddress) => (
        <div key={address.id}>
          <CardRow>
            <CardDescription
              text={`${address.street} ${address.houseNumber}, ${address.postalCode} ${address.city}`}
            />
          </CardRow>
        </div>
      ))}
      <CardFooter actions={addressActions} />
    </Card>
  );

  const loadingCard = (nothingFoundText?: string) => (
    <Card>
      <CardHeader
        text={t('Addresses')}
        picture={<FontAwesomeIcon className="general-icon" icon={['fas', 'map-marked-alt']} />}
      />
      {nothingFoundText ? <CardDescription text={nothingFoundText} /> : <LoadingDots />}
    </Card>
  );

  return (
    <div>
      {props.addressesLoading
        ? loadingCard()
        : props.addresses.length === 0
        ? loadingCard(t('no addresses found'))
        : addressesCard}
    </div>
  );
};
const mapStateToProps = (state: IAppState, componentProps: IComponentProps): IStateProps => ({
  addresses: state.organisationAddresses.addresses.filter(
    (element: IAddress) => element.organisationId === componentProps.organisation.id
  ),
  addressesLoading: state.organisationAddresses.loading,
});

const mapDispatchToProps = (dispatch: any): IDispatchProps =>
  bindActionCreators(
    {
      getOrganisationAddresses: (organisationId: string) =>
        dispatch(getOrganisationAddresses(organisationId)),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddressesCard);
