import React from 'react';

interface IProps {
  text?: any;
  title?: string;
  image?: any;
}

const Description: React.FunctionComponent<IProps> = (props: IProps) => (
  <div className="card-description">
    {props.text}
    {props.image}
  </div>
);

export default Description;
