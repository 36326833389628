import React from 'react';
import {RouteComponentProps} from 'react-router';

const NotFound: React.StatelessComponent<RouteComponentProps<any>> = ({location}) => (
  <div>
    <h1>
      We are sorry, we did not find a webpage at : <code>{location.pathname}</code>
    </h1>
  </div>
);

export default NotFound;
