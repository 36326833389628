import React from 'react';
import {useTranslation} from 'react-i18next';
import {TFunction} from 'i18next';
import {Link} from 'react-router-dom';
import {IOrganisation} from '../Store/IOrganisation';
import LoadingDots from '../../App/LoadingDots/LoadingDots';
import useEstablishmentForm from './useEstablishmentForm';
import TextInput from '../../App/Components/TextInput/TextInput';
import Dropdown from '../../App/Components/Dropdown/Dropdown';
import Checkbox from '../../App/Components/Checkbox/Checkbox';
import './AddEstablishment';

interface IProps {
  organisation: IOrganisation;
}

const AddEstablishment: React.FunctionComponent<IProps> = (props: IProps) => {
  const {t} = useTranslation();

  return (
    <div>
      {props.organisation ? (
        <div className="form-layout">
          <h2>
            {t('Add establishment for')}:<br />
          </h2>
          <h1>{props.organisation.name}</h1>
          {getContent(t, props)}
        </div>
      ) : (
        <LoadingDots />
      )}
    </div>
  );
};

const getContent = (t: TFunction, props: IProps) => {
  const [
    name,
    nameError,
    email,
    emailError,
    label,
    labelError,
    language,
    languageError,
    setValue,
    onSubmit,
    status,
    reset,
    tryAgain,
  ] = useEstablishmentForm(props.organisation);

  const labels = [
    {
      label: 'Optios',
      value: 'optios',
    },
    {
      label: 'Esthios',
      value: 'esthios',
    },
  ];

  const languages = [
    {
      label: 'Nederlands',
      value: 'nl',
    },
    {
      label: 'English',
      value: 'en',
    },
    {
      label: 'Français',
      value: 'fr',
    },
    {
      label: 'Deutsch',
      value: 'de',
    },
  ];

  if (status === 'submitting') {
    return <LoadingDots />;
  }

  if (status === 'success') {
    return (
      <>
        <div className="status-message success">{t('Establishment has been added.')}</div>
        <button className="button" onClick={reset}>
          {t('Return')}
        </button>
      </>
    );
  }

  if (status === 'failed') {
    return (
      <>
        <div className="status-message error">{t('Unable to add establishment.')}</div>
        <button className="button" onClick={tryAgain}>
          {t('Try again')}
        </button>
      </>
    );
  }

  return (
    <form onSubmit={onSubmit}>
      <TextInput
        label={t('Name')}
        value={String(name)}
        onChange={value => {
          setValue('name', value);
        }}
        errorMessage={String(nameError)}
      />
      <TextInput
        label={t('Email')}
        value={String(email)}
        onChange={value => {
          setValue('email', value);
        }}
        errorMessage={String(emailError)}
      />
      <Dropdown
        label={t('Label')}
        value={String(label)}
        onChange={(selectedItem: string) => {
          setValue('label', selectedItem);
        }}
        errorMessage={String(labelError)}
        options={labels}
      />
      <Dropdown
        label={t('Language')}
        value={String(language)}
        onChange={(selectedItem: string) => {
          setValue('language', selectedItem);
        }}
        errorMessage={String(languageError)}
        options={languages}
      />
      <Checkbox
        label={t('Trial modules')}
        onChange={value => {
          setValue('with_trial_modules', value);
        }}
      />
      <Checkbox
        label={t('Sample data')}
        onChange={value => {
          setValue('with_sample_data', value);
        }}
      />
      <button type="submit" className="button">
        {t('Create')}
      </button>
      <Link className="button dim" to={`/accounts/${props.organisation.id}`}>
        {t('Cancel')}
      </Link>
    </form>
  );
};

export default AddEstablishment;
