import {Dispatch} from 'react';
import {userConstants} from './Reducer';
import SecurityApiClient from './SecurityApiClient';
import {ICurrentUser} from "./ISecurityState";

export interface ILoginSuccessResponse {
    access_token: string;
    refresh_token: string;
    expires_at: string;
}

export const userActions = {
    login,
    logout,
    validateCredentials,
};


interface IRequest {
}

function loginRequest(): IRequest {
    return {type: userConstants.LOGIN_REQUEST};
}

function success(accessToken: string, refreshToken: string, expiresAt: string, roles: string[]) {
    return {
        type: userConstants.LOGIN_SUCCESS,
        access_token: accessToken,
        refresh_token: refreshToken,
        expires_at: expiresAt,
        roles
    };
}

function failure(error: string) {
    return {type: userConstants.LOGIN_FAILURE, error};
}

function logout() {
    localStorage.clear();

    return {type: userConstants.LOGOUT};
}

function login(email: string, password: string) {
    return async (dispatch: Dispatch<IRequest>): Promise<ICurrentUser | undefined> => {
        dispatch(loginRequest());

        try {
            const data = await SecurityApiClient.login(email, password)
            const user: ICurrentUser = await SecurityApiClient.getCurentUser(data.access_token)

            dispatch(success(
                data.access_token,
                data.refresh_token,
                data.expires_at,
                user.roles
            ));

            localStorage.setItem('access_token', data.access_token);
            localStorage.setItem('refresh_token', data.refresh_token);
            localStorage.setItem('expired_at', data.expires_at);

            return user;
        } catch (error: any) {
            let errorMessage = 'Oops, something went wrong.';
            if (error.response.data.message) {
                errorMessage = error.response.data.message;
            }
            dispatch(failure(errorMessage));

            return error;
        }
    };
}

function validateCredentials() {
    return async (dispatch: Dispatch<IRequest>): Promise<ICurrentUser | undefined> => {
        if (!localStorage.getItem('access_token')) {
            return;
        }

        dispatch(loginRequest());

        try {
            const accessToken = String(localStorage.getItem('access_token'));
            await SecurityApiClient.validateAccessToken(accessToken);
            const user: ICurrentUser = await SecurityApiClient.getCurentUser(accessToken);

            dispatch(
                success(
                    accessToken,
                    String(localStorage.getItem('refresh_token')),
                    String(localStorage.getItem('expired_at')),
                    user.roles
                )
            )

            return user;
        } catch (error: any) {
            dispatch(logout());

            return error
        }
    };
}
