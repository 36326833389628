import { makeStyles } from '@material-ui/styles'

export const styles = makeStyles(theme => ({
  input: {
    borderStyle: 'dashed',
    borderColor: '#727273',
  },
  label: {
    display: 'inline',
    padding: '4px',
  },
  button: {
    borderRadius: '99px',
    border: '3px solid',
    borderColor: '#2164db',
    backgroundColor: 'white',
    padding: '8px 0px',
    '&:hover': {
      backgroundColor: '#2164db',
      color: '#fff',
    },
    '&:hover $icon': {
      color: '#fff',
    },
  },
  icon: {
    color: '#2164db',
  },
}))
