import React, { FunctionComponent, useState } from 'react'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import Paper from '@material-ui/core/Paper'
import TableHead from './components/TableHead'
import { CollapsibleTableBody } from './components/CollapsibleTableBody/CollapsibleTableBody'
import TableModal from './components/TableModal'
import { IProps } from './IProps'

export const CollapsibleTable: FunctionComponent<IProps> = ({
  brandList,
  columnMapping,
  fileSelected,
  statusProgress,
  openModal,
}) => {
  const [rowId, setRowId] = useState<string>('')

  return (
    <>
      <TableContainer style={{ borderRadius: '15px', padding: '16px' }} component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead />
          <CollapsibleTableBody
            setSelectedRowID={setRowId}
            brandList={brandList}
            openModal={openModal}
          />
        </Table>
      </TableContainer>
      <TableModal
        rowId={rowId}
        brands={brandList}
        columnMapping={columnMapping}
        fileSelected={fileSelected}
        statusProgress={statusProgress}
      />
    </>
  )
}
