import {Reducer} from 'redux';
import {
  ADD_ORGANISATION,
  ADD_ORGANISATION_ERROR,
  GET_ACCOUNTS_ERROR,
  GET_ACCOUNTS_LOADING,
  GET_ACCOUNTS_SUCCESS,
  ORGANISATIONS_LOADING,
  CHANGE_ORGANISATION_NAME_LOADING,
  CHANGE_ORANISATION_NAME,
  SET_CURRENT_ORGANISATION,
} from './Actions';
import {IAccountsState} from './IAccountsState';

const initialState: IAccountsState = {
  search: {
    error: null,
    result: [],
    isLoading: false,
  },
  loading: false,
  list: [],
  error: null,
  changeOrganisationNameLoading: false,
};

const reducer: Reducer<IAccountsState> = (
  state: IAccountsState = initialState,
  action: any
): IAccountsState => {
  switch (action.type) {
    case GET_ACCOUNTS_LOADING:
      return {
        ...state,
        search: {
          ...state.search,
          isLoading: true,
        },
      };
    case GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        search: {
          ...state.search,
          isLoading: false,
          result: action.accounts,
        },
      };
    case GET_ACCOUNTS_ERROR:
      return {
        ...state,
        search: {
          ...state.search,
          isLoading: false,
          error: action.error,
        },
      };
    case ORGANISATIONS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADD_ORGANISATION:
      const foundIndex = state.list.findIndex(
        (organisation: any) => organisation.id === action.organisation.id
      );
      if (foundIndex >= 0) {
        return {
          ...state,
          loading: false,
        };
      }
      return {
        ...state,
        loading: false,
        list: [...state.list, action.organisation],
      };

    case ADD_ORGANISATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CHANGE_ORGANISATION_NAME_LOADING:
      return {
        ...state,
        changeOrganisationNameLoading: true,
      };
    case CHANGE_ORANISATION_NAME:
      return {
        ...state,
        changeOrganisationNameLoading: false,
      };
    case SET_CURRENT_ORGANISATION:
      return {
        ...state,
        currentOrganisationId: action.organisationId,
      };
    default:
      return state;
  }
};

export default reducer;
