import { makeStyles } from '@material-ui/styles'

export const brandProductsStyles = makeStyles(theme => ({
  backArrow: {
    padding: '10px',
    backgroundColor: '#2164db',
    color: '#fff',
    width: 'fit-content',
    borderRadius: '34px',
    display: 'flex',
    left: '88px',
    top: '12px',
    position: 'fixed',
    '&:hover': {
      backgroundColor: '#2164db',
      color: '#000',
    },
  },
  productsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))
