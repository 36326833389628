import {TFunction} from 'i18next';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import LoadingDots from '../../App/LoadingDots/LoadingDots';
import {countries} from '../../App/Resources/dropDownMenus/countries';
import {languages} from '../../App/Resources/dropDownMenus/languages';
import Dropdown from '../../App/Components/Dropdown/Dropdown';
import TextInput from '../../App/Components/TextInput/TextInput';
import useForm from './useForm';

const AddAccount: React.FunctionComponent = () => {
  const {t} = useTranslation();

  return (
    <div className="add-account-wrapper">
      <h1>{t('Create Account')}</h1>
      {getContent(t)}
    </div>
  );
};

const getContent = (t: TFunction) => {
  const [
    salonName,
    salonNameError,
    name,
    nameError,
    surname,
    street,
    streetNr,
    postalCode,
    city,
    country,
    language,
    homePhone,
    mobilePhone,
    email,
    emailError,
    setvalue,
    onSubmit,
    status,
    reset,
  ] = useForm();

  if (status === 'submitting') {
    return <LoadingDots />;
  }

  if (status === 'success') {
    return (
      <>
        <div className="status-message success">{t('Account has been succesfully created.')}</div>
        <button className="button" onClick={reset}>
          {t('Create another')}
        </button>
      </>
    );
  }

  if (status === 'failed') {
    return (
      <>
        <div className="status-message error">{t('Unable to create account.')}</div>
        <button className="button" onClick={reset}>
          {t('Try again')}
        </button>
      </>
    );
  }

  return (
    <form onSubmit={onSubmit}>
      <TextInput
        label={t('Organisation name')}
        value={String(salonName)}
        onChange={value => {
          setvalue('organisationName', value);
        }}
        errorMessage={String(salonNameError)}
      />
      <TextInput
        label={t('Name')}
        value={String(name)}
        onChange={value => {
          setvalue('name', value);
        }}
        errorMessage={String(nameError)}
      />
      <TextInput
        label={t('Surname')}
        value={String(surname)}
        onChange={value => {
          setvalue('surname', value);
        }}
      />
      <TextInput
        label={t('Street')}
        value={String(street)}
        onChange={value => {
          setvalue('street', value);
        }}
      />
      <TextInput
        label={t('Street number')}
        value={String(streetNr)}
        onChange={value => {
          setvalue('streetNr', value);
        }}
      />
      <TextInput
        label={t('Postal code')}
        value={String(postalCode)}
        onChange={value => {
          setvalue('postalCode', value);
        }}
      />
      <TextInput
        label={t('City')}
        value={String(city)}
        onChange={value => {
          setvalue('city', value);
        }}
      />
      <Dropdown
        label={t('Country')}
        value={String(country)}
        onChange={(selectedItem: string) => {
          setvalue('country', selectedItem);
        }}
        options={countries}
      />
      <Dropdown
        label={t('Language')}
        value={String(language)}
        onChange={(selectedItem: string) => {
          setvalue('language', selectedItem);
        }}
        options={languages}
      />
      <TextInput
        label={t('Home phone')}
        value={String(homePhone)}
        onChange={value => {
          setvalue('homePhone', value);
        }}
      />
      <TextInput
        label={t('Phone')}
        value={String(mobilePhone)}
        onChange={value => {
          setvalue('mobilePhone', value);
        }}
      />
      <TextInput
        label={t('E-mail')}
        value={String(email)}
        onChange={value => {
          setvalue('email', value);
        }}
        errorMessage={String(emailError)}
      />
      <button type="submit" className="button">
        {t('Create')}
      </button>
      <Link className="nav-item" to="/accounts">
        <button className="button dim">{t('Cancel')}</button>
      </Link>
    </form>
  );
};

export default AddAccount;
