export default [
  {
    id: 1,
    name: 'Brand name',
  },
  {
    id: 2,
    name: 'Last requested',
  },
  {
    id: 3,
    name: 'Last updated',
  },
  {
    id: 4,
    name: 'Upload',
  },
]
