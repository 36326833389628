import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {bindActionCreators} from 'redux';
import LoadingDots from '../../App/LoadingDots/LoadingDots';
import {IAppState} from '../../App/IAppState';
import {IOrganisation} from '../Store/IOrganisation';
import HtmlList from '../../App/Components/HtmlList/HtmlList';
import {getOrganisationAddresses} from '../AddAddress/AddressStore/Actions';
import {IHtmlListData} from '../../App/Components/HtmlList/IHtmlListData';
import {IAddress} from '../AddAddress/AddressStore/IAddress';

export interface IProps {
  addresses: any;
  organisation: IOrganisation;
  getOrganisationAddresses: any;
  addressLoading: boolean;
  accountsLoading: boolean;
}

const AddressList: React.FunctionComponent<IProps> = props => {
  useEffect(() => {
    if (!props.addressLoading && props.addresses == '') {
      props.getOrganisationAddresses(props.organisation.id);
    }
  }, [location.pathname]);
  const {t} = useTranslation();
  const addresses: IHtmlListData[] = props.addresses.map((address: IAddress) => {
    const content = [
      `${address.street} ${address.houseNumber}`,
      `${address.postalCode} ${address.city}`,
    ];
    return {
      content,
    };
  });
  return (
    <div className="address-list">
      <h1>
        {props.organisation ? props.organisation.name : null} {t('address list')}
      </h1>
      <div>{props.addressLoading ? <LoadingDots /> : <HtmlList list={addresses} />}</div>
    </div>
  );
};
const mapStateToProps = (state: IAppState) => ({
  addresses: state.organisationAddresses.addresses,
  addressLoading: state.organisationAddresses.loading,
  accountsLoading: state.accounts.loading,
  loading: state.accounts.loading,
});
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      getOrganisationAddresses: (organisationId: string) =>
        dispatch(getOrganisationAddresses(organisationId)),
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(AddressList);
