import { combineReducers, Reducer } from 'redux'
import accountsReducer from '../Accounts/Store/Reducer'
import { reducer as modalReducer } from './Components/Modal/Reducer'
import { reducer as securityReducer } from '../Security/Reducer'
import { reducer as addressReducer } from '../Accounts/AddAddress/AddressStore/Reducer'
import { reducer as contactsReducer } from '../Accounts/ContactList/ContactStore/Reducer'
import { reducer as establishmentReducer } from '../Accounts/Dashboard/EstablishmentStore/Reducer'
import { reducer as invoiceInfoReducer } from '../Accounts/Dashboard/InvoiceInfoStore/Reducer'
import { reducer as dashboardReducer } from '../Dashboard/Store/Reducer'
import ModuleReducer from '../store/Module/ModuleReducer'
import BrandReducer from '../store/Brands/Reducer'
import { IAppState } from './IAppState'
import ProductReducer from '../store/Products/Reducer'
import PartnerReducer from '../store/Partners/Reducer'

const reducer: Reducer<IAppState> = combineReducers<IAppState>({
  security: securityReducer,
  accounts: accountsReducer,
  modal: modalReducer,
  brands: BrandReducer,
  products: ProductReducer,
  organisationAddresses: addressReducer,
  organisationContacts: contactsReducer,
  organisationEstablishments: establishmentReducer,
  organisationInvoiceInfo: invoiceInfoReducer,
  dashboard: dashboardReducer,
  modules: ModuleReducer,
  partners: PartnerReducer,
})

export default reducer
