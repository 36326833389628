import {Reducer} from 'redux';
import {GET_ORGANISATION_ADDRESSES, ORGANISATION_ADDRESSES_LOADING} from './Actions';
import {IAddressState} from './IAddressState';

const initialState: IAddressState = {
  loading: false,
  error: '',
  addresses: [],
};
export const reducer: Reducer<IAddressState> = (
  state: IAddressState = initialState,
  action: any
): IAddressState => {
  switch (action.type) {
    case ORGANISATION_ADDRESSES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ORGANISATION_ADDRESSES:
      return {
        ...state,
        loading: false,
        addresses: state.addresses.concat(action.addresses),
      };
    default:
      return state;
  }
};
