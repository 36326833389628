import React, {useState} from 'react';
import {IOrganisation} from 'Accounts/Store/IOrganisation';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {TFunction} from 'i18next';
import useInvoiceInfoForm from './useInvoiceInfoForm';
import {IAppState} from '../../App/IAppState';
import LoadingDots from '../../App/LoadingDots/LoadingDots';
import Dropdown from '../../App/Components/Dropdown/Dropdown';
import TextInput from '../../App/Components/TextInput/TextInput';

interface IProps {
  organisation: IOrganisation;
  accessToken: string;
  addresses: any;
}

const AddInvoiceInfo: React.FunctionComponent<IProps> = props => {
  const {t} = useTranslation();
  return (
    <div className="form-layout">
      {props.organisation ? (
        <div>
          <h2>
            {t('Add invoice information for')}:<br />
          </h2>
          <h1>{props.organisation.name}</h1>
          {getContent(t, props)}
        </div>
      ) : (
        <LoadingDots />
      )}
    </div>
  );
};
const getContent = (t: TFunction, props: IProps) => {
  const [countryCode, setCountryCode] = useState('BE');

  const [
    ibanNumber,
    ibanNumberError,
    vatNumber,
    vatNumberError,
    registrationNumber,
    registrationNumberError,
    email,
    emailError,
    name,
    nameError,
    addressId,
    setvalue,
    onSubmit,
    status,
    reset,
    tryAgain,
  ] = useInvoiceInfoForm(props.accessToken, props.organisation);

  if (status === 'submitting') {
    return <LoadingDots />;
  }
  if (status === 'success') {
    return (
      <>
        <div className="status-message success">{t('Invoice information has been added.')}</div>
        <button className="button" onClick={reset}>
          {t('Return')}
        </button>
      </>
    );
  }
  if (status === 'failed') {
    return (
      <>
        <div className="status-message error">{t('Unable to add invoice information.')}</div>
        <button className="button" onClick={tryAgain}>
          {t('Try again')}
        </button>
      </>
    );
  }

  return (
    <form onSubmit={onSubmit}>
      <TextInput
        label={t('Name')}
        value={String(name)}
        onChange={value => {
          setvalue('name', value);
        }}
        errorMessage={String(nameError)}
      />
      <Dropdown
        label={t('Address')}
        value={String(addressId)}
        onChange={(selectedItem: string) => {
          setvalue('addressId', selectedItem);
          const address = props.addresses.find((element: any) => element.value === selectedItem);

          if (address) {
            setCountryCode(address.countryCode);
          }
        }}
        options={props.addresses}
      />
      <TextInput
        label={t('Email')}
        value={String(email)}
        onChange={value => {
          setvalue('email', value);
        }}
        errorMessage={String(emailError)}
      />
      <TextInput
        label={t('Iban number')}
        value={String(ibanNumber)}
        onChange={value => {
          setvalue('ibanNumber', value);
        }}
        errorMessage={String(ibanNumberError)}
        format={(value: string): string => {
          let formattedValue = value ? value.toUpperCase() : '';
          const splitValue = formattedValue.replace(/[^a-zA-Z0-9]/g, '').match(/.{1,4}/g);
          if (splitValue !== null) {
            formattedValue = splitValue.join(' ');
          }

          return formattedValue;
        }}
        cleanFormat={(value: string): string => value.replace(/[^a-zA-Z0-9]/g, '')}
      />
      <TextInput
        label={t('Vat number')}
        value={String(vatNumber)}
        onChange={value => {
          setvalue('vatNumber', value);
          if (countryCode === 'BE') {
            setvalue('registrationNumber', value.substr(2));
          }
        }}
        errorMessage={String(vatNumberError)}
      />
      {countryCode !== 'BE' ? (
        <TextInput
          label={t('Registration number')}
          value={String(registrationNumber)}
          onChange={value => {
            setvalue('registrationNumber', value);
          }}
          errorMessage={String(registrationNumberError)}
        />
      ) : (
        ''
      )}
      <button type="submit" className="button">
        {t('Create')}
      </button>
      <Link className="button dim" to={`/accounts/${props.organisation.id}`}>
        {t('Cancel')}
      </Link>
    </form>
  );
};

const mapStateToProps = (state: IAppState) => ({
  accessToken: state.security.accessToken,
  addresses: state.organisationAddresses.addresses.map((x: any) => ({
    label: x.city,
    value: x.id,
    countryCode: x.country_code,
  })),
});
export default connect(mapStateToProps)(AddInvoiceInfo);
