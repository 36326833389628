import React, {useEffect} from 'react';
import Card from '../../App/Components/Card/Card';
import CardDescription from '../../App/Components/Card/Description/Description';
import './PayingCustomerCard.scss';
import {useDispatch, useSelector} from 'react-redux';
import {getPayingCustomers} from '../Store/Actions';
import {IAppState} from '../../App/IAppState';
import LoadingDots from '../../App/LoadingDots/LoadingDots';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from 'moment';

const PayingCustomerCard: React.FunctionComponent<any> = () => {
  const dispatch = useDispatch();

  const payingCustomers = useSelector((state: IAppState) => {
    const findPayingCustomers = state.dashboard.statistics.filter(
      stat => stat.statisticType === 'paying_customers'
    );
    return findPayingCustomers;
  });
  let newestValue;
  let lastMonthValue: any;
  let loading = false;
  let growthValue: number;

  const now = moment().format('YYYY-MM-DD');
  const endOfPrevMonth = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');

  if (payingCustomers.length > 1) {
    // find the value of the last day of the previous month
    lastMonthValue = payingCustomers.find(element => element.date === endOfPrevMonth);
    // find the newest value available
    newestValue = payingCustomers.reduce((a, b) => (a.date > b.date ? a : b));
    loading = newestValue.loading;

    if (lastMonthValue) growthValue = newestValue.count - lastMonthValue.count;
  }
  useEffect(() => {
    if (payingCustomers.length === 0 && loading === false) {
      dispatch(getPayingCustomers(endOfPrevMonth, now));
    }
  });
  const cardText = () => {
    if (growthValue >= 0) {
      return (
        <div>
          <span>{lastMonthValue ? lastMonthValue.count : 0}</span>
          <span className="positive-growth"> +{growthValue} </span>
        </div>
      );
    }
    return (
      <div>
        <span>{lastMonthValue ? lastMonthValue.count : 0}</span>
        <span className="negative-growth"> {growthValue} </span>
      </div>
    );
  };

  return (
    <div className="paying-customer-card">
      <Card>
        <div className="card-block">
          <div className="card-icon">
            <FontAwesomeIcon icon={['fas', 'users']} />
          </div>
          <div className="card-name">Users</div>
          <div className="card-content">
            {loading ? <LoadingDots /> : <CardDescription text={cardText()} />}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default PayingCustomerCard;
