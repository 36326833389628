import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IAppState} from 'App/IAppState';
import React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {compose, Dispatch} from 'redux';
import LoadingDots from '../../App/LoadingDots/LoadingDots';
import logo from '../../App/Resources/Images/logo.png';
import {userActions} from '../Actions';
import './Login.scss';

interface IProps {
  email: string;
  password: string;
}

interface IConnectedProps extends IProps, WithTranslation {
  dispatch: Dispatch<any>;
  isLoggingIn: boolean;
  error: string;
}

interface IState {
  email: string;
  password: string;
  emailError: boolean;
}

export const isLoggedIn = false;

class Login extends React.Component<IConnectedProps, IState> {
  constructor(props: IConnectedProps) {
    super(props);

    this.state = {
      email: props.email,
      password: props.password,
      emailError: false,
    };
  }

  onEmailChange(value: string): void {
    this.setState({
      email: value,
    });
  }

  onPasswordChange(value: string): void {
    this.setState({
      password: value,
    });
  }

  onSubmitLogin() {
    if (!this.validate()) {
      this.setState({emailError: true});
    } else {
      this.setState({emailError: false});
      this.props.dispatch(userActions.login(this.state.email, this.state.password));
    }
  }

  validate(): boolean {
    const check = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.email);

    return check && this.state.password != '';
  }

  render() {
    if (!this.props.isLoggingIn) {
      this.props.dispatch(userActions.validateCredentials());
    }

    let {error} = this.props;
    if (!error && this.state.emailError) {
      error = 'Invalid username and/or password';
    }
    const {t} = this.props;
    return (
      <div>
        <div className="home-wrapper">
          <img src={logo} alt="Optios Logo" className="home-logo" />
          <h1>{t('Backoffice')}</h1>
          <div className="home-form">
            <form
              className="form-split"
              method="post"
              noValidate
              onSubmit={e => {
                e.preventDefault();
                this.onSubmitLogin();
              }}
            >
              <label>
                {error ? <p className="error">{error}</p> : null}

                <FontAwesomeIcon icon={['fas', 'envelope']} className="iconLogin" />
                <input
                  type="email"
                  id="email"
                  className="input"
                  name="email"
                  placeholder={t('E-mail')}
                  value={this.state.email}
                  onChange={e => this.onEmailChange(e.target.value)}
                  required
                />
              </label>

              <label>
                <FontAwesomeIcon icon={['fas', 'lock']} className="iconLogin" />
                <input
                  type="password"
                  className="input"
                  name="password"
                  id="password"
                  placeholder={t('Password')}
                  value={this.state.password}
                  onChange={e => this.onPasswordChange(e.target.value)}
                  required
                />
              </label>
              {this.props.isLoggingIn ? (
                <LoadingDots />
              ) : (
                <button className="button" type="submit">
                  Login
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: IAppState) {
  return {
    isLoggingIn: state.security.isLoading,
    error: state.security.error,
  };
}

export default compose(
  connect(mapStateToProps),
  withTranslation()
)(Login) as React.ComponentType<IProps>;
