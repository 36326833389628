import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {IAppState} from '../../App/IAppState';
import Dropdown from '../../App/Components/Dropdown/Dropdown';
import TextInput from '../../App/Components/TextInput/TextInput';
import LoadingDots from '../../App/LoadingDots/LoadingDots';
import {IOrganisation} from '../Store/IOrganisation';
import useContactForm from './useContactForm';
import {getOrganisationAddresses} from '../AddAddress/AddressStore/Actions';
import {IError} from '../IError';
import './AddContact.scss';

interface IProps {
  organisation: IOrganisation;
  addresses: any;
  getOrganisationAddresses: (organisationId: string) => void;
}

const AddContact: React.FunctionComponent<IProps> = (props: IProps) => {
  useEffect(() => {
    if (props.addresses.length === 0) {
      props.getOrganisationAddresses(props.organisation.id);
    }
  }, [location.pathname]);
  const {t} = useTranslation();
  const [
    contact,
    errors,
    // @ts-ignore | setContact not used in AddContactForm
    setContact,
    setValue,
    onSubmit,
    status,
    reset,
    tryAgain,
  ] = useContactForm(props.organisation);

  let AddContactForm: any = <div>{t('something went wrong')}</div>;
  if (status === 'submitting') {
    AddContactForm = <LoadingDots />;
  } else if (status === 'success') {
    AddContactForm = (
      <>
        <div className="status-message success">{t('Contact has been added.')}</div>
        <button className="button" onClick={reset}>
          {t('Add another')}
        </button>
      </>
    );
  } else if (status === 'failed') {
    AddContactForm = (
      <>
        <div className="status-message error">{t('Unable to add contact.')}</div>
        <button className="button" onClick={tryAgain}>
          {t('Try again')}
        </button>
      </>
    );
  } else {
    const nameError = errors.find((error: IError) => error.property === 'name');
    const emailError = errors.find((error: IError) => error.property === 'email');

    AddContactForm = (
      <form onSubmit={onSubmit}>
        <Dropdown
          label={t('Address')}
          value={String(contact.addressId ? contact.addressId : '')}
          onChange={(selectedItem: string) => {
            setValue('addressId', selectedItem);
          }}
          options={props.addresses}
        />
        <TextInput
          label={t('Name')}
          value={String(contact.name)}
          onChange={value => {
            setValue('name', value);
          }}
          errorMessage={nameError ? nameError.message : undefined}
        />
        <TextInput
          label={t('Surname')}
          value={String(contact.lastName)}
          onChange={value => {
            setValue('lastName', value);
          }}
        />
        <TextInput
          label={t('Phone')}
          value={String(contact.phone)}
          onChange={value => {
            setValue('phone', value);
          }}
        />
        <TextInput
          label={t('E-mail')}
          value={String(contact.email)}
          onChange={value => {
            setValue('email', value);
          }}
          errorMessage={emailError ? emailError.message : undefined}
        />
        <button type="submit" className="button">
          {t('Create')}
        </button>
        <Link className="nav-item" to={`/accounts/${props.organisation.id}`}>
          <button className="button dim">{t('Cancel')}</button>
        </Link>
      </form>
    );
  }

  return (
    <div className="add-contact">
      {props.organisation ? (
        <div>
          <h2>
            {t('Add contact for')}:<br />
          </h2>
          <h1>{props.organisation.name}</h1>
          {AddContactForm}
        </div>
      ) : (
        <LoadingDots />
      )}
    </div>
  );
};

const mapStateToProps = (state: IAppState) => ({
  addresses: state.organisationAddresses.addresses.map((address: any) => ({
    label: `${address.street} ${address.houseNumber}, ${address.city}`,
    value: address.id,
  })),
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      getOrganisationAddresses: (organisationId: string) =>
        dispatch(getOrganisationAddresses(organisationId)),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddContact);
