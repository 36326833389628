import React from 'react';
import {Bar, Line} from 'react-chartjs-2';
import Card from '../../App/Components/Card/Card';
import './NewCustomersCard.scss';

const NewCustomersCard: React.FunctionComponent = () => {
  const fakeDataSet = [
    {
      month: 'september',
      count: 37,
    },
    {
      month: 'oktober',
      count: 50,
    },
    {
      month: 'november',
      count: 12,
    },
  ];
  const data = () => ({
    labels: fakeDataSet.map(x => x.month),
    datasets: [
      {
        label: 'Nieuwe klanten',
        data: fakeDataSet.map(x => x.count),
        borderWidth: 1,
      },
      {
        label: 'Sales target',
        data: fakeDataSet.map(x => 40),
        pointHoverRadius: [5],
        type: 'line',
        pointBorderColor: 'rgba(0, 0, 0, 0)',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderColor: 'rgba(6, 200, 116,1)',
        fill: true,
      },
    ],
  });

  return (
    <div className="new-customers-card">
      <Card>
        <Bar data={data}>
          {' '}
          <Line data={data} />{' '}
        </Bar>
      </Card>
    </div>
  );
};

export default NewCustomersCard;
