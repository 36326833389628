import React from 'react';
import {Route, Switch} from 'react-router';
import Add from './Add/Add';
import Table from './Table/Table';
import './Translations.scss';

const Translations: React.FunctionComponent = props => (
  <div className="main-content">
    <Switch>
      <Route
        exact
        path="/translations/add/:domain"
        render={routeProps => <Add domain={routeProps.match.params.domain} />}
      />
      <Route path="/translations" render={() => <Table />} />
      <Route
        path="/translations/:domain"
        render={routeProps => <Table domain={routeProps.match.params.domain} />}
      />
    </Switch>
  </div>
);

export default Translations;
