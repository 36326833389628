import axios, {AxiosResponse} from 'axios';

export default class EstablishmentClient {
  static getEstablishments(organisationId: string): Promise<AxiosResponse> {
    const requestOptions: object = {
      method: 'GET',
      url: `${process.env.API_URL}/organisation/${organisationId}/establishments`,
    };

    return axios(requestOptions);
  }

  static connectContactToEstablishment(
    organisationId: string,
    contactId: string,
    establishmentId: string
  ): Promise<AxiosResponse> {
    // const requestOptions: object = {
    //     method: 'POST',
    //     url: `${process.env.API_URL}/organisation/${organisationId}/establishments` // URL TODO
    // }

    // return axios(requestOptions)

    const response: AxiosResponse<string> = {
      data: 'resolved',
      status: 200,
      statusText: 'OK',
      headers: undefined,
      config: {},
    };
    return Promise.resolve(response);
  }

  static disconnectContactToEstablishment(
    organisationId: string,
    contactId: string,
    establishmentId: string
  ): Promise<AxiosResponse> {
    // const requestOptions: object = {
    //     method: 'POST',
    //     url: `${process.env.API_URL}/organisation/${organisationId}/establishments` // URL TODO
    // }

    // return axios(requestOptions)

    const response: AxiosResponse<string> = {
      data: 'resolved',
      status: 200,
      statusText: 'OK',
      headers: undefined,
      config: {},
    };
    return Promise.resolve(response);
  }

  static connectInvoiceInfoToEstablishment(
    organisationId: string,
    contactId: string,
    establishmentId: string
  ): Promise<AxiosResponse> {
    // const requestOptions: object = {
    //     method: 'POST',
    //     url: `${process.env.API_URL}/organisation/${organisationId}/establishments` // URL TODO
    // }

    // return axios(requestOptions)

    const response: AxiosResponse<string> = {
      data: 'resolved',
      status: 200,
      statusText: 'OK',
      headers: undefined,
      config: {},
    };
    return Promise.resolve(response);
  }

  static disconnectInvoiceInfoToEstablishment(
    organisationId: string,
    contactId: string,
    establishmentId: string
  ): Promise<AxiosResponse> {
    // const requestOptions: object = {
    //     method: 'POST',
    //     url: `${process.env.API_URL}/organisation/${organisationId}/establishments` // URL TODO
    // }

    // return axios(requestOptions)

    const response: AxiosResponse<string> = {
      data: 'resolved',
      status: 200,
      statusText: 'OK',
      headers: undefined,
      config: {},
    };
    return Promise.resolve(response);
  }
}
