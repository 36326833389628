import React from 'react';
import {useTranslation} from 'react-i18next';
import Form from '../Form/Form';

type IProps = {
  domain: string;
};

const Add: React.FunctionComponent<IProps> = props => {
  const [t] = useTranslation();

  return (
    <div className="main-content">
      <div className="page-layout">
        <h1>{t('Add translation')}</h1>

        <Form
          translation={{key: '', en: '', nl: '', nl_NL: '', fr: '', it: '', de: '', sq: ''}}
          domain={props.domain}
        />
      </div>
    </div>
  );
};

export default Add;
