import React, {ChangeEvent, useState} from 'react';

interface IProps {
  label: string;
  value?: string;
  onChange?: (value: string) => void;
  rows?: number;
  full?: boolean;
  errorMessage?: string;
}

const TextareaInput: React.FunctionComponent<IProps> = (props: IProps) => {
  const [value, rows, onTextareaChange] = useTextarea(
    props.value ? props.value : '',
    props.rows ? props.rows : 2,
    props.onChange ? props.onChange : null
  );

  const classes = [];
  if (props.full) classes.push('full');
  if (props.errorMessage) classes.push('error');

  return (
    <label className={classes.join(' ')}>
      <div className="text">{props.label}</div>
      <textarea className="input" onChange={onTextareaChange} rows={rows} value={value} />
      {props.errorMessage ? <div className="error">{props.errorMessage}</div> : ''}
    </label>
  );
};

const useTextarea = (
  initialValue: string,
  minRows: number,
  onChangeCallback: null | ((value: string) => void)
): any => {
  const [value, setValue] = useState(initialValue);
  const [rows, setRows] = useState(minRows);

  const onTextareaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const textareaLineHeight = 24;
    const previousRows = event.target.rows;
    event.target.rows = minRows;

    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    setValue(event.target.value);
    setRows(currentRows);

    if (onChangeCallback) {
      onChangeCallback(event.target.value);
    }
  };

  return [value, rows, onTextareaChange];
};

export default TextareaInput;
