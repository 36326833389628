import React from 'react';
import {Doughnut} from 'react-chartjs-2';
import CardRow from '../../App/Components/Card/CardRow/CardRow';
import Card from '../../App/Components/Card/Card';

const CustomerPercentageCard: React.FunctionComponent = () => {
  const fakeDataset = () => ({
    labels: ['2 years', '1 year', '6 months', '1 month'],
    datasets: [
      {
        label: '# of Votes',
        backgroundColor: ['#B21F00', '#00A6B4', '#C9DE00', '#2FDE00'],
        data: [10, 30, 15, 45],
        borderWidth: 1,
      },
    ],
  });
  return (
    <div>
      <Card>
        <CardRow> Duurcontracten klanten </CardRow>
        <Doughnut data={fakeDataset()} />
      </Card>
    </div>
  );
};

export default CustomerPercentageCard;
