import React, {FunctionComponent} from "react";

import "./Hero.scss"

const Hero: FunctionComponent<{maxWidth: string}> = ({children, maxWidth}) => {
    return (
        <div className="hero" style={{maxWidth: maxWidth}}>{children}</div>
    )
}

export default Hero
