import {Dispatch} from 'redux';
import ContactsClient from './ContactsClient';
import {IContact, IApiContact} from './IContact';
import ContactTransformer from './ContactTransformer';

export const GET_ORGANISATION_CONTACTS = 'GET_ORGANISATION_CONTACTS';
export const ORGANISATION_CONTACTS_LOADING = 'ORGANISATION_CONTACTS_LOADING';

export function getOrganisationContacts(organisationId: string) {
  return (dispatch: Dispatch<any>) => () => {
    dispatch(contactsLoading());
    ContactsClient.getOrganisationContacts(organisationId).then(response => {
      const contacts: IContact[] = response.data.data.map((apiContact: IApiContact): IContact => {
        const transformedContact = ContactTransformer.transform(apiContact);
        transformedContact.organisationId = organisationId;
        return transformedContact;
      });
      dispatch(organisationContacts(contacts));
    });
  };
}

function contactsLoading() {
  return {
    type: ORGANISATION_CONTACTS_LOADING,
  };
}

function organisationContacts(contacts: IContact[]) {
  return {
    type: GET_ORGANISATION_CONTACTS,
    contacts,
  };
}
