import React, {useEffect, useState} from 'react';
import './TableCellColorPicker.scss';

export default function TableCellColorPicker(props: any) {
  const {colors, onUpdate} = props;

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    document.addEventListener('keydown', (event: any) => {
      // escape
      if (event.keyCode !== 27) {
        return;
      }

      setOpen(false);
    });
  });

  const colorElements = [];
  for (let i = 0; i < colors.length; i++) {
    colorElements.push(
      <div
        className="colorBlock"
        style={{backgroundColor: colors[i]}}
        key={`tableCellColorPicker${colors[i]}`}
        onClick={() => {
          setValue(colors[i]);
          setOpen(false);
          onUpdate(colors[i]);
        }}
      />
    );
  }

  return (
    <div className={`tableCellColorPicker` + ` ${open || ''}`}>
      <button
        className="currentValue"
        style={{backgroundColor: value}}
        onClick={() => setOpen(!open)}
      />
      <div className="overview">{colorElements}</div>
    </div>
  );
}
