export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSED = 'MODAL_CLOSED';
export const SET_MODAL_CONTENT = 'SET_MODAL_CONTENT';

export function openModal(content: any) {
  return {
    type: MODAL_OPEN,
    content,
  };
}

export function closeModal() {
  return {
    type: MODAL_CLOSED,
  };
}
