import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import React from 'react';
import {connect} from 'react-redux';
import {closeModal} from './Actions';
import './Modal.scss';
import {IAppState} from '../../IAppState';

interface IProps {
  isOpen: boolean;
  closeModal: () => {};
  content: any;
}

interface IState {
  isOpen: boolean;
}

class Modal extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.closeModal();
  }

  render() {
    return (
      <div className="modal-main">
        <div className="modal-overlay" onClick={() => this.handleClick()} />
        <div className="modal-container">
          <button className="modal-close" onClick={() => this.handleClick()}>
            <FontAwesomeIcon icon={['fas', 'times']} className="closeIcon" />
          </button>
          <div className="modal-content">{this.props.content}</div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: IAppState) {
  return {
    isOpen: state.modal.isOpen,
    content: state.modal.content,
  };
}

const mapDispatchToProps = (dispatch: any) => ({
  closeModal: () => dispatch(closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
