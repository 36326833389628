import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import './TablePager.scss';

export default function TablePager(props: any) {
  const {pages, currentPage, onPageClick} = props;

  if (!pages || pages === 1) {
    return null;
  }

  const pageButtons = [];

  pageButtons.push(
    <button onClick={() => onPageClick(1)} disabled={currentPage === 1} key="pagerFirst">
      <FontAwesomeIcon icon={faAngleDoubleLeft} />
    </button>
  );

  pageButtons.push(
    <button
      onClick={() => onPageClick(Math.min(pages, currentPage - 1))}
      disabled={currentPage === 1}
      key="pagerPrev"
    >
      <FontAwesomeIcon icon={faAngleLeft} />
    </button>
  );

  for (let pageNumber = 1; pageNumber <= pages; pageNumber++) {
    pageButtons.push(
      <button
        onClick={() => onPageClick(pageNumber)}
        className={pageNumber === currentPage ? 'active' : ''}
        key={`pager${pageNumber}`}
      >
        {pageNumber}
      </button>
    );
  }

  pageButtons.push(
    <button
      onClick={() => onPageClick(Math.min(pages, currentPage + 1))}
      disabled={currentPage === pages}
      key="pagerNext"
    >
      <FontAwesomeIcon icon={faAngleRight} />
    </button>
  );

  pageButtons.push(
    <button onClick={() => onPageClick(pages)} disabled={currentPage === pages} key="pagerLast">
      <FontAwesomeIcon icon={faAngleDoubleRight} />
    </button>
  );

  return <div className="pager">{pageButtons}</div>;
}
