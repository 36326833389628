import React, { FormEvent, FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPartners } from "../../store/Partners/Action";
import { IPartner } from "../../store/Partners/interface/IPartner";
import { IAppState } from "../../App/IAppState";
import LoadingDots from "../../App/LoadingDots/LoadingDots";
import TextInput from "../../App/Components/TextInput/TextInput";
import { updatePassword } from "../../store/Partners/client/Client";

const UpdatePassword: FunctionComponent = () => {
  const {t} = useTranslation()

  const dispatch = useDispatch();
  const history = useHistory();
  const {partnerUuid} = useParams<{ partnerUuid: string }>()
  const partners = useSelector((state: IAppState) => state.partners.list)

  const [partner, setPartner] = useState<IPartner | undefined>()

  useEffect(() => {
    if (!partners) {
      dispatch(getPartners());
      return;
    }

    const foundPartner = partners.find((onePartner) => onePartner.uuid === partnerUuid)
    if (!foundPartner) {
      history.push('/partners');
      return;
    }
    setPartner(foundPartner);
  }, [partners])

  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');

  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<string>();
  const [repeatPasswordError, setRepeatPasswordError] = useState<string>();

  const [isSaving, setIsSaving] = useState<boolean>();

  const validate = (): boolean => {
    let isValid = true;
    if (password === '') {
      setPasswordError(t('Password is required'));
      isValid = false;
    } else {
      setPasswordError(undefined);
    }

    if (repeatPassword !== password) {
      setRepeatPasswordError(t('Password confirmation is not equal to the password'));
      isValid = false;
    } else {
      setRepeatPasswordError(undefined);
    }

    return isValid
  }

  const onFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowErrors(true);
    if (validate()) {
      setIsSaving(true)
      await updatePassword(partnerUuid, password)
      history.push('/partners')

      setIsSaving(false)
    }

    return false;
  }

  if (!partner) {
    return <LoadingDots/>
  }

  return (
    <div className="main-content" style={{textAlign: 'center'}}>
      <div style={{margin: '0 auto 2em auto', maxWidth: '480px', textAlign: 'left'}}>
        <h1>{partner.firstName} {partner.lastName}</h1>
        <h2>{t('Update password')}</h2>
        <form onSubmit={onFormSubmit}>
          <TextInput
            label={t('Password')}
            type="password"
            value={password}
            onChange={(newValue) => setPassword(newValue)}
            errorMessage={showErrors ? passwordError : undefined}
            full
          />
          <TextInput
            label={t('Repeat password')}
            type="password"
            value={repeatPassword}
            onChange={(newValue) => setRepeatPassword(newValue)}
            errorMessage={showErrors ? repeatPasswordError : undefined}
            full
          />
          <button type="submit" className={isSaving ? 'button dim' : 'button'} disabled={isSaving}>
            {t('Save')}
          </button>
          <Link className="button dim" to="/partners">{t('Cancel')}</Link>
        </form>
      </div>
    </div>
  )
}

export default UpdatePassword
