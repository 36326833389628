import {faChevronDown} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react';
import './TableCellDropdown.scss';

export default function TableCellDropDown(props: any) {
  const {choices, onUpdate} = props;

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    document.addEventListener('keydown', (event: any) => {
      // escape
      if (event.keyCode !== 27) {
        return;
      }

      setOpen(false);
    });
  });

  const choiceElements = [];
  let hasSelected = false;
  let selectedText = '';
  for (const content in choices) {
    if (!choices.hasOwnProperty(content)) continue;

    let isSelected = choices[content] === value;
    if ((value === null || typeof value === 'undefined') && !hasSelected) {
      isSelected = true;
    }

    choiceElements.push(
      <div
        onClick={() => {
          setValue(choices[content]);
          setOpen(false);
          onUpdate(choices[content]);
        }}
        className={isSelected ? 'selected' : ''}
        key={`tableCellDropdown${content}`}
      >
        {content}
      </div>
    );

    if (isSelected) selectedText = content;

    hasSelected = hasSelected || isSelected;
  }

  if (!hasSelected) {
    choiceElements.push(
      <div
        onClick={() => {
          setValue(value);
          setOpen(false);
          onUpdate(value);
        }}
        className="selected"
        key="tableCellDropdownDefault"
      >
        {value}
      </div>
    );
    selectedText = value;
  }

  return (
    <div className={`tableCellDropdown` + ` ${open || ''}`}>
      <button className="valueButton" onClick={() => setOpen(!open)}>
        {selectedText}
        <span className="dropDownIcon">
          <FontAwesomeIcon icon={faChevronDown} />
        </span>
      </button>
      <div className="list">{choiceElements}</div>
    </div>
  );
}
