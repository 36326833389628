import React, {StatelessComponent} from 'react';
import './loadingDots.scss';

const LoadingDots: StatelessComponent = props => (
  <div>
    <div className="loader">
      <div className="duo duo1">
        <div className="dot dot-a" />
        <div className="dot dot-b" />
      </div>
      <div className="duo duo2">
        <div className="dot dot-a" />
        <div className="dot dot-b" />
      </div>
    </div>
  </div>
);

export default LoadingDots;
