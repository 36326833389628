import axios, {AxiosResponse} from 'axios';

export default class AccountsApiClient {
  static getAccounts(): Promise<AxiosResponse> {
    const requestOptions: object = {
      method: 'GET',
      url: `${process.env.API_URL}/organisation`,
    };

    return axios(requestOptions);
  }

  static getAccountsForSearch(searchTerm: string): Promise<AxiosResponse> {
    const requestOptions: object = {
      method: 'GET',
      url: `${process.env.API_URL}/organisation?filter[name]=${searchTerm}`,
    };

    return axios(requestOptions);
  }

  static getOrganisation(organisationId: string): Promise<AxiosResponse> {
    const requestOptions: object = {
      method: 'GET',
      url: `${process.env.API_URL}/organisation/${organisationId}`,
    };

    return axios(requestOptions);
  }

  static changeOrganisationName(organisationId: string, name: string): Promise<AxiosResponse> {
    const requestOptions: object = {
      method: 'POST',
      url: `${process.env.API_URL}/organisation/${organisationId}/rename`,
      data: {
        name,
      },
    };

    return axios(requestOptions);
  }
}
