import { Reducer } from 'redux'
import { IPartnerState } from './interface/IPartnerState'
import { initialState } from './initialState'
import { PARTNER_CREATED, PARTNER_DELETED, PARTNER_UPDATED, PARTNERS_LOADED } from './Action'

const ProductReducer: Reducer<IPartnerState> = (state: IPartnerState = initialState, action) => {
  let partnerIndex: number
  switch (action.type) {
    case PARTNERS_LOADED:
      return { ...state, list: action.payload.partners }
    case PARTNER_CREATED:
      if (! state.list) {
        return state;
      }

      return { ...state, list: [ ...state.list, action.payload.partner ]}
    case PARTNER_UPDATED:
      if (! state.list) {
        return state;
      }

      partnerIndex = state.list.findIndex((onePartner) => onePartner.uuid === action.payload.partner.uuid)
      if (partnerIndex === -1) {
        return state;
      }

      return {
        ...state, 
        list: [ ...state.list.slice(0, partnerIndex), action.payload.partner, ...state.list.slice(partnerIndex + 1) ]
      }
    case PARTNER_DELETED:
      if (! state.list) {
        return state;
      }

      partnerIndex = state.list.findIndex((onePartner) => onePartner.uuid === action.payload.partnerUuid)
      if (partnerIndex === -1) {
        return state;
      }

      return {
        ...state,
        list: [ ...state.list.slice(0, partnerIndex), ...state.list.slice(partnerIndex + 1) ]
      }
    default:
      return state
  }
}

export default ProductReducer
