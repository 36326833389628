import moment from 'moment'
import { IAPIProduct, IProduct } from './interface/IProduct'

export const productTransformer = (apiProduct: IAPIProduct): IProduct => ({
  barcode: apiProduct.barcode,
  brand: {
    id: apiProduct.brand.id,
    name: apiProduct.brand.name,
  },
  category: apiProduct.category,
  code: apiProduct.code,
  createdAt: moment(apiProduct.created_at),
  id: apiProduct.id,
  imageId: apiProduct.image_id,
  name: apiProduct.name,
  purchasePrice: apiProduct.purchase_price,
  sellingPrice: apiProduct.selling_price,
})
