import React from 'react';
import {useTranslation} from 'react-i18next';
import {Route, Switch} from 'react-router';
import {Link} from 'react-router-dom';
import SecondaryNav from '../App/Components/SecondaryNav/SecondaryNav';
import Broadcast from './Notification/Broadcast/Broadcast';

const Support: React.FunctionComponent = props => {
  const {t} = useTranslation();
  return (
    <>
      <SecondaryNav>
        <Link className="nav-item" to="/support/notification/broadcast">
          {t('Broadcast notification')}
        </Link>
      </SecondaryNav>
      <Switch>
        <Route path="/support" component={Broadcast} />
      </Switch>
    </>
  );
};

export default Support;
