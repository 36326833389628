import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {IAppState} from 'App/IAppState';
import {useTranslation} from 'react-i18next';
import {bindActionCreators} from 'redux';
import {Link} from 'react-router-dom';
import {IContact} from 'Accounts/ContactList/ContactStore/IContact';
import {IOrganisation} from '../Store/IOrganisation';
import {getOrganisationContacts} from '../ContactList/ContactStore/Actions';
import LoadingDots from '../../App/LoadingDots/LoadingDots';
import {getOrganisationAddresses} from '../AddAddress/AddressStore/Actions';
import useContactForm from '../../Accounts/AddContact/useContactForm';
import Dropdown from '../../App/Components/Dropdown/Dropdown';
import TextInput from '../../App/Components/TextInput/TextInput';
import {IError} from '../IError';

interface IProps {
  organisation: IOrganisation;
  loading: boolean;
  contacts: any;
  contactLoading: boolean;
  addresses: any;
  addressLoading: boolean;
  getOrganisationContacts: (organisationId: string) => void;
  getOrganisationAddresses: (organisationId: string) => void;
}

const EditContact: React.FunctionComponent<IProps> = props => {
  const contactId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  const foundContact = props.contacts.find((oneContact: IContact) => oneContact.id === contactId);

  useEffect(() => {
    if (!props.contactLoading && props.contacts.length === 0) {
      props.getOrganisationContacts(props.organisation.id);
    }
    if (!props.addressLoading && props.addresses.length === 0) {
      props.getOrganisationAddresses(props.organisation.id);
    }
  }, [location.pathname]);
  const {t} = useTranslation();
  const [contact, errors, setContact, setValue, onSubmit, status, reset, tryAgain] = useContactForm(
    props.organisation
  );

  useEffect(() => {
    setContact(foundContact);
  }, [foundContact]);

  let editableContact: any = <div>{t('something went wrong')}</div>;

  if (status === 'submitting') {
    editableContact = <LoadingDots />;
  } else if (status === 'success') {
    editableContact = (
      <>
        <div className="status-message success">{t('Contact has been changed.')}</div>
        <button className="button" onClick={reset}>
          {t('Add another')}
        </button>
        <Link
          to={`/accounts/${props.organisation.id}/contacts/${contactId}`}
          className="button dim"
        >
          {t('Cancel')}
        </Link>
      </>
    );
  } else if (status === 'failed') {
    editableContact = (
      <>
        <div className="status-message error">{t('Unable to change contact.')}</div>
        <button className="button" onClick={tryAgain}>
          {t('Try again')}
        </button>
        <Link
          to={`/accounts/${props.organisation.id}/contacts/${contactId}`}
          className="button dim"
        >
          {t('Cancel')}
        </Link>
      </>
    );
  } else if (!foundContact || !contact) {
    editableContact = <LoadingDots />;
  } else {
    const foundAddress: any = props.addresses.find(
      (address: any) => address.value === contact.addressId
    );

    const nameError = errors.find((error: IError) => error.property === 'name');
    const emailError = errors.find((error: IError) => error.property === 'email');

    editableContact = (
      <form onSubmit={onSubmit}>
        <Dropdown
          label={t('Address')}
          value={foundAddress ? foundAddress.value : undefined}
          onChange={(selectedItem: string) => {
            setValue('addressId', selectedItem);
          }}
          options={props.addresses}
        />
        <TextInput
          label={t('Name')}
          value={contact.name}
          onChange={value => {
            setValue('name', value);
          }}
          errorMessage={nameError ? nameError.message : undefined}
        />
        <TextInput
          label={t('Surname')}
          value={contact.lastName}
          onChange={value => {
            setValue('lastName', value);
          }}
        />
        <TextInput
          label={t('Phone')}
          value={contact.phone ? contact.phone.replace(/^00/, '+') : ''}
          onChange={value => {
            setValue('phone', value);
          }}
        />
        <TextInput
          label={t('E-mail')}
          value={contact.email}
          onChange={value => {
            setValue('email', value);
          }}
          errorMessage={emailError ? emailError.message : undefined}
        />
        <button type="submit" className="button">
          {t('Edit')}
        </button>
        <Link className="nav-item" to={`/accounts/${props.organisation.id}/contacts`}>
          <button className="button dim">{t('Cancel')}</button>
        </Link>
      </form>
    );
  }

  return (
    <div className="edit-contact">
      <h1>{t('Edit contact')}</h1>
      <div>
        {!props.contactLoading && foundContact && props.contacts ? (
          editableContact
        ) : (
          <LoadingDots />
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state: IAppState) => ({
  contacts: state.organisationContacts.contacts,
  contactLoading: state.organisationContacts.loading,
  addresses: state.organisationAddresses.addresses.map((address: any) => ({
    label: `${address.street} ${address.houseNumber}, ${address.city}`,
    value: address.id,
  })),
  addressLoading: state.organisationAddresses.loading,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      getOrganisationContacts: (organisationId: string) =>
        dispatch(getOrganisationContacts(organisationId)),
      getOrganisationAddresses: (organisationId: string) =>
        dispatch(getOrganisationAddresses(organisationId)),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditContact);
