import React from 'react';
import LoadingDots from '../../LoadingDots/LoadingDots';
import './AppLoadingDots.scss';

const AppLoadingDots: React.StatelessComponent = props => (
  <div className="app-loading-dots">
    <LoadingDots />
  </div>
);

export default AppLoadingDots;
