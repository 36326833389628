import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {ICurrentUser} from "./ISecurityState";
import {ILoginSuccessResponse} from "./Actions";

export default class SecurityApiClient {
    static async login(email: string, password: string): Promise<ILoginSuccessResponse> {
        const url = `${process.env.CLIENT_API}/login`;

        const requestOptions: object = {
            method: 'POST',
            url,
            headers: {
                authorization: false,
            },
            data: {
                email,
                password,
            },
        };

        const response = await axios(requestOptions);

        return response.data
    }

    static async validateAccessToken(accessToken: string): Promise<AxiosResponse> {
        const url = `${process.env.CLIENT_API}/anonymous/validate-token`;

        const requestOptions: object = {
            method: 'POST',
            url,
            headers: {
                authorization: false,
            },
            data: {
                access_token: accessToken,
            },
        };

        return await axios(requestOptions);
    }

    static async getCurentUser(accessToken?: string): Promise<ICurrentUser> {
        const url = `${process.env.CLIENT_API}/person`;

        const requestOptions: AxiosRequestConfig = {method: 'GET', url, headers: {}};

        if (accessToken) {
            requestOptions.headers.authorization    = false ;
            requestOptions.headers['X-CUSTOM-AUTH'] = accessToken;
        }

        const response = await axios(requestOptions);

        return {
            roles: response.data.data.attributes.roles
        }
    }
}
