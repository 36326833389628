import {SyntheticEvent, useEffect, useState} from 'react';
import {
  inviteTrialAccount,
} from './ApiClient';

const useForm = (): [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  any,
  any,
  string,
  any
] => {
  const [organisation, setOrganisation] = useState<string>('');
  const [organisationError, setOrganisationError] = useState<string>('');
  const [fullName, setFullName] = useState<string>('');
  const [fullNameError, setFullNameError] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>('');
  const [countryCodeError, setCountryCodeError] = useState<string>('');
  const [language, setLanguage] = useState<string>('');
  const [languageError, setLanguageError] = useState<string>('');

  const [submitAttempted, setSubmitAttempted] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('default');

  useEffect(() => {
    if (submitAttempted) {
      validate();
    }
  }, [organisation, fullName, email]);

  const setvalue = (key: string, value: string): void => {
    switch (key) {
      case 'organisation':
        setOrganisation(value);
        break;
      case 'fullName':
        setFullName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'countryCode':
        setCountryCode(value);
        break;
      case 'language':
        setLanguage(value);
        break;
    }
  };

  const validate = () => {
    let isvalid = true;
    if (!organisation) {
      setOrganisationError('Organisation name is required');
      isvalid = false;
    } else {
      setOrganisationError('');
    }

    if (!fullName) {
      setFullNameError('Full name is required');
      isvalid = false;
    } else {
      setFullNameError('');
    }

    if (!email) {
      setEmailError('E-mail is required');
      isvalid = false;
    } else {
      setEmailError('');
    }

    if (!countryCode) {
      setCountryCodeError('Country is required');
      isvalid = false;
    } else {
      setCountryCodeError('');
    }

    if (!language) {
      setLanguageError('Language is required');
      isvalid = false;
    } else {
      setLanguageError('');
    }

    return isvalid;
  };

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    setSubmitAttempted(true);

    if (!validate()) {
      return;
    }

    setStatus('submitting');

    inviteTrialAccount(
      fullName,
      organisation,
      email,
      countryCode,
      language
    ).then(
      () => {
        setStatus('success');
      },
      () => {
        setStatus('failed');
      }
    )
  };

  const reset = () => {
    setOrganisation('');
    setFullName('');
    setEmail('');
    setCountryCode('');
    setLanguage('');
    setStatus('default');
    setSubmitAttempted(false);
  };

  return [
    organisation,
    organisationError,
    fullName,
    fullNameError,
    email,
    emailError,
    countryCode,
    countryCodeError,
    language,
    languageError,
    setvalue,
    onSubmit,
    status,
    reset,
  ];
};

export default useForm;
