import { Dispatch } from 'react'
import {
  fetchPartners,
  createPartner as fetchCreatePartner,
  updatePartner as fetchUpdatePartner,
  deletePartner as fetchDeletePartner,
} from './client/Client'
import { IPartner } from "./interface/IPartner";

export const PARTNERS_LOADED = 'PARTNERS_LOADED'
export const PARTNER_CREATED = 'PARTNER_CREATED'
export const PARTNER_UPDATED = 'PARTNER_UPDATED'
export const PARTNER_DELETED = 'PARTNER_DELETED'

export const getPartners = () => async (dispatch: Dispatch<unknown>) => {
  const partners = await fetchPartners()

  return dispatch({
    type: PARTNERS_LOADED,
    payload:{
      partners
    }
  })
}

export const createPartner = (newPartner: Partial<IPartner> & {password: string}) => async (dispatch: Dispatch<unknown>) => {
  const partner = await fetchCreatePartner(newPartner)
  dispatch({
    type: PARTNER_CREATED,
    payload: {
      partner
    }
  })
}

export const updatePartner = (updatedPartner: IPartner) => async (dispatch: Dispatch<unknown>) => {
  const partner = await fetchUpdatePartner(updatedPartner)
  dispatch({
    type: PARTNER_UPDATED,
    payload: {
      partner
    }
  })
}

export const deletePartner = (partnerUuid: string) => async (dispatch: Dispatch<unknown>) => {
  await fetchDeletePartner(partnerUuid)
  dispatch({
    type: PARTNER_DELETED,
    payload: {
      partnerUuid
    }
  })
}
