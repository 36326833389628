import React, { FunctionComponent } from 'react'
import TableBody from '@material-ui/core/TableBody'

import { IProps } from './IProps'
import { CollapsibleTableRow } from './components/CollapsibleTableRow/CollapsibleTableRow'

export const CollapsibleTableBody: FunctionComponent<IProps> = ({
  brandList,
  setSelectedRowID,
  openModal,
}) => (
  <TableBody>
    {brandList.map(brand => (
      <CollapsibleTableRow
        setSelectedRowID={setSelectedRowID}
        key={brand.id}
        brandInfo={brand}
        openModal={openModal}
      />
    ))}
  </TableBody>
)
