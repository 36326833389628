import React, {FC, useState} from 'react';

// React redux
import {useDispatch} from 'react-redux';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';

// Material UI
import Button from '@material-ui/core/Button';

import {makeStyles} from '@material-ui/styles';

// Own components
import {IAppState} from 'App/IAppState';
import {sendSelected} from '../../../store/Brands/Actions';
import {useTranslation} from "react-i18next";

interface CollapsibleTableContinueButtonProps {
  values?: any;
  column?: any;
  brands: any;
  rowId?: any;
}

const useStyles = makeStyles(theme => ({
  grid: {
    padding: '16px',
  },
  input: {
    padding: '16px',
    borderStyle: 'dashed',
    borderColor: '#727273',
  },
  label: {
    padding: '4px',
  },
  button: {
    borderRadius: '99px',
    border: '3px solid',
    borderColor: '#2164db',
    backgroundColor: 'white',
    padding: '16px',
    width: '110px',
    fontWeight: 600,

    '&:hover': {
      backgroundColor: '#2164db',
      width: '110px',
      color: '#fff',
      padding: '16px',
    },
    '&:hover $icon': {
      color: '#fff',
    },
  },
  icon: {
    color: '#2164db',
  },
}));

const CollapsibleTableContinueButton: FC<CollapsibleTableContinueButtonProps> = ({
  values,
  column,
  rowId,
}) => {
  const {t} = useTranslation();

  const classes = useStyles();

  const [retrievedFileUuId] = useState(column.id);

  const dispatch = useDispatch<ThunkDispatch<IAppState, undefined, AnyAction>>();

  const continueOnClick = () => {
    dispatch(sendSelected(values, retrievedFileUuId, rowId));
  };

  return (
    <Button className={classes.button} onClick={() => continueOnClick()} color="primary">
      {t('Continue')}
    </Button>
  );
};

export default CollapsibleTableContinueButton;
