import React, { FunctionComponent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@material-ui/core/Box";
import { Backdrop, Modal } from "@material-ui/core";
import LoadingDots from '../App/LoadingDots/LoadingDots'
import { IAppState } from '../App/IAppState'
import { deletePartner, getPartners } from "../store/Partners/Action";
import Table from "../App/Table/Table";
import ArrayDataProvider from "../App/Table/DataProvider/ArrayDataProvider";
import TableColumn from "../App/Table/TableColumn";
import IDataProvider from "../App/Table/IDataProvider";
import { partnerStyles } from './style'

const Partners: FunctionComponent = () => {
  const {t} = useTranslation()

  const classes = partnerStyles()
  const partners = useSelector((state: IAppState) => state.partners.list)
  const dispatch = useDispatch();

  const [dataProvider, setDataProvider] = useState<IDataProvider | undefined>()
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [confirmingForDeletion, setConfirmingForDeletion] = useState<string | undefined>(undefined)

  useEffect(() => {
    if(! partners) {
      dispatch(getPartners());
    }
  }, [])

  useEffect(() => {
    if (partners) {
      setDataProvider(new ArrayDataProvider(partners))
    }
  }, [partners])

  const modalClose = () => setConfirmingForDeletion(undefined)

  const onConfirmDelete = async () => {
    if (! confirmingForDeletion) {
      return;
    }

    setIsDeleting(true)
    await dispatch(deletePartner(confirmingForDeletion));
    setConfirmingForDeletion(undefined);
    setIsDeleting(false)
  }

  if (!partners || !dataProvider || isDeleting) {
    return <LoadingDots/>
  }

  return (
    <div className="main-content" style={{textAlign: 'center'}}>
      <div style={{margin: '0 auto 2em auto', maxWidth: '640px', textAlign: 'left'}}>
        <h1>{t('Partners')}</h1>
        <Table dataProvider={dataProvider} pageSize={100}>
          <TableColumn dataKey="firstName" sortable={false} collapsable={false}>{t('Firstname')}</TableColumn>
          <TableColumn dataKey="lastName" sortable={false}>{t('Lastname')}</TableColumn>
          <TableColumn dataKey="email" sortable={false}>{t('Email')}</TableColumn>
          <TableColumn
            dataKey="uuid"
            sortable={false}
            collapsable={false}
            transform={
              (partnerUuid) => <>
                <Link to={`/partners/${partnerUuid}/password`} style={{marginRight: '.6em'}}>
                  <FontAwesomeIcon icon={['fas', 'lock']} />
                </Link>
                <Link to={`/partners/${partnerUuid}`} style={{marginRight: '.6em'}}>
                  <FontAwesomeIcon icon={['fas', 'pencil-alt']} />
                </Link>
                <a
                    className="error"
                    onClick={(e) => { e.preventDefault(); setConfirmingForDeletion(partnerUuid as string); } }
                >
                  <FontAwesomeIcon icon={['fas', 'trash']} />
                </a>
              </>
            }
          />
        </Table>
        <Link className="button" to="/partners/create">{t('Add partner')}</Link>
      </div>
      <Modal
          open={typeof confirmingForDeletion !== 'undefined'}
          BackdropComponent={Backdrop}
          onClose={modalClose}
      >
        <Box className={classes.modalBox}>
          <p>{ t('Are you sure?') }</p>
          <div>
            <button className="button error" onClick={onConfirmDelete}>
              <FontAwesomeIcon icon={['fas', 'trash']} /> {t('Delete')}
            </button>
            <button className="button dim" onClick={modalClose}>
              {t('Cancel')}
            </button>
          </div>
        </Box>

      </Modal>
    </div>
  )
}

export default Partners
