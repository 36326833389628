import axios from 'axios'
import { IBrand, IApiBrand } from '../interface/IBrand'
import { brandTransformer } from '../brandTransformer'

export const fetchBrands = async (): Promise<IBrand[]> => {
  const response = await axios.get<IApiBrand[]>(
    `${process.env.CLIENT_API}/products/sources/brands`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )

  return brandTransformer(response.data)
}

export const fetchRequestUpdate = (brand: IBrand) =>
  axios.post<IApiBrand[]>(
    `${process.env.CLIENT_API}/products/sources/brands/${brand.id}/request-update`
  )

export const fetchFileUpload = (file: any) => {
  const formData = new FormData()
  formData.append('file', file)
  return axios.post(`${process.env.CLIENT_API}/products/sources/import/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const fetchImportReference = (values: any, retrievedFileUuId: any, rowId: any) => {
  const payload = {
    brand_uuid: rowId,
    file_uuid: retrievedFileUuId,

    name: values.name,
    category: values.category,
    barcode: values.barcode,
    code: values.code,
    purchase_price: values.purchase_price,
    retail_price: values.retail_price,
  }

  return axios.post(`${process.env.CLIENT_API}/products/sources/import/column-mapping`, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const fetchStatusProgress = (fileSelected: any) =>
  axios.get<IApiBrand[]>(
    `${process.env.CLIENT_API}/products/sources/import/${fileSelected.import_uuid}/status`
  )

// ADD return type
export const postCreateBrandName = (brandName: string) => {
  const payload = { name: brandName }
  return axios.post(`${process.env.CLIENT_API}/products/sources/brands`, payload)
}

// ADD return type
export const postUpdateBrandName = (brandName: string, brandId: string) => {
  const payload = { name: brandName }
  return axios.post(`${process.env.CLIENT_API}/products/sources/brands/${brandId}`, payload)
}
