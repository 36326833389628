import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {useTranslation} from 'react-i18next';
import {IOrganisation} from '../Store/IOrganisation';
import {
  getOrganisationEstablishments,
  connectContactToEstablishment,
  disconnectContactToEstablishment,
} from '../Dashboard/EstablishmentStore/Actions';
import {IAppState} from '../../App/IAppState';
import {IEstablishment} from '../Dashboard/EstablishmentStore/IEstablishment';
import EstablishmentCards from '../Dashboard/EstablishmentCards/EstablishmentCards';
import {IContact} from '../ContactList/ContactStore/IContact';
import {getOrganisationContacts} from '../ContactList/ContactStore/Actions';

interface IDispatchProps {
  getOrganisationEstablishments: (organisationId: string) => void;
  connectContactToEstablishment: (
    organisationid: string,
    contactId: string,
    establishmentId: string
  ) => void;
  getOrganisationContacts: (organisationId: string) => void;
  disConnectContactToEstablishment: (
    organisationid: string,
    contactId: string,
    establishmentId: string
  ) => void;
}
interface IStateProps {
  establishments: IEstablishment[];
  establishmentsLoading: boolean;
  contacts: IContact[];
  contactLoading: boolean;
}
interface IComponentProps {
  organisation: IOrganisation;
  loading: boolean;
}

type IProps = IStateProps & IDispatchProps & IComponentProps;

const LinkContactToEstablishment: React.FunctionComponent<IProps> = props => {
  const contactId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  const foundContact = props.contacts.find((oneContact: IContact) => oneContact.id === contactId);

  const selectedEstablishments: string[] = [];

  props.establishments.map((establishment: IEstablishment) => {
    if (
      establishment.contactPersonsIds.find(
        (selectedContactId: string) => selectedContactId === contactId
      )
    ) {
      selectedEstablishments.push(establishment.id);
    }
  });

  useEffect(() => {
    if (!props.contactLoading && props.contacts.length === 0) {
      props.getOrganisationContacts(props.organisation.id);
    }
  }, [location]);

  const [t] = useTranslation();

  const handleOnChange = (isSelected: boolean, establishmentId: string) => {
    if (selectedEstablishments.find((element: string) => element === establishmentId)) {
      props.disConnectContactToEstablishment(props.organisation.id, contactId, establishmentId);
      const index = selectedEstablishments.indexOf(establishmentId);
      selectedEstablishments.splice(index, 1);
    } else {
      props.connectContactToEstablishment(props.organisation.id, contactId, establishmentId);
      selectedEstablishments.push(establishmentId);
      console.log(selectedEstablishments);
    }
  };

  return (
    <div className="cards">
      <h1 className="link-contact-title">
        {t('Connect ')}
        {foundContact ? foundContact.name : ''}
        {t(' to following establishments')}
      </h1>
      <EstablishmentCards
        selectable
        selectedEstablishmentIds={selectedEstablishments}
        organisation={props.organisation}
        loading={props.loading}
        onChanged={(isSelected: boolean, establishmentId: any) => {
          handleOnChange(isSelected, establishmentId);
        }}
      />
    </div>
  );
};

const mapStateToProps = (state: IAppState, componentProps: IComponentProps): IStateProps => ({
  contacts: state.organisationContacts.contacts,
  contactLoading: state.organisationContacts.loading,
  establishments: state.organisationEstablishments.establishments.filter(
    (element: IEstablishment) => element.organisationId === componentProps.organisation.id
  ),
  establishmentsLoading: state.organisationEstablishments.loading,
});

const mapDispatchToProps = (dispatch: any): IDispatchProps =>
  bindActionCreators(
    {
      getOrganisationContacts: (organisationId: string) =>
        dispatch(getOrganisationContacts(organisationId)),
      getOrganisationEstablishments: (organisationId: string) =>
        dispatch(getOrganisationEstablishments(organisationId)),
      connectContactToEstablishment: (
        organisationId: string,
        contactId: string,
        establishmentId: string
      ) => dispatch(connectContactToEstablishment(organisationId, contactId, establishmentId)),
      disConnectContactToEstablishment: (
        organisationId: string,
        contactId: string,
        establishmentId: string
      ) => dispatch(disconnectContactToEstablishment(organisationId, contactId, establishmentId)),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LinkContactToEstablishment);
