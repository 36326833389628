import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  DialogContent,
  // DialogContentText,
  DialogActions,
  Typography,
  FormControl,
  Select,
  MenuItem,
  LinearProgress,
  Box,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/styles'

import { IAppState } from 'App/IAppState'
import { fetchStatus } from '../../../store/Brands/Actions'
import TableContinueButton from './TableContinueButton'
import ProductImportFieldDefinition from '../../../imports/Table/ProductImportFieldDefinition'

interface CollapsibleTableModalProps {
  columnMapping?: any
  brands?: any
  rowId?: any
  fileSelected?: string
  statusProgress?: any
}

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 100,
  },
  bar: {
    color: '#2164db',
  },
  statusText: {
    padding: '16px',
    textAlign: 'center',
  },
  statusBar: {
    padding: '16px',
  },
}))

const CollapsibleTableModal: FC<CollapsibleTableModalProps> = ({
  columnMapping,
  brands,
  rowId,
  fileSelected,
  statusProgress,
}) => {
  const dispatch = useDispatch<ThunkDispatch<IAppState, undefined, AnyAction>>()

  const classes = useStyles()
  const result: string[] = []

  const [open, setOpen] = useState(false)
  const [selectValue, setSelectValue] = useState<Array<string>>([])
  const [values, setValues] = useState(result)
  const [processed, setProcessed] = useState(0)
  const [total, setTotal] = useState(0)
  const [status, setStatus] = useState('')

  useEffect(() => {
    if (columnMapping) {
      setOpen(true)
      setValues(columnMapping.column_headers)
    }
  }, [columnMapping])

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = (e: any) => {
    setSelectValue((selectValue: any) => {
      const newSelectValue = [...selectValue]
      if (!newSelectValue[0]) newSelectValue[0] = {}
      newSelectValue[0] = { ...newSelectValue[0], [e.target.name]: e.target.value }
      return newSelectValue
    })
  }

  useEffect(() => {
    if (fileSelected) {
      dispatch(fetchStatus(fileSelected))
    }
  }, [fileSelected])

  useEffect(() => {
    if (statusProgress) {
      console.log(statusProgress, statusProgress.status, status)
      setProcessed(statusProgress.processed)
      setTotal(statusProgress.total)
      setStatus(statusProgress.status)

      if (statusProgress.status !== 'finished') {
        setTimeout(() => dispatch(fetchStatus(fileSelected)), 5000)
      } else {
        setTimeout(() => {
          setOpen(false)
        }, 5000)
      }
    }
  }, [statusProgress])

  const progressInPercentage = total === 0 ? 0 : Math.round((processed / total) * 100)

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogContent>
        <DialogTitle>Import klanten</DialogTitle>
        <Typography variant="subtitle2" style={{ padding: '16px 24px' }}>
          Gelieve de kolommen aan de juiste velden toe te wijzen.
        </Typography>
        {/* <DialogContentText style={{ padding: '16px  ' }}> */}
        <List>
          {ProductImportFieldDefinition.map((field, i) => (
            <ListItem key={i}>
              <ListItemText>
                {field.label} {field.required ? '*' : ''}
              </ListItemText>
              <FormControl className={classes.formControl}>
                <Select name={field.name} onChange={(e: any) => handleChange(e)}>
                  {values &&
                    Object.entries(values).map(([key, value]) => (
                      <MenuItem value={key}>{value}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </ListItem>
          ))}
        </List>
        {/* </DialogContentText> */}
        <div className={classes.statusText}>
          {status.charAt(0).toUpperCase() + status.slice(1)}
          {status === 'processing' ? '...' : ''}
        </div>
        {fileSelected ? (
          <Box className={classes.statusBar} display="flex" alignItems="center">
            <Box width="85%" mr={1}>
              <LinearProgress
                className={classes.bar}
                variant="determinate"
                value={progressInPercentage}
              />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">
                {processed} / {total}
              </Typography>
            </Box>
          </Box>
        ) : (
          <DialogActions>
            <TableContinueButton
              rowId={rowId}
              brands={brands}
              values={selectValue[0]}
              column={columnMapping}
            />
          </DialogActions>
        )}
      </DialogContent>
    </Dialog>
  )
}
export default CollapsibleTableModal
