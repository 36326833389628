import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import InviteTrialAccount from "./InviteTrialAccount/InviteTrialAccount";
import {IAppState} from "../App/IAppState";

interface IState {}

interface IStateProps {
  error: string | null;
  loading: boolean;
}

interface IDispatchProps {}

type IProps = IStateProps & IDispatchProps;

class Trials extends React.Component<IProps, IState> {
  render() {
    return (
      <div className="trials">
        <div className="page-content">
          <InviteTrialAccount />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState): IStateProps => ({
  error: state.accounts.error,
  loading: state.accounts.search.isLoading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): IDispatchProps =>
  bindActionCreators(
    {},
    dispatch
  );

export default connect<IStateProps, IDispatchProps, {}, IAppState>(
  mapStateToProps,
  mapDispatchToProps
)(Trials);
