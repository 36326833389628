import {SyntheticEvent, useState} from 'react';
import {sendOrganisationAddress} from '../AddAccount/ApiClient';
import {IOrganisation} from '../Store/IOrganisation';

const useAddressForm = (
  organisation: IOrganisation
): [string, string, string, string, string, string, any, any, any] => {
  const [street, setStreet] = useState('');
  const [streetNr, setStreetNr] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [status, setStatus] = useState('');

  const setValue = (key: string, value: string): void => {
    switch (key) {
      case 'street':
        setStreet(value);
        break;
      case 'streetNr':
        setStreetNr(value);
        break;
      case 'postalCode':
        setPostalCode(value);
        break;
      case 'city':
        setCity(value);
        break;
      case 'country':
        setCountry(value);
        break;
    }
  };

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    setStatus('submitting');

    sendOrganisationAddress(organisation.id, street, +streetNr, postalCode, city, country).then(
      () => {
        setStatus('succes');
      },
      () => {
        setStatus('failed');
      }
    );
  };

  const reset = () => {
    setCity('');
    setCountry('');
    setPostalCode('');
    setStreet('');
    setStreetNr('');
    setStatus('default');
  };

  return [street, streetNr, postalCode, city, country, status, reset, setValue, onSubmit];
};

export default useAddressForm;
