import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Route, Switch, RouteProps} from 'react-router';
import {bindActionCreators} from 'redux';
import {IAppState} from '../../App/IAppState';
import AddAddress from '../AddAddress/AddAddress';
import {getOrganisation, setCurrentOrganisation} from '../Store/Actions';
import {IOrganisation} from '../Store/IOrganisation';
import AddContact from '../AddContact/AddContact';
import AddInvoiceInfo from '../AddInvoiceInfo/AddInvoiceInfo';
import LoadingDots from '../../App/LoadingDots/LoadingDots';
import AddressList from '../AddressList/AddressList';
import ContactList from '../ContactList/ContactList';
import AddEstablishment from '../AddEstablishment/AddEstablishment';
import InvoiceInfoList from '../InvoiceInfoList/InvoiceInfoList';
import './Account.scss';
import EditContact from '../EditContact/EditContact';
import LinkInvoiceInfo from '../LinkInvoiceInfo/LinkInvoiceInfo';
import LinkContactToEstablishment from '../LinkContactToEstablishment/LinkContactToEstablishment';
import Dashboard from '../Dashboard/Dashboard';

interface IProps {
  match: any;
  loading: boolean;
  organisation: IOrganisation | undefined;
  accessToken: string;
  getOrganisation: any;
  setCurrentOrganisation: (organisationId: string) => void;
}

const Account: React.FunctionComponent<IProps> = (props: IProps) => {
  useEffect(() => {
    if (!props.loading && typeof props.organisation === 'undefined') {
      props.getOrganisation(props.match.params.organisationId);
    }
  }, [props.organisation]);

  useEffect(() => {
    props.setCurrentOrganisation(props.match.params.organisationId);
  }, [props.match.params.organisationId]);

  if (props.loading || typeof props.organisation === 'undefined') {
    return <LoadingDots />;
  }

  return (
    <div className="info-container-wrapper">
      <Switch>
        <Route
          exact
          path="/accounts/:organisationId/add-contact"
          render={(injectedProps: RouteProps) => (
            <AddContact {...injectedProps} organisation={props.organisation!} />
          )}
        />
        <Route
          exact
          path="/accounts/:organisationId/add-address"
          render={(injectedProps: RouteProps) => (
            <AddAddress {...injectedProps} organisation={props.organisation!} />
          )}
        />
        <Route
          exact
          path="/accounts/:organisationId/add-invoice-info"
          render={(injectedProps: RouteProps) => (
            <AddInvoiceInfo {...injectedProps} organisation={props.organisation!} />
          )}
        />
        <Route
          exact
          path="/accounts/:organisationId/add-establishment"
          render={(injectedProps: RouteProps) => (
            <AddEstablishment {...injectedProps} organisation={props.organisation!} />
          )}
        />
        <Route
          exact
          path="/accounts/:organisationId/addresses"
          render={(injectedProps: RouteProps) => (
            <AddressList {...injectedProps} organisation={props.organisation!} />
          )}
        />
        <Route
          exact
          path="/accounts/:organisationId/contacts"
          render={(injectedProps: RouteProps) => (
            <ContactList {...injectedProps} organisation={props.organisation!} />
          )}
        />
        <Route
          exact
          path="/accounts/:organisationId/edit-contact/:contactId"
          render={(injectedProps: RouteProps) => (
            <EditContact
              {...injectedProps}
              organisation={props.organisation!}
              loading={props.loading}
            />
          )}
        />
        <Route
          exact
          path="/accounts/:organisationId/connect-establishment-contact/:contactId"
          render={(injectedProps: RouteProps) => (
            <LinkContactToEstablishment
              {...injectedProps}
              organisation={props.organisation!}
              loading={props.loading}
            />
          )}
        />
        <Route
          exact
          path="/accounts/:organisationId/invoiceInformation"
          render={(injectedProps: RouteProps) => (
            <InvoiceInfoList {...injectedProps} organisation={props.organisation!} />
          )}
        />
        <Route
          exact
          path="/accounts/:organisationId/connect-establishment-invoiceinfo/:invoiceInfoId"
          render={(injectedProps: RouteProps) => (
            <LinkInvoiceInfo
              {...injectedProps}
              organisation={props.organisation!}
              loading={props.loading}
            />
          )}
        />
        <Route
          path="/accounts/:organisationId"
          render={(injectedProps: RouteProps) => (
            <Dashboard
              {...injectedProps}
              organisation={props.organisation!}
              loading={props.loading}
            />
          )}
        />
      </Switch>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      getOrganisation: (organisationId: string) => dispatch(getOrganisation(organisationId)),
      setCurrentOrganisation: (organisationId: string) =>
        dispatch(setCurrentOrganisation(organisationId)),
    },
    dispatch
  );

const mapStateToProps = (state: IAppState, props: IProps) => {
  const {organisationId} = props.match.params;

  return {
    accessToken: state.security.accessToken,
    organisation: state.accounts.list.find(
      (element: IOrganisation) => element.id === organisationId
    ),
    loading: state.accounts.loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
