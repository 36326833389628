import moment from 'moment';
import {IApiBrand, IBrand} from './interface/IBrand'

export const brandTransformer = (apiBrands: IApiBrand[]): IBrand[] =>
  apiBrands.map(
    (apiBrand): IBrand => ({
      ...apiBrand,
      lastRequestedAt: apiBrand.last_requested_at ? moment(apiBrand.last_requested_at) : null,
      lastUpdatedAt: apiBrand.last_updated_at ? moment(apiBrand.last_updated_at) : null,
    })
  );
