import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// import translation from "./translation.json";
import Backend from 'i18next-xhr-backend';
import {initReactI18next} from 'react-i18next';

const translationLanguages = ['en', 'nl', 'nl_NL', 'fr', 'de', 'sq'];

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    ns: ['messages'],
    defaultNS: 'messages',
    backend: {
      loadPath: `${process.env.CLIENT_API}/translations/export/domain/{{ns}}/locale/{{lng}}`,
      crossDomain: true,
    },
    detection: {
      order: ['localStorage', 'navigator', 'htmlTag'],
      lookupLocalStorage: 'locale',
    },
    react: {
      wait: true,
    },
    fallbackLng: 'en',
    debug: false,
    whitelist: translationLanguages,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
