import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { hot } from 'react-hot-loader'

import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router'

import Accounts from '../Accounts/Accounts'
import Commissions from '../Commissions/Commissions'
import Orders from '../Orders/Orders'
import { userActions } from '../Security/Actions'
import Login from '../Security/Login/Login'
import Support from '../Support/Support'
import Translations from '../Translations/Translations'
import Modal from './Components/Modal/Modal'
import { IAppState } from './IAppState'
import Navbar from './Navbar/Navbar'
import NotFound from './NotFound'
import Dashboard from '../Dashboard/Dashboard/Dashboard'
import Products from '../Products/Products'
import ModuleBootstrap from '../Module/Bootstrap'
import { BrandProducts } from '../BrandProducts/BrandProducts'
import Partners from "../Partners/Partners";
import CreatePartner from "../Partners/CreatePartner/CreatePartner";
import UpdatePartner from "../Partners/UpdatePartner/UpdatePartner";
import UpdatePassword from "../Partners/UpdatePassword/UpdatePassword";
import Trials from "../Trials/Trials";

library.add(fab, fas)

const App: React.FunctionComponent = (props: any) => {
  if (!props.isLoggedIn) {
    return <Login email="" password="" />
  }

  return (
    <>
      <Navbar />
      <div className="page-layout">
        <Switch>
          <Route exact path="/" component={Translations} />
          <Route path="/accounts" component={Accounts} />
          <Route path="/trials" component={Trials} />
          <Route path="/products" exact component={Products} />
          <Route path="/products/:brandId" component={BrandProducts} />
          <Route path="/support" component={Support} />
          <Route path="/orders" component={Orders} />
          <Route path="/commissions" component={Commissions} />
          <Route path="/translations" component={Translations} />
          <Route path="/modules" component={ModuleBootstrap} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/partners/create" component={CreatePartner} />
          <Route path="/partners/:partnerUuid/password" component={UpdatePassword} />
          <Route path="/partners/:partnerUuid" component={UpdatePartner} />
          <Route path="/partners" component={Partners} />
          <Route
            exact
            path="/logout"
            render={() => {
              userActions.logout()
              return <Redirect to="/" />
            }}
          />
          <Route component={NotFound} />
        </Switch>
      </div>
      {props.isModalOpen ? <Modal /> : ''}
    </>
  )
}

function mapStateToProps(state: IAppState) {
  return {
    isLoggedIn: state.security.accessToken !== '',
    isModalOpen: state.modal.isOpen,
  }
}

export default hot(module)(connect(mapStateToProps)(App))
