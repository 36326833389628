export const countries = [
  {
    value: 'BE',
    label: 'Belgium',
  },
  {
    value: 'NL',
    label: 'The Nederlands',
  },
  {
    value: 'DE',
    label: 'Germany',
  },
  {
    value: 'FR',
    label: 'France',
  },
  {
    value: 'ES',
    label: 'Spain',
  },
  {
    value: 'CH',
    label: 'Switzerland',
  },
  {
    value: 'IT',
    label: 'Italy',
  },
  {
    value: 'LU',
    label: 'Luxembourg',
  },
];
