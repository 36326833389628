import {Dispatch} from 'react';
import {IOrganisationType} from '../Client/IOrganisationType';
import Client from '../Client/Client';

export const LOADING = 'LOADING';
export const GET_PAYING_CUSTOMERS = 'GET_PAYING_CUSTOMERS';
export const GET_STATS_SUCCESS = 'GET_STATS_SUCCESS';

export const GET_LIVE_STATS_SUCCESS = 'GET_LIVE_STATS_SUCCESS';

export function getPayingCustomers(startDate?: string, endDate?: string) {
  return getStatistics('paying_customers', startDate, endDate);
}

function getStatistics(type: string, startDate?: string, endDate?: string) {
  return (dispatch: Dispatch<any>) => {
    dispatch(getStatsLoading(type, startDate, endDate));

    Client.getStatistics(type, startDate, endDate).then(response => {
      dispatch(getStatsSuccess(type, response.data));
    });
  };
}

export function getLiveStatistics(organisation: IOrganisationType) {
  return (dispatch: Dispatch<any>) => {
    Client.getLiveStatistics(organisation).then(response => {
      dispatch(getLiveStatsSuccess(organisation, response.data));
    });
  };
}

function getStatsLoading(statsType: string, startDate?: string, endDate?: string) {
  return {
    type: LOADING,
    statsType,
    startDate,
    endDate,
  };
}

function getStatsSuccess(statsType: string, data: any) {
  return {
    type: GET_STATS_SUCCESS,
    statsType,
    data,
  };
}

function getLiveStatsSuccess(organisation: IOrganisationType, data: object) {
  return {
    type: GET_LIVE_STATS_SUCCESS,
    organisation,
    data,
  };
}
