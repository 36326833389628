import axios, {AxiosResponse} from 'axios';
import ITranslation from './Table/ITranslation';

export default class TranslationsApiClient {
  static getTranslations(domain: string): Promise<AxiosResponse> {
    const url = `${process.env.CLIENT_API}/translations/export/domain/${domain}`;
    const requestOptions: object = {
      method: 'GET',
      url,
      headers: {
        'Content-Type': 'application/json',
        authorization: false,
      },
    };

    return axios(requestOptions);
  }

  static addTranslation(translation: ITranslation, domain: string): Promise<any> {
    const locales: string[] = ['nl', 'nl_NL', 'en', 'fr', 'de', 'it', 'sq'];
    const requests: any[] = [];
    for (const locale of locales) {
      if (!translation[locale]) {
        continue;
      }

      requests.push(this.addTranslationLocale(translation, locale, domain));
    }

    return axios.all(requests);
  }

  static addTranslationLocale(translation: ITranslation, locale: string, domain: string) {
    const url = `${process.env.CLIENT_API}/translations/add`;

    const requestOptions: object = {
      method: 'POST',
      url,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        key: translation.key,
        locale,
        localized: translation[locale],
        domain,
      },
    };

    return axios(requestOptions);
  }
}
